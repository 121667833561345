const env = window.location.hostname === 'localhost' ? 'local' : process.env.BUILD_ENV || process.env.REACT_APP_BUILD_ENV;
const APIKEYS = {
    local: {
        RECAPTCHA_KEY: '6LezyVEcAAAAAHrdio2Mp7tuRcLiOm0_bXbbwr_b',
        STRIPE_KEY: 'pk_test_esXbbsc29bxQUyamvShdqPpW',
    },
    development: {
        RECAPTCHA_KEY: '6LezyVEcAAAAAHrdio2Mp7tuRcLiOm0_bXbbwr_b',
        STRIPE_KEY: 'pk_test_esXbbsc29bxQUyamvShdqPpW',
    },
    test: {
        RECAPTCHA_KEY: '6LezyVEcAAAAAHrdio2Mp7tuRcLiOm0_bXbbwr_b',
        STRIPE_KEY: 'pk_test_esXbbsc29bxQUyamvShdqPpW',
    },
    production: {
        RECAPTCHA_KEY: '6LcCq9oeAAAAAPjJlewk7rWWo7mHnFKfF67PkA1k',
        STRIPE_KEY: 'pk_live_b0xvYBQPhFvCPzGsyW3KYtvE',
    },
};

export default APIKEYS[env];

