import React from 'react';
import { StyledAbnormalPopup } from './StyledAbnormalPopup';
import { ReactComponent as ErrorIcon } from './images/error-icon.svg';
import Button from 'components/Button/Button';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const AbnormalPopup = () => {

    const { t } = useTranslation();

    const handleContactEvent = () => {
        window.open(`https://ar.istaging.com/contact_us?lang=${Cookies.get('lang')}`);
    };

    return (
        <StyledAbnormalPopup>
            <div className='abnormal_popup'>
                <ErrorIcon />
                <p className='abnormal_popup_title'>{t('abnormalPopup.accountError')}</p>
                <p className='abnormal_popup_subtitle'>{t('abnormalPopup.assistYouSoon')}</p>
                <div className='abnormal_popup_buttons'>
                    <Button type='dark' text={t('callHelp')} clickEvent={handleContactEvent} />
                </div>
            </div>
        </StyledAbnormalPopup>
    );
};

export default AbnormalPopup;