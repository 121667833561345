import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RESET_ALL } from 'redux/types';

// components
import Feature from 'components/Feature/Feature';
import Upgrade from 'components/Upgrade/Upgrade';
import Unsubscribe from 'components/Unsubscribe/Unsubscribe';
import CatchError from 'components/CatchError/CatchError';
import Button from 'components/Button/Button';

// styled
import { StyledHeader, StyledTooltip } from './StyledHeader';

// api
import { logout } from 'api/logout/logout.api';

// react icon
import { FaUserCircle, FaGlobe } from 'react-icons/fa';
import { AiFillCheckCircle, AiOutlinePlus } from 'react-icons/ai';
import { IoIosArrowDown, IoMdClose } from 'react-icons/io';
import { BsCheckLg } from 'react-icons/bs';

// images
import logo from './images/logo.svg';
import { ReactComponent as Logout } from './images/logout.svg';
import { ReactComponent as Cube } from './images/Cube.svg';
import { ReactComponent as Upload } from './images/Upload.svg';
import { ReactComponent as ArObject } from './images/ArObject.svg';
import { ReactComponent as ScanKit } from './images/ScanKit.svg';

// plugin
import Cookies from 'js-cookie';
import * as dayjs from 'dayjs';
import axios from 'axios';

// constants
import SERVER from 'constants/server';

// i18n
import { useTranslation, Trans } from 'react-i18next';

const Tooltip = ({ tooltipId, projectCount, projectLimit, arObjectCount, scanKitCount }) => {

    const { t } = useTranslation();

    return (
        <StyledTooltip id={tooltipId} place='bottom' type='light' effect='solid'>
            <p>
                {tooltipId === 'create' ? t('header.alreadyCreate') : t('header.alreadyPublic')} {projectCount}
                {projectLimit && (
                    <span className='limitation'>
                        {t('header.canPublic')} {projectLimit}
                    </span>
                )}
            </p>
            <div className='tooltip_list'>
                <ArObject />
                <span>{t('header.arObject')} {arObjectCount}</span>
            </div>
            <div className='tooltip_list'>
                <ScanKit />
                <span>{t('header.scankitObject')} {scanKitCount}</span>
            </div>
        </StyledTooltip>
    );
};

const Header = () => {

    const pathName = window.location.pathname;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const store = useSelector(store => store);
    const { projectLimit, publicProject: publicProjectLimit, visitPerMonth } = useSelector(store => store.permits);
    const { projectCount, projectPublicCount, armakerProjectCount, scankitProjectCount, armakerProjectPublicCount, scankitProjectPublicCount } = useSelector(store => store.projectRecord);

    const logoutEvent = (e) => {
        e.preventDefault();
        logout().then(() => {
            dispatch({ type: RESET_ALL });
        });
    };

    const slideDownStop = (e) => {
        e.stopPropagation();
    };

    const [showLang, setLangShow] = useState(false);

    const showLangEvent = () => {
        setLangShow(!showLang);
    };

    const changeLangEvent = (lang) => {
        i18n.changeLanguage(lang);
        Cookies.set('lang', lang);
        // window.open(window.location.origin + window.location.pathname + '?lang=' + Cookies.get('lang'), '_self');
    };

    const [showInfo, setShowInfo] = useState(false);

    const showInfoEvent = () => {
        setShowInfo(!showInfo);
    };

    const [showFeature, setShowFeature] = useState(false);

    const openFeature = (e) => {
        e.preventDefault();
        setShowFeature(!showFeature);
    };

    const [myPlan, setMyPlan] = useState(null);
    const [cancelBefore, setCancelBefore] = useState(null);
    const [totalArchive, setTotalArchive] = useState(null);

    // 查詢是否曾經取消過基本版
    const getOrderEvent = async () => {

        let token = decodeURIComponent(urlParams.get('token')) !== 'null' ? decodeURIComponent(urlParams.get('token')) : store.userInfo.bigPlatformToken;

        try {

            const getOrderStatus = await axios({
                method: 'get',
                url: `${SERVER.PLATFORM_URL}/api/v1/payment/order?productId=${SERVER.PRODUCT_ID}`,
                headers: { 'Authorization': `Bearer ${token}` },
            });
            const response = getOrderStatus.data.content;
            const cancelCount = response.filter(element => element.canceledAt !== null);
            if (cancelCount.length > 0) {
                setCancelBefore(true); // cancelBefore 為 true 代表曾經取消過 => 恢復訂閱
            } else {
                setCancelBefore(false); // cancelBefore 為 false 代表沒有取消過 => 取消續訂
            }

        } catch (error) {
            console.log(error);
        }

    };

    useEffect(() => {

        // 原本方案：free / basic / enterprise / armaker_enterprise_*企業名稱*
        // 2022/08/08 新版方案：armaker_free_20220808 / armaker_basic_20220808 / <描述1>_Enterprise_<描述2>

        const plan = store.permits.plan;
        // 避免打兩次api
        if (store.permits) {
            if (plan.indexOf('free') > -1) {
                setMyPlan('free');
            } else if (plan.indexOf('basic') > -1) {
                setMyPlan('basic');
            } else if (plan.indexOf('enterprise') > -1 || plan.indexOf('Enterprise') > -1) {
                setMyPlan('enterprise');
            }

            getOrderEvent();
            setTotalArchive(store.cardListInfo.totalArchive);
        }


    }, [store.permits, store.cardListInfo]);

    const [showStripe, setShowStripe] = useState(false);

    const upgradeEvent = (value) => {
        setShowStripe(value);
    };

    const [showUnsubscribe, setShowUnsubscribe] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [error, setError] = useState(null);

    const handleCreateEvent = () => {
        navigate('/chooseType');
    };

    return (
        <StyledHeader>
            {
                showFeature &&
                <Feature plan={myPlan} setShowFeature={setShowFeature} setShowStripe={setShowStripe} setShowUnsubscribe={setShowUnsubscribe} setError={setError} />
            }
            {
                showStripe &&
                <div className='popupUpgrade'>
                    <div className='upgradeBg'>
                        <Upgrade onShowStripe={upgradeEvent} />
                    </div>
                </div>
            }
            {
                showUnsubscribe && <Unsubscribe setShowUnsubscribe={setShowUnsubscribe} setShowFeature={setShowFeature} setShowMessage={setShowMessage} setError={setError} />
            }
            {
                showMessage &&
                <div className='flexCenter'>
                    <div className='message'>
                        <div className='flexStartCenter'>
                            <AiFillCheckCircle className='checkCircle' />
                            <Trans i18nKey="message.resumeNotification">
                                本期合約結束時({{ expiredDate: dayjs(store.permits.expiredAt).format('YYYY/MM/DD') }})，您的方案將變更為體驗版，您可以隨時
                                <span className='resumeButton'>恢復訂閱。</span>
                            </Trans>
                        </div>
                        <IoMdClose className='closeIcon' onClick={() => setShowMessage(false)} />
                    </div>
                </div>
            }
            {
                error &&
                <div className='errorBackground'>
                    <div className='errorContainer'>
                        <IoMdClose className='errorClose' onClick={() => setError(false)} />
                        <CatchError
                            title={error === 'unsubscribe' ? t('error.unsubscribe') : error === 'resume' ? t('error.resume') : t('error.error')}
                            description={t('error.checkInternet')}
                            button={t('error.confirmButton')}
                        />
                    </div>
                </div>
            }

            <div className="top">
                <div className="ll">
                    <div className="logoName">
                        <img src={logo} alt="armaker-logo" />
                    </div>
                </div>
                <div className="rr">
                    <div className='feature'>
                        {myPlan === 'free' ? t('packageFree') : myPlan === 'basic' ? t('packageBasic') : t('packageEnterprise')}
                    </div>
                    {/* {
                        myPlan !== 'enterprise' &&
                        <a href="#" className='uploadBtn' onClick={openFeature}>
                            {t('packageUpdrade')}
                        </a>
                    } */}
                    <div className='langBtn' onClick={showLangEvent}>
                        <FaGlobe className='globa' />
                        {Cookies.get('lang') === 'zh-tw' && '繁體'}
                        {Cookies.get('lang') === 'en' && 'EN'}
                        {Cookies.get('lang') === 'fr' && 'FR'}
                        {Cookies.get('lang') === 'ko' && 'KO'}
                        <div className={showLang ? 'slideDown show' : 'slideDown'} onClick={slideDownStop} onMouseLeave={() => setLangShow(false)}>
                            <div onClick={() => changeLangEvent('zh-tw')} className={Cookies.get('lang') === 'zh-tw' ? 'list current' : 'list'}>
                                <span>繁體中文</span> <BsCheckLg />
                            </div>
                            <div onClick={() => changeLangEvent('en')} className={Cookies.get('lang') === 'en' ? 'list current' : 'list'}>
                                <span>English</span> <BsCheckLg />
                            </div>
                            <div onClick={() => changeLangEvent('fr')} className={Cookies.get('lang') === 'fr' ? 'list current' : 'list'}>
                                <span>Français</span> <BsCheckLg />
                            </div>
                            <div onClick={() => changeLangEvent('ko')} className={Cookies.get('lang') === 'ko' ? 'list current' : 'list'}>
                                <span>한국어</span> <BsCheckLg />
                            </div>
                        </div>
                    </div>
                    <div className='line'></div>
                    <div className='hoverCon' onClick={showInfoEvent}>
                        <FaUserCircle className='svgIcon' />
                        <IoIosArrowDown className='arrowUnder' />
                        {showInfo && (
                            <div className="box" onMouseLeave={() => setShowInfo(false)}>
                                <FaUserCircle />
                                <div className="username">
                                    {store.userInfo.username}<br />
                                    <span>
                                        {store.userInfo.email}
                                    </span>
                                </div>
                                <a href="#" onClick={logoutEvent} className='logoutBtn'>
                                    <Logout />
                                    {t('logout')}
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="bottom">
                <div className='left_section'>
                    <div className='usage_info'>
                        <Cube className='usage_info_icon' />
                        <div>
                            <div className='usage_info_title'>{t('totalCreatedProject')}</div>
                            <div className='usage_info_count'>
                                <a data-tip data-for='create'>
                                    <span className='project_count'>{projectCount}</span> {t('projects')}
                                </a>
                                <Tooltip 
                                    tooltipId='create' 
                                    projectCount={projectCount} 
                                    projectLimit={false} 
                                    arObjectCount={armakerProjectCount} 
                                    scanKitCount={scankitProjectCount} 
                                />
                            </div>
                        </div>
                    </div>
                    <div className='divider_outer'></div>
                    <div className='usage_info'>
                        <Upload className='usage_info_icon' />
                        <div>
                            <div className='usage_info_title'>{t('totalPublicProject')}</div>
                            <div className='usage_info_count'>
                                <a data-tip data-for='public'>
                                    <span className='project_count'>{projectPublicCount}/{publicProjectLimit}</span> {t('projects')}
                                </a>
                                <Tooltip 
                                    tooltipId='public' 
                                    projectCount={projectPublicCount} 
                                    projectLimit={publicProjectLimit} 
                                    arObjectCount={armakerProjectPublicCount} 
                                    scanKitCount={scankitProjectPublicCount} 
                                />
                            </div>
                        </div>
                    </div>
                    <div className='divider_outer'></div>
                    <div className='contract_info'>
                        <div className='contract_period'>
                            {myPlan !== 'free' ? t('header.currentContract') : cancelBefore ? t('header.lastContract') : t('header.validPeriod')}
                            : {dayjs(store.permits.startAt).format('YYYY/MM/DD')} - {dayjs(store.permits.expiredAt).format('YYYY/MM/DD')}
                        </div>
                        {/* <div className='feature_button'>
                            <a href='#' onClick={openFeature}>
                                {t('checkplan')}
                            </a>
                        </div> */}
                    </div>
                </div>
                <div className='right_section'>
                    {pathName !== '/create' && (
                        <Button type='dark' icon={<AiOutlinePlus />} text={t('header.createObject')} clickEvent={handleCreateEvent} />
                    )}
                </div>
            </div>
        </StyledHeader>
    );
};

export default Header;