import styled from 'styled-components';

export const StyledScanEditorTag = styled.div`
    position: absolute;
    left: ${({ left }) => left + '%'};
    top: ${({ top }) => top + '%'};

    .tagBadge {
        display: block;
        width: 24px;
        height: 24px;
        /* background: rgba(255, 112, 67, 0.90); */
        /* background: rgba(0, 0, 0, 0.80); */
        background: ${({ newTag }) => newTag ? 'rgba(0, 0, 0, 0.80)' : 'rgba(255, 112, 67, 0.90)'};
        border: 2px solid #FFF;
        border-radius: 50%;
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;
        text-align: center;

        &:hover {
            /* box-shadow: 0px 0px 0px 6px rgba(255, 112, 67, 0.50); */
            /* box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, 0.50); */
            box-shadow: ${({ newTag }) => newTag ? '0px 0px 0px 6px rgba(0, 0, 0, 0.50)' : '0px 0px 0px 6px rgba(255, 112, 67, 0.50)'};
        }
    }

    .tagPanel {
        width: 240px;
        height: auto;
        max-height: 450px;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.12);
        background: #292929;
        padding: 10px 12px;
        color: rgba(255, 255, 255, 0.87);
        position: absolute;
        /* 標籤位置在上方，面板顯示在下方 ; 標籤位置在下方，面板顯示在上方 */
        top: ${({ top }) => top < 50 ? '0px' : 'auto'};
        bottom: ${({ top }) => top < 50 ? 'auto' : '0px'};
        /* 標籤位置 x<25，面板顯示在右側 ; 標籤位置 25<x<50，面板顯示在左側 ; 標籤位置 50<x<75，面板顯示在右側 ; 標籤位置 x>75 面板顯示在左側 */
        left: ${({ left }) => ((left > 25 && left < 50) || left > 75) ? 'auto' : '30px'};
        right: ${({ left }) => ((left > 25 && left < 50) || left > 75) ? '30px' : 'auto'};
        z-index: 999;

        >form {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
        }

        .actionButtons {
            flex-direction: row;
            column-gap: 8px;

            .alertText {
                display: flex;
                flex-direction: row;
                column-gap: 4px;
                align-items: center;

                >span {
                    color: #FFC55E;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.24px;
                }
            }

            >svg {
                cursor: pointer;
            }
        }

        .textInput {
            display: block;
            width: 100%;
            border-radius: 4px;
            border: 1px solid rgba(255, 255, 255, 0.12);
            background: #1B1B1B;
            padding: 8px;
            color: rgba(255, 255, 255, 0.87);
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.28px;

            &:focus {
                border: 1px solid rgba(255, 255, 255, 0.60);
                outline: none;
            }

            &:hover {
                border: 1px solid rgba(255, 255, 255, 0.60);
            }
        }

        .textLimitation {
            color: rgba(255, 255, 255, 0.60);
            text-align: right;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.24px;
            margin-top: 4px;
        }

        .fileField {
            width: 100%;
            height: 135px;
            border-radius: 4px;
            border: 1px dashed rgba(255, 255, 255, 0.12);
            background: #1B1B1B;
            overflow: hidden;

            &:hover {
                border: 1px solid rgba(255, 255, 255, 0.60);
            }

            >label {
                display: block;
                width: 100%;
                height: 100%;
                color: rgba(255, 255, 255, 0.50);
                text-align: center;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0.24px;

                >input {
                    display: none;
                }

                >div {
                    padding: 23px 40px;
                }

                >img {
                    max-width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .uploadButton {
                    display: flex;
                    padding: 4px 16px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-radius: 40px;
                    background: rgba(255, 255, 255, 0.38);
                    color: #FFFFFF;
                    font-size: 14px;
                    margin-top: 18px;
                }
            }
        }
    }
`;