import { RESET_ALL, SET_PERMITS } from 'redux/types';
const defaultPermits = {
    createdAt: 0,
    expiredAt: 0,
    projectLimit: 0,
    publicProject: 0,
    startAt: 0,
    uploadModel: false,
    visitPerMonth: 0,
};

const permitsReducer = (state = defaultPermits, action) => {
    switch (action.type) {
        case SET_PERMITS:
            return {
                ...state, ...action.payload,
            };
        case RESET_ALL:
            return defaultPermits;
        default: return state;
    }
};

export default permitsReducer;