export const SET_PERMITS = 'SET_PERMITS';
export const SET_NO_PERMITS = 'SET_NO_PERMITS';
export const SET_CARD_LIST = 'SET_CARD_LIST';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_PROJECT_RECORD = 'SET_PROJECT_RECORD';
export const RESET_ALL = 'RESET_ALL';
export const SET_SCANKIT_USAGE = 'SET_SCANKIT_USAGE';
export const SET_SCANKIT_CARD_LIST = 'SET_SCANKIT_CARD_LIST';
export const SET_SCANKIT_IMAGE_LIST = 'SET_SCANKIT_IMAGE_LIST';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const TOGGLE_SWITCH = 'TOGGLE_SWITCH';
export const SET_SCANKIT_TAG_LIST = 'SET_SCANKIT_TAG_LIST';
export const SET_EDITING_TAG = 'SET_EDITING_TAG';
export const SET_OLD_TAG_TITLE = 'SET_OLD_TAG_TITLE';
export const SET_OLD_TAG_CONTENT = 'SET_OLD_TAG_CONTENT';
export const SET_OLD_TAG_FILE = 'SET_OLD_TAG_FILE';
export const SET_OLD_TAG_URL = 'SET_OLD_TAG_URL';
export const REMOVE_OLD_TAG = 'REMOVE_OLD_TAG';
export const REMOVE_OLD_TAG_ICON = 'REMOVE_OLD_TAG_ICON';
export const SET_NEW_TAG = 'SET_NEW_TAG';
export const SET_NEW_TAG_TITLE = 'SET_NEW_TAG_TITLE';
export const SET_NEW_TAG_CONTENT = 'SET_NEW_TAG_CONTENT';
export const SET_NEW_TAG_FILE = 'SET_NEW_TAG_FILE';
export const SET_NEW_TAG_URL = 'SET_NEW_TAG_URL';
export const REMOVE_NEW_TAG = 'REMOVE_NEW_TAG';