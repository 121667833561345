import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';

// styled
import { StyledCreate } from './styledCreate';

// api
import { createProject } from 'api/createProject/createProject.api';

// components
import Header from 'components/Header/Header';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import Button from 'components/Button/Button';
import Banner from 'components/Banner/Banner';
import AbnormalPopup from 'components/AbnormalPopup/AbnormalPopup';

// hooks
import useModal from 'hooks/useModal';

// config
import { modalConfig } from 'config/modal';

// images
import { ReactComponent as Empty } from './images/Empty.svg';

// react icons
import { AiOutlinePlus } from 'react-icons/ai';

// plugin
import Cookies from 'js-cookie';

// i18n
import { useTranslation } from 'react-i18next';

let projectDefault = {
    description: '',
    hashTags: [],
    id: 0,
    name: '我的名稱',
};

const Create = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const store = useSelector(store => store);
    const { noPermission } = useSelector(store => store.noPermission);
    const { modalSetting, setModalSetting } = useModal();
    const [banner, setBanner] = useState(true);
    const [abnormalPopup, setAbnormalPopup] = useState(false);

    const handleErrorResponse = (modalType) => {
        setModalSetting({
            ...modalSetting,
            show: true,
            title: '',
            type: modalType,
            handleConfirm: () => { },
        });
    };

    useEffect(() => {
        if (noPermission === 'package expired fail') {
            setAbnormalPopup(true);
            Cookies.remove('token');
            Cookies.remove('xrToken');
            return;
        }
        if (window.innerWidth < 1080){
            setModalSetting({
                ...modalSetting,
                show: true,
                type: 'type36',
            });
        }
    }, []);

    useEffect(() => {
        if (store.cardListInfo.cardListDetail.length > 0) {
            navigate('/cardList');
        }
    }, [store.cardListInfo]);

    const createEvent = async (e) => {
        e.preventDefault();
        navigate('/chooseType');
        // const response = await createProject(projectDefault, Cookies.get('token'));

        // if (response.status === 'success') {
        //     Cookies.set('projectId', response.project.id);
        //     navigate('/upload?create=true&useMaterialLab=true');  // 第一次創建，預設進入素材庫
        // }

        // if (!response.result && response.status === 'failed') {
        //     handleErrorResponse('type20');
        // }
    };

    return (
        <StyledCreate>
            {abnormalPopup && <AbnormalPopup />}
            <Header />
            {banner && <Banner setBanner={setBanner} />}
            <div className='empty_container'>
                <div className='empty_wrapper'>
                    <Empty />
                    <div>{t('emptyProject')}</div>
                    <Button type='dark' icon={<AiOutlinePlus />} text={t('createFirstProject')} clickEvent={createEvent} />
                </div>
            </div>
            {modalSetting.show && (
                <ModalPopup
                    modalConfig={modalConfig[modalSetting.type]}
                    setModalSetting={setModalSetting}
                    modalSetting={modalSetting}
                />
            )}
        </StyledCreate>
    );
};

export default Create;