// react
import { useEffect, useState } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { SET_USER_INFO } from 'redux/types';

// styled components
import { StyledUnsubscribe } from './StyledUnsubscribe';

// react icon
import { AiOutlineClose, AiFillExclamationCircle, AiOutlineCheck } from 'react-icons/ai';
import { IoIosArrowBack } from 'react-icons/io';
import { BsArrowRepeat } from 'react-icons/bs';

// plugin
import * as dayjs from 'dayjs';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useBeforeunload } from 'react-beforeunload';

// env
import SERVER from 'constants/server';
import HARDCODE from 'constants/hardcode';

// api
import { sendFeedback } from 'api/sendFeedback/sendFeedback.api';
import { refreshToken } from 'api/refreshToken/refreshToken.api';
import { currentInfo } from 'api/currentInfo/currentInfo.api';

// i18n
import { useTranslation, Trans } from 'react-i18next';

const Unsubscribe = (props) => {

    const { t } = useTranslation();

    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let token = decodeURIComponent(urlParams.get('token'));

    const { setShowUnsubscribe, setShowFeature, setShowMessage, setError } = props;

    const [buttonIcon, setButtonIcon] = useState('');

    const goBackEvent = () => {
        if (buttonIcon === 'loading') return;
        setShowUnsubscribe(false);
        setShowFeature(true);
    };

    const closeEvent = () => {
        if (buttonIcon === 'loading') return;
        setShowUnsubscribe(false);
    };

    let data = [
        {
            id: 1,
            value: t('unsubscribe.option1'),
            isChecked: false,
        },
        {
            id: 2,
            value: t('unsubscribe.option2'),
            isChecked: false,
        },
        {
            id: 3,
            value: t('unsubscribe.option3'),
            isChecked: false,
        },
        {
            id: 4,
            value: t('unsubscribe.option4'),
            isChecked: false,
        },
        {
            id: 5,
            value: t('unsubscribe.option5'),
            isChecked: false,
        },
        {
            id: 6,
            value: t('unsubscribe.option6'),
            isChecked: false,
        },
        {
            id: 7,
            value: t('unsubscribe.option7'),
            isChecked: false,
        },
        {
            id: 8,
            value: t('unsubscribe.option8'),
            isChecked: false,
        },
        {
            id: 9,
            value: t('unsubscribe.option9'),
            isChecked: false,
        },
    ];

    const [checkBoxes, setCheckBoxes] = useState(data);

    const handleCheck = (id) => {
        if (buttonIcon === 'loading') return;
        let changedCheckboxes = [...checkBoxes];
        const index = changedCheckboxes.findIndex((element) => element.id === id);
        if (index === -1) return;
        changedCheckboxes[index].isChecked = !changedCheckboxes[index].isChecked;
        setCheckBoxes(changedCheckboxes);
    };

    const [opinion, setOpinion] = useState('');

    const handleOpinionChange = (e) => {
        setOpinion(e.target.value);
    };

    const [confirm, setConfirm] = useState('');
    const [disabledButton, setDisabledButton] = useState(true);

    const handleConfirmChange = (e) => {
        if (buttonIcon === 'loading') return;
        setConfirm(e.target.value);
    };

    useEffect(() => {
        const checkResult = checkBoxes.filter(checkBox => checkBox.isChecked === true);

        if (confirm === 'CONFIRM' && checkResult.length > 0) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [confirm, checkBoxes]);

    const unsubscribeEvent = async (e) => {
        e.preventDefault();
        setButtonIcon('loading');

        let submitData = {
            a1: checkBoxes[0].isChecked,
            a2: checkBoxes[1].isChecked,
            a3: checkBoxes[2].isChecked,
            a4: checkBoxes[3].isChecked,
            a5: checkBoxes[4].isChecked,
            a6: checkBoxes[5].isChecked,
            a7: checkBoxes[6].isChecked,
            a8: checkBoxes[7].isChecked,
            a9: opinion,
        };

        if (token == 'null') {
            token = store.userInfo.bigPlatformToken;
        }

        try {

            if (store.permits.plan === 'basic') {

                // 舊版取消訂閱 ARMAKER 基本版方案
                // 依序取消訂閱兩個次方案，再取消訂閱主方案

                const viewUsageEvent = await axios({
                    method: 'delete',
                    url: `${SERVER.PLATFORM_URL}/api/v1/payment/subscription/${HARDCODE.VIEW_USAGE_ID}`,
                    headers: { 'Authorization': `Bearer ${token}` },
                });
        
                const limitUsageEvent = await axios({
                    method: 'delete',
                    url: `${SERVER.PLATFORM_URL}/api/v1/payment/subscription/${HARDCODE.LIMIT_USAGE_ID}`,
                    headers: { 'Authorization': `Bearer ${token}` },
                });
    
                const basicPackageEvent = await axios({
                    method: 'delete',
                    url: `${SERVER.PLATFORM_URL}/api/v1/payment/subscription/${HARDCODE.BASIC_PACKAGE_ID}`,
                    headers: { 'Authorization': `Bearer ${token}` },
                });

            } else if (store.permits.plan === 'armaker_basic_20220808') {

                // 新版 20220808 之後的基本版
                // 依序取消訂閱一個次方案，再取消訂閱主方案

                const viewUsageEvent = await axios({
                    method: 'delete',
                    url: `${SERVER.PLATFORM_URL}/api/v1/payment/subscription/${HARDCODE.PAGE_VIEW_ID}`,
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                const basicPackageEvent = await axios({
                    method: 'delete',
                    url: `${SERVER.PLATFORM_URL}/api/v1/payment/subscription/${HARDCODE.NEW_BASIC_PACKAGE_ID}`,
                    headers: { 'Authorization': `Bearer ${token}` },
                });
            }

            // 儲存取消續訂的問卷調查
            sendFeedback(submitData, Cookies.get('token'));

            // 方案變更後刷新 token
            const refreshTokenEvent = await refreshToken({ token: Cookies.get('token'), username: store.userInfo.username });
            Cookies.set('token', refreshTokenEvent.token);

            // 並更新到 redux (因為這裡沒有 reload)
            const userInfo = await currentInfo();
            dispatch({ type: SET_USER_INFO, payload: userInfo });

            setButtonIcon('success');

            setTimeout(() => {
                setShowUnsubscribe(false);
                setShowMessage(true);
            }, 2000);

        } catch (error) {
            // console.log(error);
            setShowUnsubscribe(false);
            setError('unsubscribe');
        }
    };

    useBeforeunload((event) => {
        if (disabledButton === false) {
            event.preventDefault();
            let confirmationMessage = true;
            (event || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Webkit, Safari, Chrome
        }
    });

    return (
        <StyledUnsubscribe disabled={disabledButton}>
            <div className='container'>
                <div className='title'>
                    <div className='goBackIcon' onClick={goBackEvent}><IoIosArrowBack />{t('unsubscribe.allPlan')}</div>
                    <div>{t('unsubscribe.continue')}</div>
                    <div className='closeIcon' onClick={closeEvent}><AiOutlineClose /></div>
                </div>
                <div className="bg">
                    <div className='formBackground'>
                        <form autoComplete='off' onSubmit={unsubscribeEvent}>
                            <div>
                                <p className='question'>{t('unsubscribe.question')}
                                    <span className='required'>({t('unsubscribe.required')})</span>
                                </p>

                                {checkBoxes.map((item, index) => (
                                    <div key={index} className='checkboxPanel'>
                                        <label className='checkbox'>
                                            <input
                                                type="checkbox"
                                                id={item.id}
                                                checked={item.isChecked}
                                                onChange={() => handleCheck(item.id)}
                                            />
                                            <span>{item.value}</span>
                                        </label>
                                    </div>
                                ))}

                                <div>
                                    <textarea
                                        name="opinion"
                                        placeholder={t('unsubscribe.tellMore')}
                                        value={opinion}
                                        onChange={handleOpinionChange}
                                        className='textArea'
                                    >
                                    </textarea>
                                </div>
                            </div>

                            <div className='notiSection'>
                                <div className='notification'>
                                    <div className='icon'>
                                        <AiFillExclamationCircle />
                                    </div>
                                    <div>
                                        <Trans i18nKey="unsubscribe.notification1">
                                            如果您確定取消續訂，在本期合約到期日<span>{{ expiredDate: dayjs(store.permits.expiredAt).format('YYYY/MM/DD') }}</span>前，您依然可以使用基本版所有功能，您可以隨時重新訂閱基本版。
                                        </Trans>
                                    </div>
                                </div>
                                <div className='notification'>
                                    <div className='icon'>
                                        <AiFillExclamationCircle />
                                    </div>
                                    <div>
                                        <div>
                                            <Trans i18nKey="unsubscribe.notification2">
                                                <span>{{ theDayAfterExpired: dayjs(store.permits.expiredAt).add(1, 'day').format('YYYY/MM/DD') }}</span>之後，您的方案將變更為<span>體驗版</span>，變更內容如下：
                                            </Trans>
                                        </div>
                                        <div className='changeList'>
                                            <div>{t('unsubscribe.list1')} <span>{t('unsubscribe.list1Remark')}</span></div>
                                            <div>{t('unsubscribe.list2')}</div>
                                            <div>{t('unsubscribe.list3')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <p>{t('unsubscribe.enterConfirm')}</p>
                                <input type="text" className='confirmInput' value={confirm} onChange={handleConfirmChange} />
                                <div className='buttons'>
                                    <button type="submit" className='primaryButton' disabled={disabledButton}>
                                        {buttonIcon === 'loading' ? <BsArrowRepeat className='arrowIcon' /> : buttonIcon === 'success' ? <AiOutlineCheck className='checkIcon' /> : ''}
                                        {t('unsubscribe.submitButton')}
                                    </button>
                                    <div className='cancelButton' onClick={closeEvent}>{t('cancel')}</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </StyledUnsubscribe>
    );
};

export default Unsubscribe;