import styled, { css } from 'styled-components';

export const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    padding: ${({ size }) => size === 'lg' ? '12px 24px' : size === 'md' ? '8px 24px' : '4px 16px' };
    border-radius: 8px;
    cursor: pointer;

    >svg {
        font-size: 20px;
    }

    >span {
        font-size: ${({ size }) => size === 'lg' ? '16px' : '14px' };
    }

    /* button 類型有： primary / secondary / dark / light */
    ${({ type }) => {
        switch (type) {
            case 'primary':
                return css`
                    background: #E01D62;
                    border: 1px solid #E01D62;
                    color: #FFFFFF;

                    &:hover {
                        background: #FF5E8F;
                        border: 1px solid #FF5E8F;
                    }

                    &:disabled {
                        background: #F6BBD0;
                        border: 1px solid #F6BBD0;
                    }
                `;
            case 'secondary':
                return css`
                    background: #FF7043;
                    border: 1px solid #FF7043;
                    color: #FFFFFF;

                    &:hover {
                        background: #FFA270;
                        border: 1px solid #FFA270;
                    }

                    &:disabled {
                        background: #FFD4C7;
                        border: 1px solid #FFD4C7;
                    }
                `;
            case 'dark':
                return css`
                    background: #27282C;
                    border: 1px solid #27282C;
                    color: #FFFFFF;

                    &:hover {
                        background: #47484B;
                        border: 1px solid #47484B;
                    }

                    &:disabled {
                        background: #C9C9CA;
                        border: 1px solid #C9C9CA;
                    }
                `;
            case 'light':
                return css`
                    background: #EBEBEB;
                    border: 1px solid #EBEBEB;
                    color: #595959;

                    &:hover {
                        background: #D9D9D9;
                        border: 1px solid #D9D9D9;
                    }

                    &:disabled {
                        background: #EBEBEB;
                        border: 1px solid #EBEBEB;
                        color: #FFFFFF;
                    }
                `;
        }
    }}
`;