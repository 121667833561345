import { useState } from 'react';

// redux
import { useSelector } from 'react-redux';

// styles
import { StyledScanViewerTag } from './StyledScanViewerTag';

// images
import { ReactComponent as LinkIcon } from './images/link.svg';

// react icons
import { BsPlus } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';

const ScanViewerTag = ({ item, index, images, displayPanel, setDisplayPanel }) => {

    const { tagSwitch } = useSelector(store => store.switch);
    const [displayMobilePanel, setDisplayMobilePanel] = useState(false);

    const handleClickTagBadge = (id) => {
        // 標籤為id的面板已展開則收合
        if (id === displayPanel) {
            setDisplayPanel(null);
        }
        // 標籤為id的面板未展開則展開
        if (id !== displayPanel) {
            setDisplayPanel(id);
        }
    };

    // 驗證 URL
    const validateUrl = (url) => {
        if (!url.includes('http://') && !url.includes('https://')) {
            url = 'https://' + url;
        }
        return url;
    };

    // 定義顯示「查看更多」的邏輯
    const tooMuchContent = (item.title && item.content && (item.icon || item.url)) || (item.content.length >= 36);

    return (
        <StyledScanViewerTag
            left={item.dataPosition.split(' ')[0]}
            top={item.dataPosition.split(' ')[1]}
            tooMuchContent={tooMuchContent}
            style={{
                display: (tagSwitch && (images.startIndex === item.scankitIndex)) ? 'block' : 'none',
            }}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseMove={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            onMouseLeave={(e) => e.stopPropagation()}
            onDragStart={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
            onTouchMove={(e) => e.stopPropagation()}
            onTouchEnd={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
        >
            <span className='tagBadge' onClick={() => handleClickTagBadge(index + 1)}>
                {index + 1}
            </span>
            <div className='tagPanel' style={{ display: displayPanel === index + 1 ? 'flex' : 'none' }}>
                <div className='tagPanelContent'>
                    <div className='textSection'>{item.title}</div>
                    <div className='textSection'>{item.content}</div>
                    {item.icon && (
                        <div className='imageSection'>
                            <img src={item.icon} alt='scankit-icon' />
                        </div>
                    )}
                    {item.url && (
                        <div className='linkSection'>
                            <LinkIcon />
                            <a href={validateUrl(item.url)} target='_blank'>{item.url}</a>
                        </div>
                    )}
                </div>
                <div className='seeMoreButton' onClick={() => setDisplayMobilePanel(true)}>
                    <span>查看更多</span> <BsPlus />
                </div>
            </div>
            {displayMobilePanel && (
                <div className='tagMobilePanel'>
                    <AiOutlineClose className='closeButton' onClick={() => setDisplayMobilePanel(false)} />
                    <div className='itemTitle'>{item.title}</div>
                    <div className='itemContent'>{item.content}</div>
                    {item.icon && (
                        <div className='itemImage'>
                            <img src={item.icon} alt='scankit-icon' />
                        </div>
                    )}
                    {item.url && (
                        <div className='itemLink'>
                            <LinkIcon />
                            <a href={validateUrl(item.url)} target='_blank'>{item.url}</a>
                        </div>
                    )}
                </div>
            )}
        </StyledScanViewerTag>
    );
};

export default ScanViewerTag;