import styled from 'styled-components';

export const StyledImage360 = styled.div`
    width: 100%;
    height: 100%;
    /* cursor: ew-resize; */
    overflow: hidden;
    box-sizing: border-box;
    touch-action: none;
    display: flex;
    justify-content: center;
    align-items: center;

    .allContainer {
        position: relative;

        .imgContainer {
            width: 100%;
            height: 100%;

            .img360 {
                max-width: 100%;
                max-height: calc(100vh - 100px);
                object-fit: contain;
            }
        }

        .tagContainer {
            width: 100%;
            height: 100%;
        }
    }

    .gestureIcon {
        width: 68px;
        height: 40px;
        position: absolute;
        top: calc(65% - 34px);
        left: calc(50% - 20px);
        filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2));
        animation: bounce 4s ease-in-out infinite;
    }

    @keyframes bounce {
        0% {
            transform: translate(0px);
        }
        25% {
            transform: translate(-40px);
        }
        50% {
            transform: translate(40px);
        }
        75% {
            transform: translate(0px);
            opacity: 100%;
        }
        100% {
            opacity: 0;
        }
    }
`;