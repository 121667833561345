//styled
import { StyledCatchError } from './StyledCatchError';

// images
import { ReactComponent as Error002 } from './images/error-500.svg';

const CatchError = ({ title, description, button }) => {

    const retryEvent = () => {
        window.open(window.location.origin, '_self');
    };

    return (
        <StyledCatchError>
            <Error002 className='errorIcon'/>
            <div className='errorTitle'>{title}</div>
            <div className='errorDescription'>{description}</div>
            <button className='retryButton' onClick={retryEvent}>{button}</button>
        </StyledCatchError>
    );
};

export default CatchError;