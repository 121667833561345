import styled from 'styled-components';

export const StyledCheckForm = styled.div`
    padding: 40px;
    display: flex;
    justify-content:center;
    flex-direction: row;
    column-gap: 24px;
    @media all and (max-width:992px) {
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
    @media screen and (max-width:414px) {
        padding: 40px 24px;
    }
    .modal-card {
        width: 32%;
        text-align: left;
        background: white;
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        padding: 32px 24px 48px 24px;
        overflow: hidden;
        @media all and (max-width:1366px) {
            height:722px;
            width: 32%;
        }
        @media all and (max-width:992px) {
            width: 64.7%;
            height:348px;
            margin-bottom:40px;
        }
        @media screen and (max-width:768px) {
            width: 59.4%;
        }
        @media screen and (max-width:414px) {
            width: 88.4%;
        }
        &:nth-child(2){
            @media all and (max-width:992px) {
                height:678px;
            }
            @media screen and (max-width:768px) {
                height:722px;
            }
            @media screen and (max-width:414px) {
                height:744px;
            }
        }
    }
    .modal-input-container {
    padding: 8px;
    background: white;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    }
    .modal-input-container svg {
    color: #666666;
    padding: 4px;
    }
    .modal-input {
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    }
    .modal-submit-button {
        width: 100%;
        background: #ED0973;
        border: none;
        border-radius: 4px;
        color: white;
        padding: 12px 0px;
        margin: 40px 0px;
        cursor: pointer;
        &:hover{
            opacity: 0.7;
        }
    }
    .modal-disable-button {
    width: 100%;
    background: #FAB5D5;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 12px 0px;
    margin: 40px 0px;
    }
    .link-with-underline {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #333333;
    cursor: pointer;
    text-align: center;
    }
    .credit-card-container div {
    width: 100%;
    }
    .payment-info-header {
    background: #FF744E;
    padding: 20px 24px;
    }
    .payment-info-header span {
    padding: 8px 24px;
    color: white;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 40px;
    }
    .payment-info-content {
    padding: 0px 24px;
    }
    .expiration-container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    border-bottom: 1px dashed #9E9E9E;
    padding-bottom: 32px;
    margin-top: 32px;
    }
    .payment-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #333333;
    margin: 16px 0px;
    }
    .payment-notification {
    background: #F2F2F2;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: #666666;
    margin-top: 40px;
    }
    .payment-notification span {
    color: #54C68F;
    }
    .link{
        color: #54C68F; 
        text-decoration: underline;
    }
    .read-checkbox {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    justify-content: center;
    padding: 4px 0px;
    margin-top: 24px;
    color: #333333;
    }
    .payment-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    background: #F2F2F2;
    z-index: 99999;
    }
    .payment-modal-header {
    background: linear-gradient(79.78deg, #ED0973 0%, #FF744E 100%);
    opacity: 0.87;
    padding: 18px;
    color: white;
    }
`;