import axios from 'axios';
import SERVER from 'constants/server';

const axiosApi = axios.create({
    baseURL: SERVER.SERVER_URL,
});

export const getErrorMsg = (err) => err?.response?.data.msg || err?.response?.data.message;

export const setHeader = (key, value) => {
    axiosApi.defaults.headers.common[key] = value;
};

export const removeHeader = (key) => {
    delete axiosApi.defaults.headers.common[key];
};

export default axiosApi;