import styled from 'styled-components';

export const StyledScanKitInfo = styled.div`
    width: 100%;
    height: 100vh;
    background: #F2F2F2;
    overflow: auto;

    .headerContainer {
        width: 100%;
        height: 56px;
        background: linear-gradient(79.78deg, #ED0973 0%, #FF744E 100%);

        color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;

        .homeButton {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);
            cursor: pointer;
            position: absolute;
            top: 18px;
            left: 24px;
        }
    }

    .contentContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        height: calc(100% - 56px);
        padding: 40px 0px 154px 0px;

        .projectInfoContainer {
            /* responsive container */
            width: 60%;
            height: 100%;
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-column-gap: 24px;

            .cardContainer {
                background: #FFFFFF;
                border: 1px solid #E6E6E6;
                border-radius: 8px;
                position: relative;
                overflow: hidden;
                padding: 32px 24px;
            }

            .cardContainer::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 8px;
                background: #FFA270;
            }

            /* project details section */
            .projectThumbnail {
                width: 100%;
                height: 190px;
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.98) 0%, rgba(255, 255, 255, 0.98) 100%), #000;
                border: 1px solid #E6E6E6;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                .thumbnailImage {
                    /* width: 100%; */
                    max-height: 100%;
                    object-fit: cover;
                }
            }

            .projectDetails {
                margin-top: 33px;

                .detailList {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;

                    .listKey {
                        display: flex;
                        column-gap: 8px;
                        align-items: center;
                        >svg {
                            font-size: 23px;
                            fill: #333333;
                        }
                        >span {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: 0.02em;
                            color: #333333;
                        }
                    }

                    .listValue {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.02em;
                    }

                    .scankitTag {
                        padding: 1px 16px;
                        background: #FFA270;
                        border-radius: 24px;
                        color: #FFFFFF;
                    }

                    .publicTag {
                        background: #2BB8AF;
                    }

                    .draftTag {
                        background: #CCCCCC;
                    }

                    .highlightText {
                        color: #FF7043;
                    }

                    .disableText {
                        color: rgba(0, 0, 0, 0.2);
                    }
                }

                .publicButton {
                    padding: 8px 24px;
                    background: #FF7043;
                    border: 1px solid #FF7043;
                    border-radius: 4px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #FFFFFF;
                    margin-top: 44px;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

            /* edit information section */
            .formInputSection {
                margin-bottom: 68px;
                
                .labelPanel {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: 500;
                    letter-spacing: 0.02em;
                    margin-bottom: 18px;

                    .projectName {
                        font-size: 14px;
                        line-height: 20px;
                        color: #333333;
                    }

                    .textCounter {
                        font-size: 12px;
                        line-height: 18px;
                        color: #666666;
                    }
                }

                .textInput {
                    font-family: inherit;
                    width: 100%;
                    background: #FFFFFF;
                    border: 1px solid #CCCCCC;
                    border-radius: 4px;
                    padding: 8px 16px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #333333;
                }
            }
        }

        .buttonContainer {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 80px;
            background: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.2);

            .buttonWrapper {
                /* responsive container */
                width: 60%;
                display: flex;
                justify-content: space-between;

                .buttonGroup {
                    display: flex;
                    column-gap: 24px;

                    .arrowIcon {
                        animation: rotateMove 2s infinite linear;
                    }

                    @keyframes rotateMove {
                        0%{
                            transform: rotateZ(0deg);
                        }
                        100%{
                            transform: rotateZ(360deg);
                        }
                    }
                }
            }
        }
    }
`;