import { RESET_ALL, SET_NO_PERMITS } from 'redux/types';

const defaultValue = {
    noPermission: '',
};

const noPermissionReducer = (state = defaultValue, action) => {
    switch (action.type) {
        case SET_NO_PERMITS:
            return {
                ...state, ...action.payload,
            };
        case RESET_ALL:
            return defaultValue;
        default: return state;
    }
};

export default noPermissionReducer;