import { OPEN_MODAL, CLOSE_MODAL } from 'redux/types';

const initModal = {
    isOpen: false,
    icon: '',
    title: '',
    description: '',
    description2: '',
    button1: '',
    button2: '',
    handleConfirm: () => {},
};

const modalReducer = (state = initModal, action) => {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state, ...action.payload,
            };
        case CLOSE_MODAL:
            return initModal;
        default: return state;
    }
};

export default modalReducer;