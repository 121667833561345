import styled from 'styled-components';

export const StyledViewer = styled.div`
    width: 100%;
    height: 100vh;
    overflow: auto;

    .detailsButton {
        position: fixed;
        top: 24px;
        left: 24px;
        z-index: 99999;

        .switchContainer {
            width: 70px;
            height: 40px;
            padding: 2px;
            border-radius: 20px;
            background: #F1F1F1;
            border: 2px solid #F1F1F1;

            .switchToggle {
                position: relative;
                width: 32px;
                height: 32px;
                background: rgba(255, 112, 67, 0.90);
                border-radius: 50%;
                margin-left: 0;
                cursor: pointer;
                transition: .3s;

                >svg {
                    width: 22px;
                    height: 22px;
                    color: #FFFFFF;
                }
            }
        }

        .inActive .switchToggle {
            background: rgba(0, 0, 0, 0.60);
            margin-left: calc(100% - 32px);
        }
    }

    .scankitViewerLogo {
        position: fixed;
        top: 24px;
        right: 24px;
        z-index: 99999;
    }

    .actionButtons {
        position: fixed;
        right: 48px;
        bottom: 56px;
        z-index: 99999;
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        @media all and (max-width: 414px) {
            right: 36px;
            bottom: 36px;
            flex-direction: row;
            column-gap: 16px;
        }

        >div {
            width: 40px;
            height: 40px;
            padding: 6px;
            border-radius: 8px;
            background: #FFFFFF;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.20);
            cursor: pointer;
        }

        .zoomDisabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        .zoomHover:hover {
            background: #e4e4e4;
        }
    }

    .contentContainer {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;

        >div {
            position: absolute;
            top: 33px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: linear-gradient(79.78deg, #FF7043 0%, #FFA270 100%);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
        }

        >div:nth-child(1) {
            left: 8px;
            animation: lds-ellipsis1 0.6s infinite;
        }

        >div:nth-child(2) {
            left: 8px;
            animation: lds-ellipsis2 0.6s infinite;
        }

        >div:nth-child(3) {
            left: 32px;
            animation: lds-ellipsis2 0.6s infinite;
        }

        >div:nth-child(4) {
            left: 56px;
            animation: lds-ellipsis3 0.6s infinite;
        }
    }

    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }
`;