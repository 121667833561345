const env = window.location.hostname === 'localhost' ? 'local' : process.env.BUILD_ENV || process.env.REACT_APP_BUILD_ENV;
const SERVER = {
    local: {
        SERVER_URL: 'https://ar-api-dev.istaging.com',
        PLATFORM_URL: 'https://sso-dev.istaging.com',
        EDITOR_URL: 'http://localhost:3020',
        VIEWER_URL: 'http://localhost:3021',
        PRODUCT_ID: 'ff8081817ca04e50017ca21459620000',
        AR_MAKER: 'https://ar-dev.istaging.com',
        BILLING: 'https://ar-dev.istaging.com/billing',
        ARPLATFORM: 'http://localhost:3022',
        ISTAGING: 'https://dtpr6ylugmdvf.cloudfront.net',
        GET_DOMAIN: 'localhost',
    },
    development: {
        SERVER_URL: 'https://ar-api-dev.istaging.com',
        PLATFORM_URL: 'https://sso-dev.istaging.com',
        EDITOR_URL: 'https://areditor-dev.istaging.com',
        VIEWER_URL: 'https://arviewer-dev.istaging.com',
        PRODUCT_ID: 'ff8081817ca04e50017ca21459620000',
        AR_MAKER: 'https://ar-dev.istaging.com',
        BILLING: 'https://ar-dev.istaging.com/billing',
        ARPLATFORM: 'https://armaker-dev.istaging.com',
        ISTAGING: 'https://dtpr6ylugmdvf.cloudfront.net',
        GET_DOMAIN: '.istaging.com',
    },
    test: { // temporary use dev env
        SERVER_URL: 'https://ar-api-test.istaging.com',
        PLATFORM_URL: 'https://sso-test.istaging.com',
        EDITOR_URL: 'https://areditor-test.istaging.com',
        VIEWER_URL: 'https://arviewer-test.istaging.com',
        PRODUCT_ID: 'ff8081817ca04e50017ca21459620000',
        AR_MAKER: 'https://ar-dev.istaging.com',
        BILLING: 'https://ar-billing-test.istaging.com/',
        ARPLATFORM: 'https://armaker-test.istaging.com',
        ISTAGING: 'https://dtpr6ylugmdvf.cloudfront.net',
        GET_DOMAIN: '.istaging.com',
    },
    production: {
        SERVER_URL: 'https://ar-api.istaging.com',
        PLATFORM_URL: 'https://sso.istaging.com',
        EDITOR_URL: 'https://areditor.istaging.com',
        VIEWER_URL: 'https://arviewer.istaging.com',
        PRODUCT_ID: 'ff8081817d274eb7017dfa0c507503ee',
        AR_MAKER: 'https://ar.istaging.com',
        BILLING: 'https://ar.istaging.com/billing',
        ARPLATFORM: 'https://armaker.istaging.com',
        ISTAGING: 'https://www.istaging.com',
        GET_DOMAIN: '.istaging.com',
    },
};

export default SERVER[env];

