import styled from 'styled-components';

export const StyledUpgrade = styled.div`
    .titleUpgrade{
        position: relative;
        color: #fff;
        font-size: 20px;
        background: linear-gradient(79.78deg, #ED0973 0%, #FF744E 100%);
        height: 64px;
        line-height: 64px;
        .closeIcon{
            position: absolute;
            top:5px;
            right: 20px;
            font-size: 25px;
            cursor: pointer;
        }
    }
    .content-card-modal {
        z-index: 1;
        width: 978px;
        width:100%;
        border-radius: 8px;
        position: relative;
        animation: animate 0.3s;
        overflow: hidden;
        margin: 0 auto;
        @media all and (max-width:992px) {
            width:100%;
        }
    }
    .modal-close-button {
        border: none;
        background: transparent;
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 16px;
    }
`;