import { RESET_ALL, SET_USER_INFO } from 'redux/types';


// interface UserInfo {
//     expireDate: 0,
//     userId: '',
//     username: '',
//     userThumb: '',
//     thirdParty: '',
//     phone: '',
//     firstName: '',
//     lastName: '',
//     email: ''
// }

const defaultUserInfo = null;

const userInfoReducer = (state = defaultUserInfo, action) => {
    switch (action.type) {
        case SET_USER_INFO:
            return {
                ...state, ...action.payload,
            };
        case RESET_ALL:
            return defaultUserInfo;
        default: return state;
    }
};

export default userInfoReducer;