import React, { useState } from 'react';

// styled components
import { StyledLoading } from './StyledLoading';

const Loading = (props) => {

    const [word, setWord] = useState({ title: props.title, txt: props.txt });

    return (
        <StyledLoading mask={props.mask}>
            <div className="whiteBg">
                <div className="loading">
                    <div id="loading-wrapper">
                        <div id="loading-content"></div>
                    </div>
                </div>
                <div className="title">
                    {
                        word.title
                    }
                </div>
                <div className="txt">
                    {
                        word.txt
                    }
                </div>
            </div>
        </StyledLoading>
    );
};

export default Loading;