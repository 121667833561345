import { combineReducers } from 'redux';
import userInfoReducer from './reducers/userInfo';
import projectRecordReducer from './reducers/projectRecord';
import cardListReducer from './reducers/cardList';
import permitsReducer from './reducers/permits';
import scankitUsageReducer from './reducers/scankitUsage';
import scankitListReducer from './reducers/scankitList';
import imageListReducer from './reducers/imageList';
import tagListReducer from './reducers/tagList';
import modalReducer from './reducers/modal';
import switchReducer from './reducers/switch';
import noPermissionReducer from './reducers/noPermission';


const rootReducer = combineReducers({
    userInfo: userInfoReducer,
    projectRecord: projectRecordReducer,
    cardListInfo: cardListReducer,
    permits: permitsReducer,
    scankit: scankitUsageReducer,
    scankitList: scankitListReducer,
    imageList: imageListReducer,
    tagList: tagListReducer,
    modal: modalReducer,
    switch: switchReducer,
    noPermission: noPermissionReducer,
});


export default rootReducer;