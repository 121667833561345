// styled components
import { StyledSwitch } from './StyledSwitch';

const Switch = ({ turn }) => {

    return (
        <StyledSwitch>
            <div className={turn ? 'color colorMove' : 'color'}>
                <div className={turn ? 'shape shapeMove' : 'shape'}></div>
            </div>
        </StyledSwitch>
    );
};

export default Switch;