import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

// redux
import { SET_USER_INFO, SET_PROJECT_RECORD, SET_PERMITS, RESET_ALL, SET_NO_PERMITS } from 'redux/types';

// components
import SmallLoadIngIcon from 'components/SmallLoadIngIcon/SmallLoadIngIcon';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import Button from 'components/Button/Button';

// api
import { loginPost } from 'api/login/login.api';
import { loginGet } from 'api/loginInfo/loginInfo.api';
import { currentInfo } from 'api/currentInfo/currentInfo.api';
import { checkNoPermission } from 'api/checkNoPermission/checkNoPermission.api';
import { subscribeFreePlan } from 'api/subscribeFree/subscribeFree.api';

// react icon
import {
    AiFillEye,
    AiFillEyeInvisible,
    AiFillExclamationCircle,
    AiOutlineCheck,
    AiOutlineLoading3Quarters,
} from 'react-icons/ai';
import { FaGlobe, FaUserAlt } from 'react-icons/fa';
import { BsFillBagFill, BsXLg } from 'react-icons/bs';

// image
import bg from './images/bg.svg';
import logo from './images/logo.svg';

// styled
import { StyledLogin } from './styledLogin';

// plugin
import Cookies from 'js-cookie';

// i18n
import { useTranslation, Trans } from 'react-i18next';

// env
import SERVER from 'constants/server';

// config
import { modalConfig } from 'config/modal';

// hooks
import useModal from 'hooks/useModal';

const Login = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const userInfo = useSelector(store => store.userInfo);
    const dispatch = useDispatch();
    const { modalSetting, setModalSetting } = useModal();
    const [subscribeFree, setSubscribeFree] = useState(false);

    const [apiStatus, setApiStatus] = useState(0);
    const [inputErrors, setInputErrors] = useState({
        accountStatus: false,
        passwordStatus: false,
        msg: t('wrong'),
    });

    const [loginUserName, setLoginUserName] = useState('');

    const userNameLoginChange = (e) => {
        setLoginUserName(e.target.value);
        setInputErrors({
            ...inputErrors,
            accountStatus: false,
            passwordStatus: false,
        });
    };

    const [loginPassword, setLoginPassword] = useState('');

    const passwordLoginChange = (e) => {
        setLoginPassword(e.target.value);
        setInputErrors({
            ...inputErrors,
            accountStatus: false,
            passwordStatus: false,
        });
    };

    const loginInfo = {
        password: loginPassword,
        productKey: 'test',
        username: loginUserName,
    };

    const accountInput = useRef();
    const passwordInput = useRef();

    const loginEvent = async (e) => {

        e.preventDefault();
        
        if (loginUserName === '') {
            setInputErrors({
                ...inputErrors,
                accountStatus: true,
            });
            accountInput.current.focus();
            return;
        }

        if (loginPassword === '') {
            setInputErrors({
                ...inputErrors,
                passwordStatus: true,
            });
            passwordInput.current.focus();
            return;
        }

        setApiStatus(1);

        try {
            const code = await loginPost(loginInfo);
            if (code === '000029') {
                const noPermission = await checkNoPermission(Cookies.get('xrToken'));
                // console.log('沒有權限:' + noPermission);
                if (noPermission === '000031') {
                    // console.log('方案到期: package expired fail');
                    dispatch({ type: SET_NO_PERMITS, payload: { noPermission: 'package expired fail' } });
                } else if (noPermission === '000032') {
                    // console.log('沒有方案: wait for subscribe');
                    setApiStatus(0);
                    setSubscribeFree(true);
                    Cookies.remove('token');
                    return;
                }
            }
            const [projectInfo, userInfo] = await Promise.all([loginGet(), currentInfo()]);
            const { permits, expiredAt, createdAt, startAt } = userInfo.ssoData.permission;
            const limits = { ...permits, expiredAt, createdAt, startAt };
            dispatch({ type: SET_USER_INFO, payload: userInfo });
            dispatch({ type: SET_PERMITS, payload: limits });
            dispatch({ type: SET_PROJECT_RECORD, payload: { ...projectInfo } });
            setApiStatus(2);
        } catch (err) {
            // console.log('登入失敗:' + err);
            if (err.response.data.msg === 'big platform login fail') {
                setInputErrors({
                    ...inputErrors,
                    accountStatus: true,
                    passwordStatus: true,
                    msg: err.response.data.msg,
                });
                setApiStatus(0);
            } else {
                setModalSetting({
                    ...modalSetting,
                    show: true,
                    type: 'type37',
                    handleConfirm: () => {
                        window.location.reload();
                    },
                });
            }
        }
    };

    useEffect(() => {
        // 顯示環境變數: test / development / production
        // console.log(process.env.REACT_APP_BUILD_ENV);
        accountInput.current.focus();
    }, []);

    useEffect(() => {
        if (userInfo == null) return;
        navigate('/');
    }, [userInfo]);

    const [passwordShow, setPasswordShow] = useState(true);

    const passwordShowEvent = () => {
        setPasswordShow(!passwordShow);
    };

    const [langList, setLangList] = useState(false);
    const [currentLang, setCurrentLang] = useState(Cookies.get('lang'));

    const openLangListEvent = (e) => {
        e.stopPropagation();
        setLangList(!langList);
    };

    const lanEvent = (lang) => {
        i18n.changeLanguage(lang);
        Cookies.set('lang', lang);
        setInputErrors({ ...inputErrors, msg: t('wrong') });
        setLangList(false);
        setCurrentLang(lang);
        accountInput.current.focus();
    };

    useEffect(() => {
        document.addEventListener('click', (e) => setLangList(false));
        window.history.pushState({}, 0, window.location.origin + window.location.pathname);
    }, []);

    const [checked, setChecked] = useState(false);
    const checkboxEvent = () => {
        setChecked(!checked);
    };

    return (
        <StyledLogin>
            {subscribeFree && (
                <div className='subscribeFree'>
                    <div className='subscribeFree_popup'>
                        <BsXLg className='closeIcon' onClick={() => setSubscribeFree(false)} />
                        <p className='title'>{t('subscribeFree.dearMember')}</p>
                        <div className='subtitle'>
                            <Trans i18nKey="message.error002Subtitle">
                                請嘗試其他帳號或直接登入。<br/>
                                如需進一步取得協助，請聯繫
                            </Trans>
                            <span>hello@istaging.com</span>
                        </div>
                    </div>
                </div>
            )}
            {modalSetting.show && (
                <ModalPopup
                    modalConfig={modalConfig[modalSetting.type]}
                    setModalSetting={setModalSetting}
                    modalSetting={modalSetting}
                />
            )}
            <div className="whiteBg">
                <a href={`${SERVER.AR_MAKER}/?lang=${currentLang}`} target="_blank" rel="noopener">
                    <img src={logo} className="logo" alt="ARmaker Logo" />
                </a>
                <div className="inputErrorMsg flexCenter">
                    {(inputErrors.accountStatus || inputErrors.passwordStatus) && (
                        <span className="flexCenter">
                            <AiFillExclamationCircle fontSize={18} />
                            {t('wrong')}
                        </span>
                    )}
                </div>

                <h1>{t('loginEntertitle')}</h1>

                <form className='formTable' autoComplete="off" onSubmit={loginEvent}>
                    <div className="accountInputPanel inputCon">
                        <span><FaUserAlt /></span>
                        <input
                            ref={accountInput}
                            name="username"
                            className="inputs accountInput"
                            value={loginUserName}
                            onChange={userNameLoginChange}
                            type="text"
                            placeholder={t('enterUsername')}
                        />
                    </div>

                    <div className="passwordInputPanel inputCon">
                        <span><BsFillBagFill /></span>
                        <input
                            ref={passwordInput}
                            name="password"
                            className="inputs passwordInput"
                            value={loginPassword}
                            onChange={passwordLoginChange}
                            type={passwordShow ? 'password' : 'text'}
                            placeholder={t('enterUserpassword')}
                        />
                        {passwordShow
                            ? <AiFillEye fontSize={20} className="eyeIcon" onClick={passwordShowEvent} />
                            : <AiFillEyeInvisible fontSize={20} className="eyeIcon" onClick={passwordShowEvent} />
                        }
                    </div>

                    <div className="passwordInputLable">
                        <Link to={'/forget'}>{t('forgot')}</Link>
                    </div>
                    <button type="submit" className='button'>
                        {apiStatus === 1 ? <AiOutlineLoading3Quarters className='bsRotate' /> : apiStatus === 2 ? <AiOutlineCheck /> : ''}
                        {t('login')}
                    </button>
                </form>

                {/* <div className='say'>
                    {t('createAccount')} <Link to="/register">{t('signup')}</Link>
                </div> */}
                <div className='langBtn' onClick={openLangListEvent}>
                    <FaGlobe className='globa' />
                    {currentLang === 'zh-tw' && '繁體中文'}
                    {currentLang === 'en' && 'English'}
                    {currentLang === 'fr' && 'Français'}
                    {currentLang === 'ko' && '한국어'}
                </div>
                {langList && (
                    <ul className='langList'>
                        <li onClick={() => lanEvent('zh-tw')} className={currentLang === 'zh-tw' && 'current'}>
                            繁體中文
                            <AiOutlineCheck />
                        </li>
                        <li onClick={() => lanEvent('en')} className={currentLang === 'en' && 'current'}>
                            English
                            <AiOutlineCheck />
                        </li>
                        <li onClick={() => lanEvent('fr')} className={currentLang === 'fr' && 'current'}>
                            Français
                            <AiOutlineCheck />
                        </li>
                        <li onClick={() => lanEvent('ko')} className={currentLang === 'ko' && 'current'}>
                            한국어
                            <AiOutlineCheck />
                        </li>
                    </ul>
                )}
            </div>
            <img src={bg} className='bg' alt="background" />
        </StyledLogin>
    );
};

export default Login;