import axiosApi, { setHeader } from 'api/root.api';

export const createProject = async (body, token) => {
    try {
        const urlEnd = '/editor/project/v1/create';
        setHeader('Authorization', `Bearer ${token}`);
        const response = await axiosApi.post(urlEnd, body);
        const result = response.data;
        return result;
    } catch (error) {
        return {
            result: false,
            status: 'failed',
        };
    }
};