import styled from 'styled-components';

export const StyledSuccessProject = styled.div`
    position: fixed;
    left:0;
    top:0;
    z-index: 9999999;
    background-color: rgba(0,0,0,0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: auto;
    .copySuccess{
        width: 230px;
        background: #54C68F;
        border-radius: 4px;
        padding: 8px 24px;
        color: #FFFFFF;
        line-height: 140%;
        position: absolute;
        top: calc((100vh - 588px) / 2);
        left: calc((100vw - 230px) / 2);
        display: flex;
        align-items: center;
        svg{
            margin-right: 4px;
        }
    }
    .whiteBg{
        width: 560px;
        height: 464px;
        position: relative;
        background-color: #fff;
        border-radius: 8px;
        padding: 25px;
        .closeIcon{
            position: absolute;
            right: 10px;
            top:10px;
            font-size:25px;
            color: rgba(0,0,0,0.8);
            svg{
                color: rgba(0,0,0,0.8); 
            }
        }
        .title{
            padding-top: 10px;
            text-align:center;
            display: block;
            font-size: 16px;
            padding-bottom: 30px;
            color: #222;
            vertical-align: middle;
            font-weight: bold;
            .icon{
                margin-right: 10px;
                display: inline-block;
                font-size:30px;
                color: #54C68F;
                position: relative;
                top:2px;
                svg{
                    position: relative;
                    top:6px;
                }
            }
        }
        .copyBar{
            text-align:center;
            padding-bottom: 20px;
            input{
                width: 350px;
                border-radius: 3px;
                border:1px solid #202020;
                height: 32px;
                padding-right: 10px;
                padding-left: 10px;
                text-align:left;
                &:focus{
                    outline-color: #FF744E;
                }
            }
            .copy{
                background-color: #ED0973;
                color: #fff;
                display: inline-block;
                width: 34px;
                height: 34px;
                font-size:20px;
                line-height: 32px;
                text-align:center;
                border-radius: 3px;
                margin-left: 5px;
                &:hover{
                    background-color: #F2539D;
                }
                svg{
                    position: relative;
                    top:3px;
                }
            }
        }
        .listUl{
            width: 400px;
            margin:0 auto;
            padding:0;
            li{
                display: flex;
                justify-content: space-between;
                align-items: center;
                list-style: none;
                padding-top: 7px;
                &:last-child{
                    width: 100%;
                    display: block;
                    margin: 0 auto;
                }
                >div{
                    font-size: 14px;
                    font-weight: bold;
                    &:first-child{
                        color: #333;
                        .icon{
                            display: inline-block;
                            margin-right: 7px;
                            font-size: 20px;
                            position: relative;
                            svg{
                                color: #333 !important;
                            }
                        }
                    }
                    &:last-child{
                        color: #54C68F;
                    }
                    .icon{
                        img{
                            width: 18px;
                        }
                    }
                }
                >.publishBtn {
                    display: block;
                    border-radius: 4px;
                    font-size: 14px;
                    line-height: 40px;
                    text-align: center;
                    text-decoration: none;
                    width: 120px;
                    height: 40px;
                    background-color: #ED0973;
                    color: #fff;
                    margin: 48px auto;
                    &:hover{
                        background-color:#F2539D ;
                    }
                }
                >.scanViewerBtn {
                    display: block;
                    border-radius: 4px;
                    font-size: 14px;
                    line-height: 40px;
                    text-align: center;
                    text-decoration: none;
                    width: 120px;
                    height: 40px;
                    background-color: #27282C;
                    color: #fff;
                    margin: 48px auto;
                    &:hover{
                        background-color: #C9C9CA;
                    }
                }
            }
            .lastLi{
                padding-top: 65px;
                >div{
                    &:first-child{
                        font-size: 12px;
                        color: rgba(0,0,0,0.6);
                        position: relative;
                        top:5px;
                    }
                }
                a{
                    color: #FF744E;
                    .underIcon{
                        display: inline-block;
                        font-size: 25px;
                        margin-left: 5px;
                        position: relative;
                        top:2px;
                        font-size:18px
                    }
                }
            }
        }
    }
`;