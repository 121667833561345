import React, { useEffect, useState } from 'react';

// components
import CheckIcon from './CheckIcon';
import CatchError from 'components/CatchError/CatchError';
import Button from 'components/Button/Button';

// react icon
import { AiOutlineClose, AiOutlineLoading, AiOutlineCheck } from 'react-icons/ai';
import { BsArrowRepeat } from 'react-icons/bs';

// styled components
import { StyledFeature } from './StyledFeature';

// i18n
import { useTranslation } from 'react-i18next';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { SET_USER_INFO } from 'redux/types';

// plugin
import axios from 'axios';
import Cookies from 'js-cookie';
import { useBeforeunload } from 'react-beforeunload';

// env
import SERVER from 'constants/server';
import HARDCODE from 'constants/hardcode';

// api
import { refreshToken } from 'api/refreshToken/refreshToken.api';
import { currentInfo } from 'api/currentInfo/currentInfo.api';

const Feature = (props) => {

    const { plan, setShowFeature, setShowStripe, setShowUnsubscribe, setError } = props;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = decodeURIComponent(urlParams.get('token'));

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const store = useSelector(store => store);

    const [loading, setLoading] = useState(null);
    const [cancelBefore, setCancelBefore] = useState(null);
    const [buttonIcon, setButtonIcon] = useState('');
    const [errorStatus, setErrorStatus] = useState(false);

    const getOrderEvent = async () => {

        setLoading(true);

        if (token == 'null') {
            token = store.userInfo.bigPlatformToken;
        }

        try {

            const getOrderStatus = await axios({
                method: 'get',
                url: `${SERVER.PLATFORM_URL}/api/v1/payment/order?productId=${SERVER.PRODUCT_ID}`,
                headers: { 'Authorization': `Bearer ${token}` },
            });
            const response = getOrderStatus.data.content;
            const cancelCount = response.filter(element => element.canceledAt !== null);
            if (cancelCount.length > 0) {
                setCancelBefore(true); // cancelBefore 為 true 代表曾經取消過 => 恢復訂閱
            } else {
                setCancelBefore(false); // cancelBefore 為 false 代表沒有取消過 => 取消續訂
            }

            setLoading(false);

        } catch (error) {
            // console.log(error);
            setLoading(false);
            setErrorStatus(true);
        }

    };

    useEffect(() => {
        getOrderEvent();
    }, []);

    const contactEvent = () => {
        window.open(`${SERVER.AR_MAKER}/contact_us?lang=${Cookies.get('lang')}`, '_blank');
    };

    const closeEvent = () => {
        if (buttonIcon === 'loading') return;

        if (urlParams.get('upgrade')) {
            window.location.replace(`${SERVER.ARPLATFORM}/cardList`);
        } else {
            setShowFeature(false);
        }
        
    };

    const showStripeUpgrade = () => {
        if (plan === 'free') {
            setShowStripe(true);
            setShowFeature(false);
        }
    };

    const showUnsubscribePopup = (e) => {
        e.preventDefault();
        setShowUnsubscribe(true);
        setShowFeature(false);
    };

    const resumeEvent = async (e) => {
        e.preventDefault();
        setButtonIcon('loading');

        if (token == 'null') {
            token = store.userInfo.bigPlatformToken;
        }

        try {

            if (store.permits.plan === 'basic') {

                // 舊版回復訂閱 ARMAKER 基本版方案
                // 依序恢復訂閱兩個次方案，再恢復訂閱主方案

                const viewUsageEvent = await axios({
                    method: 'put',
                    url: `${SERVER.PLATFORM_URL}/api/v1/payment/resume/${HARDCODE.VIEW_USAGE_ID}`,
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                const limitUsageEvent = await axios({
                    method: 'put',
                    url: `${SERVER.PLATFORM_URL}/api/v1/payment/resume/${HARDCODE.LIMIT_USAGE_ID}`,
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                const basicPackageEvent = await axios({
                    method: 'put',
                    url: `${SERVER.PLATFORM_URL}/api/v1/payment/resume/${HARDCODE.BASIC_PACKAGE_ID}`,
                    headers: { 'Authorization': `Bearer ${token}` },
                });

            } else if (store.permits.plan === 'armaker_basic_20220808') {

                // 新版 20220808 之後的基本版
                // 依序恢復訂閱一個次方案，再恢復訂閱主方案

                const viewUsageEvent = await axios({
                    method: 'put',
                    url: `${SERVER.PLATFORM_URL}/api/v1/payment/resume/${HARDCODE.VIEW_USAGE_ID}`,
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                const basicPackageEvent = await axios({
                    method: 'put',
                    url: `${SERVER.PLATFORM_URL}/api/v1/payment/resume/${HARDCODE.NEW_BASIC_PACKAGE_ID}`,
                    headers: { 'Authorization': `Bearer ${token}` },
                });
            }

            // 方案變更後刷新 token
            const refreshTokenEvent = await refreshToken({ token: Cookies.get('token'), username: store.userInfo.username });
            Cookies.set('token', refreshTokenEvent.token);

            // 並更新到 redux (因為這裡沒有 reload)
            const userInfo = await currentInfo();
            dispatch({ type: SET_USER_INFO, payload: userInfo });

            setButtonIcon('success');
            // window.open(window.location.origin, '_self');

            setTimeout(() => {
                setShowFeature(false);
            }, 2000);

        } catch (error) {
            // console.log(error);
            setShowFeature(false);
            setError('resume');
        }

    };

    useBeforeunload((event) => {
        if (buttonIcon === 'loading') {
            event.preventDefault();
            let confirmationMessage = true;
            (event || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Webkit, Safari, Chrome
        }
    });

    return (
        <StyledFeature>
            <div className='container'>
                <div className='title'>
                    {t('content.varietySolution')}
                    <span className='closeIcon' onClick={closeEvent}><AiOutlineClose /></span>
                </div>
                <div className='bg'>
                    {loading && <AiOutlineLoading className='loadingIcon' />}
                    {
                        !loading &&
                        <>
                            {
                                errorStatus ?
                                    <div className='errorStatus'>
                                        <CatchError title={t('error.loading')} description={t('error.checkInternet')} button={t('error.tryAgainButton')} />
                                    </div> :
                                    <div>
                                        {/* Cards */}
                                        <div className='content-card-container'>
                                            {/* 體驗版 */}
                                            <div className='content-card'>
                                                <div>
                                                    <div className='content-card-title'>
                                                        {t('common.freeTrial')}
                                                        {plan === 'basic' && cancelBefore && <div className='nextPlan'>{t('feature.nextPlan')}</div>}
                                                    </div>
                                                    <div className='content-card-subtitle'>{t('content.startFreeTrial')}</div>
                                                    <div className='content-card-price'><span>0</span> {t('content.usdPerMonth')}</div>
                                                </div>
                                                <div className='content-card-button-container'>
                                                    {
                                                        plan === 'free' &&
                                                        <Button type='secondary' text={t('common.currentPlanButton')} disabled={true} />
                                                    }
                                                    {
                                                        plan === 'basic' &&
                                                        <>
                                                            {
                                                                cancelBefore ?
                                                                    <Button type='secondary' text={t('feature.nextPlan')} disabled={true} /> :
                                                                    <Button type='secondary' text={t('common.unsubscriptionButton')} clickEvent={showUnsubscribePopup} />
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                <div className='content-card-description'>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.availableObjects')}: <span className='key-point'>2{t('feature.unitPerMonth')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.viewsPerObject')}: <span className='key-point'>2000{t('feature.timesPerMonth')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.supportObjectType')}: <span className='key-point'>{t('feature.3dAndScankit')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.objectfileSizeLimit')}: <span className='key-point'>100MB</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.additionalStyles')}: <span className='key-point'>9{t('feature.unitPer3dObject')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.numberOfTags')}: <span className='key-point'>10{t('feature.unitPer3dObject')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} /> {t('feature.webArProjection')}
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} /> {t('feature.dataAnalysisReport')}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* 基本版 */}
                                            <div className='content-card'>
                                                <div>
                                                    <div className='content-card-title'>{t('common.basic')}</div>
                                                    <div className='content-card-subtitle'>{t('content.subscription')}</div>
                                                    <div className='content-card-price'><span>10</span> {t('content.usdPerMonth')}</div>
                                                </div>
                                                <div className='content-card-button-container'>
                                                    {
                                                        plan === 'free' &&
                                                        <Button type='primary' text={t('common.subscribeNow')} clickEvent={showStripeUpgrade} />
                                                    }
                                                    {
                                                        plan === 'basic' &&
                                                        <>
                                                            {
                                                                cancelBefore ?
                                                                    <Button
                                                                        type='primary'
                                                                        icon={buttonIcon === 'loading' ? <BsArrowRepeat className='arrowIcon' /> : buttonIcon === 'success' ? <AiOutlineCheck /> : ''}
                                                                        text={t('feature.resumeButton')}
                                                                        clickEvent={resumeEvent}
                                                                    /> :
                                                                    <Button type='primary' text={t('common.currentPlanButton')} disabled={true} />
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                <div className='content-card-description'>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.availableObjects')}: <span className='key-point'>10{t('feature.unitPerMonth')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.viewsPerObject')}: <span className='key-point'>2000{t('feature.timesPerMonth')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.supportObjectType')}: <span className='key-point'>{t('feature.3dAndScankit')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.objectfileSizeLimit')}: <span className='key-point'>100MB</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.additionalStyles')}: <span className='key-point'>9{t('feature.unitPer3dObject')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.numberOfTags')}: <span className='key-point'>10{t('feature.unitPer3dObject')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} /> {t('feature.webArProjection')}
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} /> {t('feature.dataAnalysisReport')}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* 企業版 */}
                                            <div className='content-card'>
                                                <div>
                                                    <div className='content-card-title'>{t('common.enterprise')}</div>
                                                    <div className='content-card-subtitle'>{t('content.enterprise')}</div>
                                                    <div className='content-card-price'><span style={{ fontSize: 26 }}>{t('content.customizedPricing')}</span></div>
                                                </div>
                                                <div className='content-card-button-container'>
                                                    <Button type='dark' text={t('common.ContactNow')} clickEvent={contactEvent} />
                                                </div>
                                                <div className='content-card-description'>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.availableObjects')}: <span className='key-point'>{t('feature.customPerMonth')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.viewsPerObject')}: <span className='key-point'>{t('feature.customPerMonth')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.supportObjectType')}: <span className='key-point'>{t('feature.3dAndScankit')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.objectfileSizeLimit')}: <span className='key-point'>{t('feature.customPerMonth')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.additionalStyles')}: <span className='key-point'>{t('feature.customPer3dObject')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} />
                                                        <div>
                                                            {t('feature.numberOfTags')}: <span className='key-point'>{t('feature.customPer3dObject')}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} /> {t('feature.webArProjection')}
                                                    </div>
                                                    <div>
                                                        <CheckIcon check={true} /> {t('feature.dataAnalysisReport')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </>
                    }
                </div>
            </div>
        </StyledFeature>
    );
};

export default Feature;