import styled from 'styled-components';

export const StyledMoneyStatus = styled.div`
    width: 100vw;
    height: 100vh;
    background: #F2F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: fixed;
    z-index: 999999;
    left: 0;
    top: 0;
    .other-pages-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: #333333;
        margin-top: 32px;
        @media screen and (max-width:414px){
            font-size: 16px;
        }
    }

    .other-pages-subtitle {
        width: 333px;
        font-weight: 500;
        font-size: 15px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: #666666;
        margin-top: 24px;
    }

    .retry-button {
        padding: 12px 24px;
        background: #ED0973;
        border: none;
        border-radius: 4px;
        color: white;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-top: 64px;
    }

    .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loading-circle {
        width: 72px;
        height: 72px;
        border: 3px solid #54C68F;
        border-radius: 50%;
        border-color: #54C68F transparent #54C68F #54C68F;
        animation: rotateMove 1s infinite;
    }

    @keyframes rotateMove {
        0% {
            transform: rotateZ(0deg);
        }

        100% {
            transform: rotateZ(360deg);
        }
    }

    .loading-icon {
        width: 34px;
        height: 34px;
        position: absolute;
    }
    .iconSize {
        width: 72px;
        height: 72px;
    }
`;