import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import APIKEY from 'constants/apiKey';


//react icon
import { AiFillExclamationCircle, AiOutlineCheck } from 'react-icons/ai';
import { FaGlobe, FaUserAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

//image
import bg from './images/bg.svg';
import logo from './images/logo.svg';

//styled
import { StyledForget } from './styledForget';

//plugin
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import Cookies from 'js-cookie';

//i18n
import { useTranslation } from 'react-i18next';

//env
import SERVER from 'constants/server';

const Forget = () => {

    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();


    const [userName, setUserName] = useState('');

    const [inputErrors, setInputErrors] = useState({
        accountStatus: false,
        emailStatus: false,
        msg: t('wrongPassword'),
    });

    const userNameChange = (e) => {
        setUserName(e.target.value);
        setInputErrors({
            ...inputErrors,
            accountStatus: false,
            emailStatus: false,
        });
    };

    const [email, setEmail] = useState('');

    const emailChange = (e) => {
        setEmail(e.target.value);
        setInputErrors({
            ...inputErrors,
            accountStatus: false,
            emailStatus: false,
        });
    };

    const [captcha, setCaptcha] = useState('');
    let captchaMain;

    function onRecaptchaChange(value) {
        setCaptcha(value);
    }

    const [langList, setLangList] = useState(false);
    const [currentLang, setCurrentLang] = useState(Cookies.get('lang'));

    const openLangListEvent = (e) => {
        e.stopPropagation();
        setLangList(!langList);
    };

    const lanEvent = (lang) => {
        i18n.changeLanguage(lang);
        Cookies.set('lang', lang);
        setInputErrors({ ...inputErrors, msg: t('wrong') });
        setLangList(false);
        setCurrentLang(lang);
        document.querySelector('.accountInput').focus();
    };

    useEffect(() => {
        document.querySelector('.accountInput').focus();
        document.addEventListener('click', (e) => setLangList(false));
        window.history.pushState({}, 0, window.location.origin + window.location.pathname);
    }, []);

    const forgetEvent = () => {
        if (userName === '') {
            setInputErrors({
                ...inputErrors,
                accountStatus: true,
            });
            document.querySelector('.accountInput').focus();

            return;
        }

        if (email === '') {
            setInputErrors({
                ...inputErrors,
                emailStatus: true,
            });
            document.querySelector('.passwordInput').focus();

            return;
        }
        axios({
            method: 'post',
            url: `${SERVER.PLATFORM_URL}/public/v1/account/forgotPwd`,
            data: { username: userName, email: email },
            headers: { 'captcha-response': captcha, 'Content-Type': 'application/json' },
        }).then(res => {
            navigate('/finshPassword');
        }).catch(err => {
            // console.log(err.response.data.msg);
            captchaMain.reset();
            if (err.response.data.msg.indexOf('email') > -1) {
                setInputErrors({
                    ...inputErrors,
                    emailStatus: true,
                });
                document.querySelector('.passwordInput').focus();
                return;
            }

            if (err.response.data.msg.indexOf('No value present') > -1) {
                setInputErrors({
                    ...inputErrors,
                    accountStatus: true,
                });
                document.querySelector('.accountInput').focus();
                return;
            }

        });

    };

    return (
        <StyledForget>
            <div className="whiteBg">
                <a href={`${SERVER.AR_MAKER}/?lang=${currentLang}`}  target="_blank" rel="noopener">
                    <img src={logo} className="logo" alt="ARmaker Logo" />
                </a>
                <div className="inputErrorMsg flexCenter">
                    {
                        (inputErrors.accountStatus || inputErrors.emailStatus) &&
                        <span className="flexCenter"><AiFillExclamationCircle fontSize={18} />{inputErrors.msg}</span>
                    }
                </div>


                <h1>
                    {t('forgot')}
                </h1>

                <form className='formTable' autoComplete="off">

                    <div className="accountLabelPanel">
                        {t('resetPasswordEmail')}
                    </div>
                    <div className="accountInputPanel inputCon">
                        <span><FaUserAlt /></span>
                        <input
                            name="username"
                            className="inputs accountInput"
                            value={userName}
                            onChange={userNameChange}
                            type="text"
                            placeholder={t('enterUsername')}
                        />
                    </div>

                    <div className="passwordInputPanel inputCon">
                        <span><MdEmail /></span>
                        <input
                            name="password"
                            className="inputs passwordInput"
                            value={email}
                            onChange={emailChange}
                            type="email"
                            placeholder={t('enterEmail')}
                        />
                    </div>
                </form>
                <div className='reCAPTCHA'>
                    <ReCAPTCHA
                        sitekey={APIKEY.RECAPTCHA_KEY}
                        onChange={onRecaptchaChange}
                        ref={el => { captchaMain = el; }}
                    />
                </div>
                <button className='button' onClick={forgetEvent}>{t('sendResetEmail')}</button>
                <div className='say'>
                    <Link to={'/'}>{t('backToLogin')}</Link>
                </div>
                <div className='underWord'>{t('needHelp')}<a href="https://www.istaging.com/zh-tw/locations" target='_blank'>{t('callHelp')}</a></div>
                <div className='langBtn' onClick={openLangListEvent}>
                    <FaGlobe className='globa' />
                    {
                        currentLang === 'fr' ? 'Français' : currentLang === 'en' ? 'English' : '繁體中文'
                    }
                </div>
                {
                    langList && <ul className='langList'>
                        <li onClick={() => lanEvent('zh-tw')} className={currentLang === 'zh-tw' ? 'current' : ''}>
                            繁體中文
                            <AiOutlineCheck />
                        </li>
                        <li onClick={() => lanEvent('en')} className={currentLang === 'en' ? 'current' : ''}>
                            English
                            <AiOutlineCheck />
                        </li>
                        <li onClick={() => lanEvent('fr')} className={currentLang === 'fr' ? 'current' : ''}>
                            Français
                            <AiOutlineCheck />
                        </li>
                    </ul>
                }
            </div>
            <img src={bg} className='bg' alt="" />
        </StyledForget>
    );
};

export default Forget;