import styled, { css } from 'styled-components';

export const StyledTopBar = styled.div`
    padding: 0px 40px;
    width: 100%;
    height: 56px;
    background: #FFFFFF;
    ${({ noColor }) => {
        if (!noColor) {
            return css`
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
            `;
        } 
    }}
    line-height: 56px;
    color: #333333;
    font-size: 18px;
    text-align:center;
    position:sticky;
    z-index:10;
    top:0;
    left:0;
    .holder {
        width: 100px;
    }


    .leftBackToPlatform {

        &:hover {
            cursor: pointer;
        }

        svg {
            margin-right: 10px;
        }
    }

    .projectTitle {
        margin: 0 auto;
        max-width: 300px;
        display: flex;
        column-gap: 12px;
        align-items: center;

        .title{
            display: flex;

            .name{
                max-width:200px;
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .rightClose {
        width: 100px;
    }

    .closeIcon{
        font-size: 25px;
        cursor: pointer;
    }
`;