import styled from 'styled-components';

const color = {
    primary: '#27282C',
    secondary: '#F0F0F0',
    alert: '#F5314D',
    primaryHover: '#C9C9CA',
    secondaryHover: '#E0E0E0',
    alertHover: '#F34F66',
    primaryDisabled: '#C9C9CA',
};

const text = {
    primary: '#FFFFFF',
    secondary: '#595959',
    alert: '#FFFFFF',
};

export const StyledScanButton = styled.div`
    display: ${({ block }) => block ? 'block' : 'flex'};

    .button {
        display: flex;
        flex-direction: row;
        column-gap: 8px;
        align-items: center;
        justify-content: center;
        padding: 12px 24px;
        border-radius: 8px;
        cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};

        >svg {
            font-size: 20px;
        }

        >span {
            font-weight: 500;
            font-size: 16px;
        }
    }

    .button-primary {
        background: ${({ disabled }) => disabled ? color.primaryDisabled : color.primary};
        border: 1px solid ${({ disabled }) => disabled ? color.primaryDisabled : color.primary};
        color: ${text.primary};

        &:hover {
            background: ${color.primaryHover};
            border: 1px solid ${color.primaryHover};
        }
    }

    .button-primary-outline {
        background: #FFFFFF;
        border: 1px solid ${color.primary};
        color: ${color.primary};

        &:hover {
            background: ${color.primary};
            color: #FFFFFF;
        }
    }

    .button-secondary {
        background: ${color.secondary};
        border: 1px solid ${color.secondary};
        color: ${text.secondary};

        &:hover {
            background: ${color.secondaryHover};
            border: 1px solid ${color.secondaryHover};
        }
    }

    .button-alert {
        background: ${color.alert};
        border: 1px solid ${color.alert};
        color: ${text.alert};

        &:hover {
            background: ${color.alertHover};
            border: 1px solid ${color.alertHover};
        }
    }
`;