import { useState } from 'react';

const useModal = () => {
    const [modalSetting, setModalSetting] = useState({
        show: false,
        type: '',
        title: '',
        desc: '',
        notice: '',
        handleConfirm: () => { },
    });


    return {
        modalSetting,
        setModalSetting,
    };
};

export default useModal;
