import styled from 'styled-components';

export const StyledCardListContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    height: 100vh;
    margin: 0 auto;
    overflow: hidden;
    /* margin-top:160px ; */

    .cardListContainer {
        width: calc(100% + 14px);
        height: calc(100vh - 160px);
        overflow: auto;
        padding-bottom: 180px;
    }

    .btnContainer {
        margin: 48px 0px;
        width: 100%;
        height: 50px;
    }

    .createObjectBtn {
        padding: 12px 24px;
        background: #ED0973;
        border-radius: 4px;
        color: #fff;
        margin-right: 80px;
        cursor: pointer;
        &:hover{
            background-color: #EA4C89;
        }
        svg {
            margin: 0 10px 0 0;
            font-size: 20px;
        }
    }

    .cardListPanel {
        width: 100%;
        display: grid;
        padding: 0px 80px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 20px;
        margin:0 auto;
        padding-bottom: 30px;
        margin: 0 auto;
        position: relative;

        @media all and (max-width:1600px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media all and (max-width:1270px) {
            grid-template-columns: 1fr 1fr;
        }

        > *{
            min-width: 0;
        }

        .filterLoading {
            width: 100%;
            height: calc(100vh - 300px);
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .lds-ellipsis {
                display: inline-block;
                position: relative;
                width: 80px;
                height: 80px;

                >div {
                    position: absolute;
                    top: 33px;
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    background: linear-gradient(79.78deg, #FF7043 0%, #FFA270 100%);
                    animation-timing-function: cubic-bezier(0, 1, 1, 0);
                }

                >div:nth-child(1) {
                    left: 8px;
                    animation: lds-ellipsis1 0.6s infinite;
                }

                >div:nth-child(2) {
                    left: 8px;
                    animation: lds-ellipsis2 0.6s infinite;
                }

                >div:nth-child(3) {
                    left: 32px;
                    animation: lds-ellipsis2 0.6s infinite;
                }

                >div:nth-child(4) {
                    left: 56px;
                    animation: lds-ellipsis3 0.6s infinite;
                }
            }

            @keyframes lds-ellipsis1 {
                0% {
                    transform: scale(0);
                }
                100% {
                    transform: scale(1);
                }
            }
            @keyframes lds-ellipsis3 {
                0% {
                    transform: scale(1);
                }
                100% {
                    transform: scale(0);
                }
            }
            @keyframes lds-ellipsis2 {
                0% {
                    transform: translate(0, 0);
                }
                100% {
                    transform: translate(24px, 0);
                }
            }
        }
    }

    .card {
        height: 320px;
        border-radius: 8px;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
        /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2); */
        &:not(.archiveHoverStatus) {
            &:hover {
                box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.35);
                margin-top: -1px;
                margin-left: -1px;
                .cardImagePanel{
                    .cardMask{
                        display: block;
                    }
                }
            }
        }
    }

    .cardListHolder{
        height: 320px;
        border-radius: 8px;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
        /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2); */
        &_Img{
            width: 100%;
            height: 240px;
            background: #E0E0E0;
            border-radius: 8px 8px 0 0;

            @media all and (max-width: 1440px) {
                height: 235px;
            }
        }
    }

    .cardImagePanel {
        position: relative;
        width: 100%;
        height: 240px;
        /* background: radial-gradient(circle farthest-side at center, #ebebeb, #d1d1d1); */
        border-radius: 8px 8px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;

        @media all and (max-width: 1440px) {
            height: 235px;
        }

        .cardMask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 240px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
            border-radius: 8px;
            display: none;
        }

        .archiveMask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 240px;
            background: #000000;
            opacity: 64%;
            border-radius: 8px 8px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            .lockIconBg {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.3);
                display: flex;
                align-items: center;
                justify-content: center;
                .lockIcon {
                    font-size: 50px;
                    color: rgba(255, 255, 255, 0.87);
                }
            }
        }

        .cardImage {
            border-radius: 8px 8px 0 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
        }

        .cardSettingBtn {
            position: absolute;
            top: 16px;
            right: 16px;
            width: 24px;
            height: 24px;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            cursor: pointer;
            display: none;
            &:hover{
                background: rgba(0, 0, 0, 0.3);
            }
            >svg {
                position: relative;
                top:3px;
                color: #fff;
            }

            .deleteColor {
                color: #F5314D;

                svg {
                    color: #F5314D !important;
                }
            }

            .cardSettingPanel {
                z-index: ${props => props.theme.zIndex.LayerTen};
                position: absolute;
                top: 0;
                right: -185px;
                width: 178px;
                height: auto;
                background: #FFFFFF;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                border-radius: 4px;

                hr {
                    background-color: rgba(0, 0, 0, 0.12);
                    width: 100%;
                    height: 1px;
                }

                .showSettingPanel {
                    display: flex;
                }

                .settingList {
                    padding: 0px 16px;
                    width: 100%;
                    height: 40px;

                    .settingIcon {
                        margin-right: 10px;

                        svg {
                            font-size: 20px;
                            color: #333333;
                        }
                    }

                    .notAllowed {
                        
                    }

                    .publicText {
                        margin-right: 5px;
                    }

                    .noPackage {
                        color: rgba(0, 0, 0, 0.2);
                        svg {
                            color: rgba(0, 0, 0, 0.2);
                        }

                        &:hover { 
                            cursor: not-allowed;
                        }
                    }

                    .packageText {
                        width: 65px;
                        height: 20px;
                        background: rgba(187, 134, 252, 0.2);
                        border-radius: 40px;
                        font-size: 12px;
                        color: #8858C8;
                    }
                }
            }
        }

        .showSettingBtn {
            display: block;
        }

        .cardPublicStatus {
            position: absolute;
            top: 16px;
            left: 16px;
            padding: 1px 16px;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 24px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;
            color: #FFFFFF;
        }

        .isPublicStatus{
            background: #2BB8AF;
        }
    }

    .cardInfoPanel {
        width: 100%;
        height: 80px;
        text-align: left;
        padding: 16px;
        column-gap: 16px;

        .objectTypeIcon {
            width: 34px;
            height: 34px;
        }

        .cardName {
            display: block;
            width: 85%;

            .nn {
                width: 100%;
                vertical-align: middle;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                white-space: nowrap;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.02em;
                color: #262626;
            }

            .viewDiv {
                position: relative;
                font-size: 12px;
                line-height: 18px;
                display: inline-block;
                margin-top: 8px;
                
                &:hover {
                    cursor: pointer;
                }

                svg {
                    margin-right: 5px;
                    font-size: 14px;
                    color: #999999;
                    position: relative;
                    top: 3px;
                }
            }

            .canHover {
                color: #666666;
                svg{
                    color: #666;
                }
                &:hover{
                    color: #FF744E;
                    svg{
                        color:#FF744E;
                    }
                }
            }

            .freeView {
                color: #CCCCCC;
                svg{
                    color: #CCCCCC;
                }
            }
        }

        .isPusblish {
            .viewDiv {
                svg {
                    color:#666666;
                }
            }
        }

        .cardView {
            position: relative;
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            font-size: 12px;
            color: #8858C8;

            &:hover {
                cursor: pointer;
            }
        }

        .hideTooltip {
            display: none;
        }

        .showToolTip {
            display: flex;
        }

        .toolTipContainer {
            position: absolute;
            top: -35px;
            left: -10px;
            width: 73px;
            height: 32px;
            background: #121212;
            border-radius: 4px;
            color: #fff;
            font-size: 14px;

            .arrowDown {
                position: absolute;
                bottom: -8px;
                left: 15px;
                width: 0; 
                height: 0; 
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 10px solid #121212;
            }
        }
    }
`;

export const StyledSortAndFilter = styled.div`
    .sortAndFilter{
        padding: 56px 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 5;
        .title{
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: #BFBFBF;
        }
        .downMenu{
            display: flex;
            color: #27282C;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            align-items: center;
            cursor: pointer;
            margin-left: 5px;
            .btn{
                margin-left: 12px;
                svg{
                    fill:#262626;
                }
            }
            &:hover{
                color: #47484B;
                .btn{
                    svg{
                        fill:#47484B;
                    }
                }
            }
        }
        .filter,.sort{
            &_openControl{
                display: flex;
                align-items: center;
            }
        }
        .filter{
            &_container{
                position: relative;
            }
            &_choiceMenu{
                width: 400px;
                border-radius: 8px;
                background: #fff;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
                display: flex;
                padding: 10px 8px;
                position: absolute;
                top: 40px;
                left: 50px;
                z-index: 5;
                .menuTitle{
                    color: #8C8C8C;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 17px;
                    text-align: left;
                    padding-left: 16px;
                    margin: 4px 0px;
                }
                .menuItems{
                    .menuItem{
                        display: flex;
                        color: #27282C;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        padding: 6px 16px;
                        cursor: pointer;
                        .check{
                            width: 24px;
                            height: 24px;
                            margin-right: 4px;
                        }
                        &:hover{
                            background: #F6F6F7;
                        }
                    }
                }
                &_category{
                    width: 50%;
                }
                &_state{
                    width: 50%;
                }
            }
        }
        .sort{
            &_container{
                display: flex;
                align-items: center;
                position: relative;
            }
            &_openControl{
                margin-right: 36px;
                .downMenu{
                    color: ${props => props.numberOfElements == 0 ? '#BFBFBF' : '#27282C'};
                    cursor: ${props => props.numberOfElements == 0 ? 'default' : 'pointer'};
                    .btn{
                        svg{
                            fill:${props => props.numberOfElements == 0 ? '#BFBFBF' : '#27282C'};
                        }
                    }
                }
                &_icons{
                    display: flex;
                    gap: 0 15px;
                    opacity: 0;
                    .icon{
                        width: 32px;
                        height: 32px;
                        /* cursor: ${props => props.numberOfElements == 0 ? 'default' : 'pointer'}; */
                        /* cursor: pointer; */
                        svg{
                            fill: #fff;
                            stroke: #9E9EA0;
                        }
                        &:nth-child(2) svg{
                            fill: #9E9EA0;
                            stroke: #fff;
                        }
                    }
                }
            }
            &_choiceMenu{
                min-width: 180px;
                /* height: 279px; */
                border-radius: 8px;
                background: #fff;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
                display: flex;
                flex-direction: column ;
                position: absolute;
                top: 40px;
                left: -25px;
                z-index: 5;
                padding: 10px 0px;
                .menuTitle{
                    color: #8C8C8C;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 17px;
                    text-align: left;
                    padding: 0px 16px;
                }
                .menuItems{
                    margin-top: 10px;
                    width:100%;
                    .menuItem{
                        display: flex;
                        color: #27282C;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        padding: 6px 16px;
                        padding-left: 17px;
                        cursor: pointer;
                        .check{
                            width: 24px;
                            height: 24px;
                            margin-right: 4px;
                        }
                        &:hover{
                            background: #F6F6F7;
                        }
                    }
                }
                &_category{
                    border-bottom: 1px solid #0000001F;
                    margin-bottom: 5px;
                }
            }
        }
        .emptyPic{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,0%);
            width: 280px;
            height: 265px;
            display: ${props => props.numberOfElements == 0 ? 'block' : 'none'};
            color: #8C8C8C;
            font-weight: 500;
            font-size: 18px;
            line-height: 140%;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
`;