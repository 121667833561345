// react
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// redux
import { SET_CARD_LIST, SET_PROJECT_RECORD } from 'redux/types';

// styled components
import { StyledCardListContainer, StyledSortAndFilter } from './StyledCardList';
import { StyledCardListPOP } from './StyledCardListPOP';

// components
import Header from 'components/Header/Header';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import SuccessProject from 'components/SuccessProject/SuccessProject';
import Loading from 'components/Loading/Loading';
import SuccessCard from 'components/SuccessCard/SuccessCard';
import Banner from 'components/Banner/Banner';
import AbnormalPopup from 'components/AbnormalPopup/AbnormalPopup';
import CookieBanner from 'components/CookieBanner/CookieBanner';

// hooks
import useModal from 'hooks/useModal';

// config
import { modalConfig } from 'config/modal';

// react icons
import { AiFillEye } from 'react-icons/ai';
import { BsThreeDots } from 'react-icons/bs';
import { ReactComponent as Copy } from './images/copy.svg';
import { ReactComponent as Edit } from './images/edit.svg';
import { ReactComponent as Eye } from './images/eye.svg';
import { ReactComponent as Link } from './images/link.svg';
import { ReactComponent as Publish } from './images/publish.svg';
import { ReactComponent as Share } from './images/share.svg';
import { ReactComponent as Trash } from './images/trash.svg';
import { ReactComponent as ArObject } from './images/ArObject.svg';
import { ReactComponent as ScanKit } from './images/ScanKit.svg';
import { ReactComponent as Down } from './images/down.svg';
import { ReactComponent as FourSquare } from './images/fourSquare.svg';
import { ReactComponent as List } from './images/List.svg';
import { ReactComponent as Check } from './images/check.svg';
import { ReactComponent as CardEmpty } from './images/cardEmpty.svg';

// plugins
import clsx from 'classnames';
import * as dayjs from 'dayjs';

// api
import { getProjectList } from 'api/getProjectList/getProjectList.api';
import { getFilterProjectList } from 'api/getFilterProjectList/getFilterProjectList.api';
import { deleteProject } from 'api/deleteProject/deleteProject.api';
import { copyProject } from 'api/copyProject/copyProject.api';
import { openProject } from 'api/openProject/openProject.api';
import { closeProject } from 'api/closeProject/closeProject.api';
import { loginGet } from 'api/loginInfo/loginInfo.api';
import { fetchModelStatus } from 'api/scankit/fetchModelStatus';


// plugin
import Cookies from 'js-cookie';

// images
import defaultImg from './images/defaultImg.svg';

// i18n
import { useTranslation } from 'react-i18next';

// constants
import SERVER from 'constants/server';

const ViewToolTip = ({ showTooltip, cardId, hoverViewId }) => {

    const { t } = useTranslation();

    return (
        <div
            className={clsx('toolTipContainer', 'flexCenter', {
                'hideTooltip': !showTooltip || cardId !== hoverViewId,
                'showToolTip': showTooltip && cardId === hoverViewId,
            })}
        >
            {t('viewers')}
            <div className="arrowDown" />
        </div>
    );
};

let preCardList;
let page = 0;
let size = 12;
let mainFilterCategory = 'All', mainFilterStatus = 'All', mainSortCategory = 'lastUpdated', mainSortStatus = 'desc';
const CardPanelBasic = (props) => {

    const { myPlan, objectType, modelStatus, setTotalOfElements } = props;

    const { modalSetting, setModalSetting } = useModal();
    const dispatch = useDispatch();
    const store = useSelector(store => store);

    const [loadingTitle, setLoadingTitle] = useState('');
    const [loadingTxt, setLoadingTxt] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [showAlertCardStatus, setShowAlertCardStatus] = useState(false);
    const [successTitle, setSuccessTitle] = useState('');

    const { publicProject: publicProjectLimit } = useSelector(store => store.permits);
    const { projectPublicCount } = useSelector(store => store.projectRecord);

    const { t } = useTranslation();

    const handleErrorResponse = (modalType) => {
        setModalSetting({
            ...modalSetting,
            show: true,
            title: '',
            type: modalType,
            handleConfirm: () => {
                props.setCanHoverSetting(true);
            },
        });
    };

    const [targetId, setTargetId] = useState({
        projectId: props.projectId,
        modelsId: props.modelsId,
        name: props.name,
        projectIndex: props.projectIndex,
        isPublish: props.isPublish,
    });

    const navigate = useNavigate();

    const editInfoEvent = (e) => {
        e.stopPropagation();

        if (objectType === 'ARMAKER') {
            Cookies.set('projectId', targetId.projectId);
            Cookies.set('modelId', targetId.modelsId);
            Cookies.set('isPublish', targetId.isPublish);
            Cookies.set('projectIndex', targetId.projectIndex);
            navigate('/projectInfo?mode=edit');
        } else {
            Cookies.set('scanProjectId', targetId.projectId);
            navigate('/info?editInfo=true');
        }
    };

    const deleteProjectEvent = (e) => {
        e.stopPropagation();

        if (modelStatus !== 'DONE' && modelStatus !== 'PENDING') return;

        props.setCanHoverSetting(false);
        setModalSetting({
            ...modalSetting,
            show: true,
            title: targetId.name,
            type: 'type30',
            handleConfirm: async () => {
                setLoadingTitle(t('deletingProject'));
                setLoadingTxt(t('pleaseWait'));
                setLoadingStatus(true);
                try {
                    if (targetId.isPublish) {
                        await closeProject(targetId.projectId, Cookies.get('token'));
                    }
                    await deleteProject(targetId.projectId, Cookies.get('token')).then(() => {
                        setLoadingStatus(false);
                        setSuccessTitle(t('deletedSuccessfully'));
                        setShowAlertCardStatus(true);
                    });

                    let res;
                    if (page > 0) {
                        res = await getFilterProjectList((page + 1) * size, 0, mainFilterCategory, mainFilterStatus, mainSortCategory, mainSortStatus);
                    } else {
                        res = await getFilterProjectList(size, 0, mainFilterCategory, mainFilterStatus, mainSortCategory, mainSortStatus);
                    }
                    preCardList = res.cardListDetail;
                    dispatch({ type: SET_CARD_LIST, payload: res });
                    const resProjectInfo = await loginGet(Cookies.get('token'));
                    dispatch({ type: SET_PROJECT_RECORD, payload: resProjectInfo });
                    setTotalOfElements(resProjectInfo.projectCount);
                    setTimeout(() => {
                        setShowAlertCardStatus(false);
                    }, 1000);
                    props.setCanHoverSetting(true);
                } catch (error) {
                    setLoadingStatus(false);
                    handleErrorResponse('type25');
                }
            },
        });
    };

    const copyProjectEvent = (e) => {

        e.stopPropagation();
        props.setCanHoverSetting(false);

        setModalSetting({
            ...modalSetting,
            show: true,
            type: 'type18',
            handleConfirm: async () => {
                setLoadingTitle(t('copyingProject'));
                setLoadingTxt(t('pleaseWait'));
                setLoadingStatus(true);
                try {
                    await copyProject(targetId.projectId, { lang: Cookies.get('lang') }, Cookies.get('token')).then(() => {
                        setLoadingStatus(false);
                        setSuccessTitle(t('copySuccessfully'));
                        setShowAlertCardStatus(true);
                    });
                    let res;
                    if (page > 0) {
                        res = await getFilterProjectList((page + 1) * size, 0, mainFilterCategory, mainFilterStatus, mainSortCategory, mainSortStatus);
                    } else {
                        console.log(222);
                        res = await getFilterProjectList(size, 0, mainFilterCategory, mainFilterStatus, mainSortCategory, mainSortStatus);
                    }
                    preCardList = res.cardListDetail;
                    dispatch({ type: SET_CARD_LIST, payload: res });
                    const resProjectInfo = await loginGet(Cookies.get('token'));
                    dispatch({ type: SET_PROJECT_RECORD, payload: resProjectInfo });
                    setTimeout(() => {
                        setShowAlertCardStatus(false);
                    }, 1000);
                    props.setCanHoverSetting(true);
                } catch (error) {
                    setLoadingStatus(false);
                    setModalSetting({
                        ...modalSetting,
                        show: true,
                        type: 'type38',
                        handleOtherAction: true,
                        handleConfirm: () => {
                            window.location.reload();
                        },
                    });
                }
            },
        });
    };

    const [isPublish, setPublish] = useState(store.cardListInfo.cardListDetail[targetId.projectIndex]?.publicSwitch);
    const [isUid, setUid] = useState(store.cardListInfo.cardListDetail[targetId.projectIndex]?.uuid);
    const [openLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    const publishEvent = (e) => {
        e.stopPropagation();

        if (modelStatus !== 'DONE') return;

        props.setCanHoverSetting(false);

        if (!isPublish) {
            // 物件是未公開狀態，可執行「公開」功能
            setLoadingTitle(t('publishingProject'));
            setLoadingTxt(t('pleaseWait'));
            setLoadingStatus(true);

            // 可公開物件達上限，跳出 popup
            if (projectPublicCount >= publicProjectLimit) {
                setLoadingStatus(false);
                setModalSetting({
                    ...modalSetting,
                    show: true,
                    type: myPlan === 'free' ? 'type19' : 'type40',
                    desc: t('publicFail', { projectCount: projectPublicCount, projectLimit: publicProjectLimit }),
                    handleConfirm: () => {
                        if (myPlan === 'free') {
                            document.querySelector('.uploadBtn').click();
                        } else {
                            window.open(`${SERVER.AR_MAKER}/contact_us?lang=${Cookies.get('lang')}`, '_blank');
                        }
                    },
                });
            } else {
                // 公開物件 api (分為 ARMAKER 及 SCANKIT)
                openProject(targetId.projectId, Cookies.get('token')).then(res => {
                    loginGet(Cookies.get('token')).then((resProjectInfo) => {
                        dispatch({ type: SET_PROJECT_RECORD, payload: resProjectInfo });
                    });
                }).then(() => {
                    getFilterProjectList((page + 1) * size, 0, mainFilterCategory, mainFilterStatus, mainSortCategory, mainSortStatus).then((res) => {
                        dispatch({ type: SET_CARD_LIST, payload: res });
                        preCardList = res.cardListDetail;
                    });
                    setLoadingStatus(false);
                    setSuccess(true);
                    setPublish(!isPublish);
                }).catch(() => {
                    setLoadingStatus(false);
                    handleErrorResponse('type39');
                    setLoading(false);
                });
            }
        } else {
            // 物件是公開狀態，可執行「取消公開」功能
            setModalSetting({
                ...modalSetting,
                show: true,
                type: 'type34',
                handleConfirm: () => {
                    setLoadingTitle(t('unpublishingProject'));
                    setLoadingTxt(t('pleaseWait'));
                    setLoadingStatus(true);
                    // 取消公開物件 api (分為 ARMAKER 及 SCANKIT)
                    closeProject(targetId.projectId, Cookies.get('token')).then(res => {
                        loginGet(Cookies.get('token')).then((resProjectInfo) => {
                            dispatch({ type: SET_PROJECT_RECORD, payload: resProjectInfo });
                        });
                    }).then(() => {
                        getFilterProjectList((page + 1) * size, 0, mainFilterCategory, mainFilterStatus, mainSortCategory, mainSortStatus).then((res) => {
                            dispatch({ type: SET_CARD_LIST, payload: res });
                            preCardList = res.cardListDetail;
                        });
                        setLoadingStatus(false);
                        setSuccessTitle(t('unlistedSuccessfully'));
                        setShowAlertCardStatus(true);
                        setTimeout(() => {
                            setShowAlertCardStatus(false);
                        }, 1000);
                        setPublish(!isPublish);
                        props.setCanHoverSetting(true);
                    }).catch((err) => {
                        setLoadingStatus(false);
                        handleErrorResponse('type28');
                    });
                },
            });

        }
    };

    const previewEvent = (e) => {
        e.stopPropagation();

        if (targetId.modelsId === undefined) {
            targetId.modelsId = store.cardListInfo.cardListDetail.filter((item) => item.id === targetId.projectId)[0].modelId;
        }

        Cookies.set('projectId', targetId.projectId);
        Cookies.set('modelId', targetId.modelsId);
        Cookies.set('isPublish', targetId.isPublish);

        const token = Cookies.get('token');
        // encodeURIComponent
        const encodeProjectId = encodeURIComponent(targetId.projectId);
        const encodeModelsId = encodeURIComponent(targetId.modelsId);
        const encodeToken = encodeURIComponent(token);
        const encodeisPublish = encodeURIComponent(targetId.isPublish);
        const lang = Cookies.get('lang');

        window.open(`${SERVER.EDITOR_URL}/preview?projectId=${encodeProjectId}&modelsId=${encodeModelsId}&token=${encodeToken}&isPublish=${isPublish}&preview=true&lang=${lang}`, '_self');
    };

    const goPublishEvent = (e) => {
        e.stopPropagation();

        Cookies.set('projectId', targetId.projectId);
        Cookies.set('modelId', targetId.modelsId);

        const encodeUid = encodeURIComponent(isUid);

        if (objectType === 'ARMAKER') {
            window.open(`${SERVER.VIEWER_URL}/?uid=${encodeUid}`, '_blank', 'noopener, noreferrer');
        } else {
            window.open(`${SERVER.ARPLATFORM}/viewer?uid=${encodeUid}`, '_blank', 'noopener, noreferrer');
        }
    };

    const childPublishEvent = (value) => {
        setSuccess(value);
    };

    const shareUrl = (e) => {
        e.stopPropagation();

        props.setCanHoverSetting(false);
        Cookies.set('projectId', targetId.projectId);
        setModalSetting({
            ...modalSetting,
            show: true,
            type: 'type33',
        });
    };

    return (
        <StyledCardListPOP>
            {showAlertCardStatus && <SuccessCard title={successTitle} mask='true' />}
            {loadingStatus && <Loading mask='true' title={loadingTitle} txt={loadingTxt} />}
            {isSuccess && (
                <SuccessProject
                    setCanHoverSetting={props.setCanHoverSetting}
                    uuid={store.cardListInfo.cardListDetail[targetId.projectIndex]?.uuid}
                    onCallParent={childPublishEvent}
                    scanViewerUrl={objectType === 'SCANKIT'}
                />
            )}
            {modalSetting.show && (
                <ModalPopup
                    modalConfig={modalConfig[modalSetting.type]}
                    setModalSetting={setModalSetting}
                    modalSetting={modalSetting}
                    setCanHoverSetting={props.setCanHoverSetting}
                    scanViewerUrl={objectType === 'SCANKIT'}
                />
            )}

            <ul>
                <li onClick={publishEvent} className={modelStatus !== 'DONE' ? 'menuDisabled' : undefined}>
                    <Publish />
                    {isPublish ? t('unpublic') : t('public')}
                    {openLoading && (
                        <div className='loadingMask'>
                            <Loading title={t('publicingModelpPrompt')} txt={t('pleaseWait')} />
                        </div>
                    )}
                </li>
                {
                    objectType === 'ARMAKER' &&
                    <>
                        <li onClick={previewEvent}>
                            <Eye />
                            {t('preview')}
                        </li>
                    </>
                }
                {
                    isPublish &&
                    <>
                        <li onClick={goPublishEvent}>
                            <Link />
                            {t('checkviewer')}
                        </li>
                        <li onClick={shareUrl}>
                            <Share />
                            {t('shareLink')}
                        </li>
                    </>
                }
                {
                    objectType === 'ARMAKER' &&
                    <>
                        <li onClick={copyProjectEvent}>
                            <Copy />
                            {t('duplicateProject')}
                        </li>
                    </>
                }
                <li onClick={editInfoEvent}>
                    <Edit />
                    {t('editProjectinfo')}
                </li>
                <li onClick={deleteProjectEvent} className={(modelStatus !== 'DONE' && modelStatus !== 'PENDING') ? 'menuDisabled' : undefined}>
                    <Trash />
                    {t('deleteProject')}
                </li>
            </ul>
        </StyledCardListPOP>
    );
};
// 過濾機制
const SortAndFilter = ({ numberOfElements, setNumberOfElements, totalOfElements, setTotalOfElements, setFilterLoading }) => {
    const { t } = useTranslation();
    const { modalSetting, setModalSetting } = useModal();
    const dispatch = useDispatch();
    // 類別(All、ARMAKER、SCANKIT)  狀態(All、true、false)
    const [filterMenuCheck, setFilterMenuCheck] = useState({ category: 'All', status: 'All' });
    const [sortMenuCheck, setSortMenuCheck] = useState({ category: 'lastUpdated', status: 'desc' });
    // 過濾機制
    const [openFilter, setOpenFilter] = useState(false);
    const [openSort, setOpenSort] = useState(false);

    const handleClickOpenFilter = () => {
        if (openSort) {
            setOpenSort(false);
        }
        setOpenFilter(!openFilter);
    };

    useEffect(() => {
        let closeMenu = (e) => {
            if (e.target.parentNode.className === 'menuItem' || e.target.parentNode.className === 'menuItems') {
                return;
            }
            setOpenFilter(false);
            setOpenSort(false);
        };
        if (openFilter === true || openSort === true) {
            document.addEventListener('click', closeMenu);
        }
        return (() => {
            document.removeEventListener('click', closeMenu);
        });
    }, [openFilter, openSort]);

    const handleClickOpenSort = () => {
        if (numberOfElements === 0) {
            return;
        }
        if (openFilter) {
            setOpenFilter(false);
        }
        setOpenSort(!openSort);
    };

    const handleGetFilterProjectList = () => {
        setFilterLoading(true);
        getFilterProjectList((page + 1) * size, 0, mainFilterCategory, mainFilterStatus, mainSortCategory, mainSortStatus)
            .then(async (res) => {
                preCardList = res.cardListDetail;
                setNumberOfElements(res.numberOfElements);
                setTotalOfElements(res.totalElements);
                dispatch({ type: SET_CARD_LIST, payload: res });
                const resProjectInfo = await loginGet(Cookies.get('token'));
                dispatch({ type: SET_PROJECT_RECORD, payload: resProjectInfo });
                setFilterLoading(false);
            }).catch((error) => {
                setModalSetting({
                    ...modalSetting,
                    show: true,
                    title: '',
                    type: 'type25',
                    handleConfirm: () => {
                    },
                });
            });
    };

    const handleChangeFilterCondition = (condition) => {
        let passCondeition = { ...filterMenuCheck, ...condition };
        setFilterMenuCheck((preState) => {
            return { ...preState, ...condition };
        });
        mainFilterCategory = passCondeition.category;
        mainFilterStatus = passCondeition.status;
        handleGetFilterProjectList();
    };

    const handleChangeSortCondition = (condition) => {
        let passCondeition = { ...sortMenuCheck, ...condition };
        setSortMenuCheck((preState) => {
            return { ...preState, ...condition };
        });
        mainSortCategory = passCondeition.category;
        mainSortStatus = passCondeition.status;
        handleGetFilterProjectList();
    };

    useEffect(() => {
        if (openFilter) {
            setTimeout(function () {
                handleClickOpenFilter();
            }, 800);
        }
        if (openSort) {
            setTimeout(function () {
                handleClickOpenSort();
            }, 800);
        }
    }, [filterMenuCheck, sortMenuCheck]);

    return (
        <StyledSortAndFilter numberOfElements={numberOfElements}>
            <div className="sortAndFilter">
                <div className="filter_container">
                    <div className="filter_openControl" onClick={handleClickOpenFilter}>
                        <div className="title">{t('sortAndFilter.filter')} ：</div>
                        <div className="downMenu">
                            <span>
                                {
                                    filterMenuCheck.category === 'All' ? t('sortAndFilter.allObjects') :
                                        filterMenuCheck.category === 'ARMAKER' ? t('sortAndFilter.ARObject') :
                                            t('sortAndFilter.ScankitObject')
                                } ({totalOfElements ? totalOfElements : '正在載入...'})
                            </span>
                            <div className="btn">
                                <Down />
                            </div>
                        </div>
                    </div>
                    {openFilter &&
                        <div className="filter_choiceMenu">
                            <div className="filter_choiceMenu_category">
                                <div className="menuTitle">{t('sortAndFilter.category')}</div>
                                <div className="menuItems">
                                    <div className="menuItem" onClick={() => handleChangeFilterCondition({ category: 'All' })}>
                                        <div className="check">
                                            {
                                                filterMenuCheck.category === 'All' &&
                                                <Check />
                                            }
                                        </div>
                                        <span>{t('sortAndFilter.allObjects')}</span>
                                    </div>
                                    <div className="menuItem" onClick={() => handleChangeFilterCondition({ category: 'ARMAKER' })}>
                                        <div className="check">
                                            {
                                                filterMenuCheck.category === 'ARMAKER' &&
                                                <Check />
                                            }
                                        </div>
                                        <span>{t('sortAndFilter.ARObject')}</span>
                                    </div>
                                    <div className="menuItem" onClick={() => handleChangeFilterCondition({ category: 'SCANKIT' })}>
                                        <div className="check">
                                            {
                                                filterMenuCheck.category === 'SCANKIT' &&
                                                <Check />
                                            }
                                        </div>
                                        <span>{t('sortAndFilter.ScankitObject')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="filter_choiceMenu_state">
                                <div className="menuTitle">{t('sortAndFilter.state')}</div>
                                <div className="menuItems">
                                    <div className="menuItem" onClick={() => handleChangeFilterCondition({ status: 'All' })}>
                                        <div className="check">
                                            {
                                                filterMenuCheck.status == 'All' &&
                                                <Check />
                                            }
                                        </div>
                                        <span>{t('sortAndFilter.allStates')}</span>
                                    </div>
                                    <div className="menuItem" onClick={() => handleChangeFilterCondition({ status: true })}>
                                        <div className="check">
                                            {
                                                filterMenuCheck.status === true &&
                                                <Check />
                                            }
                                        </div>
                                        <span>{t('sortAndFilter.public')}</span>
                                    </div>
                                    <div className="menuItem" onClick={() => handleChangeFilterCondition({ status: false })}>
                                        <div className="check">
                                            {
                                                filterMenuCheck.status === false &&
                                                <Check />
                                            }
                                        </div>
                                        <span>{t('sortAndFilter.draft')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="sort_container">
                    <div className="sort_openControl" onClick={handleClickOpenSort}>
                        <div className="title">{t('sortAndFilter.sort')}：</div>
                        <div className="downMenu">
                            <span>
                                {
                                    sortMenuCheck.category === 'lastUpdated' ?
                                        t('sortAndFilter.editTime') : sortMenuCheck.category === 'dateCreated' ?
                                            t('sortAndFilter.creationTime') : sortMenuCheck.category === 'lastPublicDate' ?
                                                t('sortAndFilter.publicTime') : t('sortAndFilter.views')
                                }
                            </span>
                            <div className="btn">
                                <Down />
                            </div>
                        </div>
                    </div>
                    <div className="sort_openControl_icons">
                        <div className="icon">
                            <FourSquare />
                        </div>
                        <div className="icon">
                            <List />
                        </div>
                    </div>
                    {openSort &&
                        <div className="sort_choiceMenu">
                            <div className="sort_choiceMenu_category">
                                <div className="menuTitle">{t('sortAndFilter.category')}</div>
                                <div className="menuItems">
                                    <div className="menuItem" onClick={() => handleChangeSortCondition({ category: 'lastUpdated' })}>
                                        <div className="check">
                                            {
                                                sortMenuCheck.category === 'lastUpdated' &&
                                                <Check />
                                            }
                                        </div>
                                        <span>{t('sortAndFilter.editTime')}</span>
                                    </div>
                                    <div className="menuItem" onClick={() => handleChangeSortCondition({ category: 'dateCreated' })}>
                                        <div className="check">
                                            {
                                                sortMenuCheck.category === 'dateCreated' &&
                                                <Check />
                                            }
                                        </div>
                                        <span>{t('sortAndFilter.creationTime')}</span>
                                    </div>
                                    <div className="menuItem" onClick={() => handleChangeSortCondition({ category: 'lastPublicDate' })}>
                                        <div className="check">
                                            {
                                                sortMenuCheck.category === 'lastPublicDate' &&
                                                <Check />
                                            }
                                        </div>
                                        <span>{t('sortAndFilter.publicTime')}</span>
                                    </div>
                                    <div className="menuItem" onClick={() => handleChangeSortCondition({ category: 'visitCount' })}>
                                        <div className="check">
                                            {
                                                sortMenuCheck.category === 'visitCount' &&
                                                <Check />
                                            }
                                        </div>
                                        <span>{t('sortAndFilter.views')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="sort_choiceMenu_state">
                                <div className="menuTitle">{t('sortAndFilter.order')}</div>
                                <div className="menuItems">
                                    <div className="menuItem" onClick={() => handleChangeSortCondition({ status: 'desc' })}>
                                        <div className="check">
                                            {
                                                sortMenuCheck.status === 'desc' &&
                                                <Check />
                                            }
                                        </div>
                                        <span>{sortMenuCheck.category === 'visitCount' ? t('sortAndFilter.most') : t('sortAndFilter.latest')}</span>
                                    </div>
                                    <div className="menuItem" onClick={() => handleChangeSortCondition({ status: 'asc' })}>
                                        <div className="check">
                                            {
                                                sortMenuCheck.status === 'asc' &&
                                                <Check />
                                            }
                                        </div>
                                        <span>{sortMenuCheck.category === 'visitCount' ? t('sortAndFilter.least') : t('sortAndFilter.oldest')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="emptyPic">
                    <CardEmpty />
                    <span>{t('sortAndFilter.relatedObjectNotFound')}</span>
                </div>
            </div>
        </StyledSortAndFilter>
    );
};

// 卡片列表
const CardList = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { modalSetting, setModalSetting } = useModal();
    const store = useSelector(store => store);
    const { projectCount } = useSelector(store => store.projectRecord);
    const { noPermission } = useSelector(store => store.noPermission);

    const [cardSettingId, setCardSetting] = useState(9999999999999);
    const [showCtrl, setShowCtrl] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const [hoverViewId, setHoverViewId] = useState(null);
    const [canHoverSetting, setCanHoverSetting] = useState(true);
    const [showCardListholder, setShowCardListholder] = useState(false);
    const [cardList, setCardList] = useState(null);
    const [banner, setBanner] = useState(true);

    const [numberOfElements, setNumberOfElements] = useState(null);
    const [totalOfElements, setTotalOfElements] = useState(null);

    const [abnormalPopup, setAbnormalPopup] = useState(false);

    const showSettingBtn = (id) => () => {
        setCardSetting(id);
    };

    const hideSettingBtn = () => {
        if (canHoverSetting) {
            setCardSetting(9999999999999);
            setShowCtrl(9999999999);
        }
    };

    // 卡片排序＆刪除多餘卡片
    useEffect(async () => {
        let getCardList = store.cardListInfo.cardListDetail;

        let projectIdAry = [];

        for (let i = 0; i < getCardList.length; i++) {
            if (getCardList[i].type === 'ARMAKER' && getCardList[i].lastUploadFileType === null) {
                projectIdAry.push(getCardList[i].id);
                getCardList.splice(i, 1);
            } else if (getCardList[i].type === 'SCANKIT' && getCardList[i].modelThumb === null) {
                projectIdAry.push(getCardList[i].id);
                getCardList.splice(i, 1);
                break;
            }
        }
        if (projectIdAry.length > 0) {
            for (let i = 0; i < projectIdAry.length; i++) {
                await deleteProject(projectIdAry[i], Cookies.get('token'));
            }
            projectIdAry.length = 0;
        }
        setCardList(getCardList);
    }, [store.cardListInfo.cardListDetail]);

    // 初始進入網頁，裝置尺寸及href判斷
    useEffect(() => {

        if (window.innerWidth < 1080) {
            setModalSetting({
                ...modalSetting,
                show: true,
                type: 'type36',
            });
        }

        const checkHref = (query) => {
            if (window.location.href.indexOf(query) > -1) {
                if (query === '?upgrade') {
                    document.querySelector('.uploadBtn').click();
                } else {
                    window.open(window.location.href.replace(query, ''), '_self');
                }
            }
        };

        checkHref('?reload');
        checkHref('?fromUpload');
        checkHref('?fromClear');
        checkHref('?upgrade');

    }, []);

    const [myPlan, setMyPlan] = useState('');

    useEffect(() => {

        // 舊版方案：free / basic / enterprise / armaker_enterprise_*企業名稱*
        // 新版方案：armaker_free_20220808 / armaker_basic_20220808 / <描述1>_Enterprise_<描述2>
        const plan = store.permits.plan;

        // 判斷方案（體驗版/基本版/企業版）
        if (plan.indexOf('free') > -1) {
            setMyPlan('free');
        } else if (plan.indexOf('basic') > -1) {
            setMyPlan('basic');
        } else if (plan.indexOf('enterprise') > -1 || plan.indexOf('Enterprise') > -1) {
            setMyPlan('enterprise');
        }

    }, [store.permits]);

    // 卡片資料載入&滾動載入事件
    useEffect(() => {
        if (noPermission === 'package expired fail') {
            setShowCardListholder(true);
            setAbnormalPopup(true);
            Cookies.remove('token');
            Cookies.remove('xrToken');
            return;
        }
        let getMoreList = true;
        setShowCardListholder(true);
        getFilterProjectList((page + 1) * 12, 0, mainFilterCategory, mainFilterStatus, mainSortCategory, mainSortStatus).then((res) => {
            // console.log(res);
            if (res.cardListDetail.length < size) {
                getMoreList = false;
            }
            // 專案數為零，則前往 create 頁面
            if (res.cardListDetail.length === 0) {
                navigate('/create');
            }
            preCardList = res.cardListDetail;
            setNumberOfElements(res.numberOfElements);
            setTotalOfElements(res.totalElements);
            dispatch({ type: SET_CARD_LIST, payload: res });
            dispatch({ type: SET_PROJECT_RECORD, payload: { projectCount: res.totalElements } });
            setShowCardListholder(false);
        }).catch((err) => {
            setModalSetting({
                ...modalSetting,
                show: true,
                type: 'type37',
                handleOtherAction: true,
                mode: 'cardList',
                otherBtnText: t('PleaseTryAgain'),
                coverSetting: true,
                handleConfirm: () => {
                    window.location.reload();
                },
            });
        });

        let newCardList = {
            cardListDetail: [],
        };
        let startScroll = true;
        const cardListContainer = document.getElementById('cardListContainer');
        const handleScroll = () => {
            // console.log('scroll事件');
            // 頁面裝置總長 - Header長度
            let cHeight = document.documentElement.clientHeight - 160;
            // 滾動區域總長
            let sHeight = cardListContainer.scrollHeight;
            // 滾動長度
            let sTop = cardListContainer.scrollTop;
            if (sHeight === Math.round(cHeight + sTop)) {
                // console.log('開始讀取新資料');
                if (startScroll) {
                    if (getMoreList) {
                        startScroll = false;
                        setShowCardListholder(true);
                        // document.documentElement.scrollTo({top: sTop - 200 });
                        page++;
                        getFilterProjectList(size, page, mainFilterCategory, mainFilterStatus, mainSortCategory, mainSortStatus).then((res) => {
                            if (res.cardListDetail.length < size) {
                                getMoreList = false;
                            }
                            // 取得前一次資料，並將新資料堆疊上去
                            newCardList.cardListDetail = [...preCardList, ...res.cardListDetail];
                            preCardList = newCardList.cardListDetail;
                            setNumberOfElements((preState) => preState + res.numberOfElements);
                            dispatch({ type: SET_CARD_LIST, payload: newCardList });
                            setShowCardListholder(false);
                            startScroll = true;
                        }).catch((err) => {
                            setModalSetting({
                                ...modalSetting,
                                show: true,
                                title: '',
                                coverSetting: true,
                                otherBtnText: t('PleaseTryAgain'),
                                noClose: true,
                                handleOtherAction: true,
                                type: 'type37',
                                handleConfirm: () => {
                                    window.location.reload();
                                },
                            });
                        });
                    }
                }
            }
        };
        cardListContainer.addEventListener('scroll', handleScroll);
        return (() => {
            cardListContainer.removeEventListener('scroll', handleScroll);
        });
    }, []);

    const toEditorEvent = (projectId, modelsId, isPublish, objectType) => () => {

        if (objectType === 'ARMAKER') {
            if (modelsId === undefined) {
                modelsId = store.cardListInfo.cardListDetail.filter((item) => item.id === projectId)[0].modelId;
            }
            Cookies.set('projectId', projectId);
            Cookies.set('modelId', modelsId);
            Cookies.set('isPublish', isPublish);
            const token = Cookies.get('token');

            // encodeURIComponent
            const encodeProjectId = encodeURIComponent(projectId);
            const encodeModelsId = encodeURIComponent(modelsId);
            const encodeToken = encodeURIComponent(token);
            const encodeisPublish = encodeURIComponent(isPublish);
            const lang = Cookies.get('lang');

            // go editor
            window.open(`${SERVER.EDITOR_URL}/?projectId=${encodeProjectId}&modelsId=${encodeModelsId}&token=${encodeToken}&isPublish=${encodeisPublish}&lang=${lang}`, '_self');
        } else {
            Cookies.set('scanProjectId', projectId);
            Cookies.set('scanModelId', modelsId);
            navigate('/editor');
        }
    };

    const handleClickShowCtrl = () => {
        setShowCtrl(9999999999);
    };

    useEffect(() => {
        document.addEventListener('click', handleClickShowCtrl);
        return (() => {
            document.removeEventListener('click', handleClickShowCtrl);
        });
    }, []);

    const showCtrlEvent = (id) => (e) => {
        e.stopPropagation();
        if (showCtrl !== id) {
            setShowCtrl(id);
        } else {
            setShowCtrl(9999999999);
        }
    };

    const handleHoverViewTooltip = (cardId) => () => {
        setShowTooltip(true);
        setHoverViewId(cardId);
    };

    const handleHideViewTooltip = () => {
        setShowTooltip(false);
        setHoverViewId(null);
    };

    // 卡片暫態
    const CardListholder = () => {
        let inner = [];
        if (numberOfElements == 0) {
            return (<></>);
        }
        if ((projectCount - numberOfElements) > 12) {
            for (let i = 0; i < 12; i++) {
                inner.push(
                    <div className="cardListHolder" key={i}>
                        <div className="cardListHolder_Img"></div>
                    </div>);
            }
        } else {
            for (let i = 0; i < (projectCount - numberOfElements); i++) {
                inner.push(
                    <div className="cardListHolder" key={i}>
                        <div className="cardListHolder_Img"></div>
                    </div>);
            }
        }
        return (
            inner
        );
    };

    const [filterLoading, setFilterLoading] = useState(false);

    // 輪詢功能
    useEffect(() => {
        // 卡片列尚在初始狀態，則不執行輪詢
        if (!cardList || cardList.length === 0) return;

        // 從卡片列表篩選出狀態不是「已完成」跟「失敗」的物件，如果沒有則不執行輪詢
        const pollingList = cardList.filter(item => item.modelStatus !== 'DONE' && item.modelStatus !== 'PENDING');
        if (pollingList.length === 0) return;

        // 將篩選出來的物件id包裝成一個陣列
        let modelIds = [];
        pollingList.forEach(item => {
            modelIds.push(item.modelId);
        });

        // 計時器：每 60 秒執行一次輪詢，並更改卡片列表中的物件狀態
        const timer = setInterval(() => {
            fetchModelStatus(modelIds).then(res => {
                let afterPollingCardlist = [ ...cardList ];
                afterPollingCardlist.forEach(item => {
                    if (Object.keys(res).includes(`${item.modelId}`)) {
                        item.modelStatus = res[`${item.modelId}`];
                    }
                });
                setCardList(afterPollingCardlist);
            });
        }, 60000);

        return () => clearInterval(timer);
    }, [cardList]);

    return (
        <StyledCardListContainer>
            {abnormalPopup && <AbnormalPopup />}
            <Header />
            <div id='cardListContainer' className='cardListContainer'>
                <SortAndFilter
                    numberOfElements={numberOfElements}
                    setNumberOfElements={setNumberOfElements}
                    totalOfElements={totalOfElements}
                    setTotalOfElements={setTotalOfElements}
                    setFilterLoading={setFilterLoading}
                />
                {banner && <Banner setBanner={setBanner} />}
                <CookieBanner />
                {modalSetting.show && (
                    <ModalPopup
                        modalConfig={modalConfig[modalSetting.type]}
                        setModalSetting={setModalSetting}
                        modalSetting={modalSetting}
                    />
                )}
                {numberOfElements !== 0 && <div className="cardListPanel">
                    {
                        !filterLoading && cardList && cardList.map((item, index) => {
                            return (
                                <div
                                    key={item.id}
                                    className='card'
                                    onMouseEnter={showSettingBtn(item.id)}
                                    onMouseLeave={hideSettingBtn}
                                    onClick={toEditorEvent(item.id, item.modelId, item.publicSwitch, item.type)}
                                >
                                    <div className="cardImagePanel" 
                                        style={{
                                            background: item.modelStatus === 'DONE' ? 'radial-gradient(circle farthest-side at center, #ebebeb, #d1d1d1)' : '#ebebeb',
                                        }}
                                    >
                                        <div className="cardMask"></div>
                                        {item.modelStatus === 'DONE' ? (
                                            <img src={item.modelThumb ? item.modelThumb : defaultImg} alt="model-icon" className="cardImage" />
                                        ) : (
                                            item.modelStatus === 'PENDING' ? (
                                                <div style={{ color: '#F5314D' }}>系統處理失敗</div>
                                            ) : (
                                                <div style={{ color: '#333333' }}>系統處理中...</div>
                                            )
                                        )}
                                        <div onClick={showCtrlEvent(item.id)} className={cardSettingId === item.id ? 'cardSettingBtn showSettingBtn' : 'cardSettingBtn'}>
                                            <BsThreeDots />
                                            {
                                                showCtrl == item.id ?
                                                    <div>
                                                        <CardPanelBasic
                                                            name={item.name}
                                                            projectIndex={index}
                                                            projectId={item.id}
                                                            modelsId={item.modelId}
                                                            isPublish={item.publicSwitch}
                                                            setCanHoverSetting={setCanHoverSetting}
                                                            myPlan={myPlan}
                                                            objectType={item.type}
                                                            modelStatus={item.modelStatus}
                                                            setTotalOfElements={setTotalOfElements}
                                                        />
                                                    </div> : null
                                            }
                                        </div>
                                        <div className={`cardPublicStatus ${item.publicSwitch && 'isPublicStatus'}`}>
                                            {item.publicSwitch ? t('public') : t('draft')}
                                        </div>
                                    </div>
                                    <div className="cardInfoPanel flexStartCenter">
                                        <div className='objectTypeIcon'>
                                            {item.type === 'ARMAKER' ? <ArObject /> : <ScanKit />}
                                        </div>
                                        <div className={`cardName ${item.publicSwitch && 'isPusblish'}`}>
                                            <div className='nn'>
                                                {item.name}
                                            </div>
                                            <div className={`viewDiv ${store.permits.publicProject === 0 && 'freeView'}`}>
                                                <div style={{ color: !item.publicSwitch && '#cccccc' }} className={item.visitCount > 0 ? 'canHover' : ''}
                                                    onMouseEnter={handleHoverViewTooltip(item.id)}
                                                    onMouseLeave={handleHideViewTooltip}
                                                >
                                                    <AiFillEye style={{ fill: !item.publicSwitch && '#cccccc' }} />{item.visitCount}
                                                    <ViewToolTip showTooltip={showTooltip} cardId={item.id} hoverViewId={hoverViewId} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    {filterLoading && (
                        <div className='filterLoading'>
                            <div className='lds-ellipsis'>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    )}
                    {showCardListholder && <CardListholder />}
                </div>}
            </div>
        </StyledCardListContainer>
    );
};

export default CardList;