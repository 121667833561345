import React from 'react';

// styled components
import { StyledSmallLoadIngIcon } from './StyledSmallLoadIngIcon';

const SmallLoadIngIcon = () => {

    return (
        <StyledSmallLoadIngIcon>
            <div></div><div></div><div></div><div></div>
        </StyledSmallLoadIngIcon>
    );
};

export default SmallLoadIngIcon;