import styled from 'styled-components';

export const StyledUpload = styled.div`
    .uploadOption{
        position:sticky;
        z-index:9;
        top:0px;
        left:0;
        width:100%;
        height:108px;
        background: #FFFFFF;
        &.leaveTop{
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        }
        &_container{
            position:absolute;
            display:flex;
            align-items:center;
            justify-content:space-between;
            top:24px;
            left:50%;
            transform:translateX(-50%);
            margin-right:auto;
            padding: 6px;
            min-width: 320px;
            height: 60px;
            background: #F1F1F1;
            border-radius: 40px;
        }
        .selet{
            border-radius: 40px;
            padding: 12px 32px;
            cursor: pointer;
            &:hover{
                background: #E0E0E0;
            }
            span{
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color:#333333;
            }
            &.isActive{
                background: #ED0973;
                border-radius: 40px;
                &:hover{
                    background: #F2539D;
                }
                span{
                    color: #FFFFFF;
                }
            }
        }
    }
    .materialLib{
        &_List{
            display:flex;
            max-width:1304px;
            flex-wrap: wrap;
            // justify-content:center;
            margin-top:65px;
            margin-bottom:100px;
            margin-left:auto;
            margin-right:auto;
            .tootipbox{
                width:302px;
                height:44px;
                position:absolute;
                .triangle{
                    z-index:9999;
                    position: absolute;
                    top:36px;
                    left:50%;
                    transform:translateX(-50%); 
                    border-style: solid;
                    border-width: 0 15px 12px 15px;
                    border-color: transparent transparent rgba(0, 0, 0, 0.87) transparent;
                }
                .tootip{
                    z-index:9999;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 140%;
                    text-align:left;
                    padding:10px;
                    color: #FFFFFF;
                    position:absolute;
                    word-break: break-all;
                    top:47px;
                    left:50%;
                    transform:translateX(-50%);
                    // display:none;
                    width:230px;
                    background: rgba(0, 0, 0, 0.87);
                    border-radius: 4px;
                }
            }
        }
        &_List_holder{
            display:flex;
            max-width:1304px;
            flex-wrap: wrap;
            // justify-content:center;
            margin-top:65px;
            margin-bottom:100px;
            margin-left:auto;
            margin-right:auto;
            &_Card{
                width:302px;
                height:244px;
                margin:12px;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
                border-radius: 8px;
                cursor:pointer;
                &_Pic{
                    width:302px;
                    height:200px;
                    background: #E0E0E0;
                    border-radius: 8px 8px 0px 0px;
                    position:relative;
                }
                &_Name{
                    width:302px;
                    height:44px;
                    text-align:left;
                    padding:12px 16px;
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
        &_Card{
            width:302px;
            height:244px;
            margin:12px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            cursor:pointer;
            position:relative;
            z-index:1;
            &_Pic{
                width:302px;
                height:200px;
                background: #E0E0E0;
                border-radius: 8px 8px 0px 0px;
                position:relative;
                &_Mask{
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    background: rgba(0, 0, 0, 0);
                    border-radius: 8px 8px 0px 0px;
                    transition: all .2s ease-in-out;
                    .useMaterial{
                        display:none;
                    }
                    .previewMaterial{
                        display:none;
                    }
                    &:hover{
                    background: rgba(0, 0, 0, 0.5);
                        .useMaterial{
                            display:inline-block;
                            font-size: 14px;
                            line-height: 24px;
                            padding: 4px 16px;
                            color: #FFFFFF;
                            background: #ED0973;
                            border-radius: 40px;
                            margin-top:136px;
                            margin-right:24px;
                            cursor:pointer;
                            border: 1px solid #ED0973;
                            &:hover{
                                background: #F2539D;
                                border: 1px solid #F2539D;
                            }
                        }
                        .previewMaterial{
                            display:inline-block;
                            line-height: 24px;
                            padding: 4px 16px;
                            color: #FFFFFF;
                            background: rgba(255, 255, 255, 0.12);
                            border: 1px solid rgba(255, 255, 255, 0.6);
                            border-radius: 40px;
                            cursor:pointer;
                            &:hover{
                                background: rgba(255, 255, 255, 0.38);
                            }
                        }
                    }
                }
                img{
                    border-radius: 8px 8px 0px 0px;
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }
            }
            &_Name{
                width:302px;
                height:44px;
                text-align:left;
                padding:12px 16px;
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
    .materialLib_ifram{
        width:100%;
        height:100%;
        background: rgba(0, 0, 0, 0.8);
        position:fixed;
        top:0;
        left:0;
        z-index:20;
        &_popup{
            background: #FFFFFF;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
            border-radius: 16px;
            width:44% ;
            height:60.7% ;
            position:relative;
            margin-left:auto;
            margin-right:auto;
            margin-top:10%;
            padding-top:12px;
            .closeBtn{
                position:absolute;
                display:flex;
                justify-content:center;
                align-items:center;
                z-index:999999;
                top:-15px;
                right:-15px;
                cursor:pointer;
                background: #999999;
                border-radius: 50%;
                height:40px;
                width:40px;
                line-height:40px;
                color:#fff;
            }
            .main{
                width:calc(100% - 24px) ;
                height: 82%;
                margin:0 12px;
                position:relative;
                overflow:hidden;
                border: 1px solid #E0E0E0;
                border-radius: 8px;
                .loadingProgressContainer {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: #F2F2F2;
                    z-index: 2;
                    backdrop-filter: blur(40px);
            
                    .progressBarPanel {
                        margin-bottom: 24px;
                        width: 300px;
                        height: 6px;
                    }
            
                    .progress {
                        height: 6px;
                        background: #E0E0E0 !important;
                        border-radius: 6px;
                    }
            
                    .progress-bar {
                        height: 6px;
                        background: linear-gradient(79.78deg, #ED0973 0%, #FF744E 100%) !important;
                        border-radius: 8px;
                    }
            
                    .progressText {
                        color: #333333;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 28px;
                        letter-spacing: 0.02em;
                        text-align: left;
                        margin-bottom:20px;
                    }
                }
                iframe{
                    width:100%;
                    height:100%;
                }
                #myModelViewer{
                    width:100%;
                    height:100%;
                }
            }
            .name{
                display:inline-block;
                width:75%;
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align:left;
                font-size: 16px;
                line-height: 24px;
                position:absolute;
                left:2.8%;
                bottom:6.7%;
            }
            .useMaterial{
                display:inline-block;
                position:absolute;
                right:2.8%;
                bottom:4.8%;
                font-size: 14px;
                line-height: 24px;
                padding: 12px 24px;
                color: #FFFFFF;
                background: #ED0973;
                border-radius: 40px;
                cursor:pointer;
                &:hover{
                    background: #F2539D;
                }
            }
            .tootipbox{
                position:absolute;
                width:75%;
                height:24px;
                left:2.8%;
                bottom:6.7%;
                &:hover{
                    .triangle,.tootip{
                    display:block;
                    }
                }
                .triangle{
                    display:none;
                    z-index:9999;
                    position: absolute;
                    top:36px;
                    left:50%;
                    transform:translateX(-50%); 
                    border-style: solid;
                    border-width: 0 15px 12px 15px;
                    border-color: transparent transparent rgba(0, 0, 0, 0.87) transparent;
                }
                .tootip{
                    display:none;
                    z-index:9999;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 140%;
                    text-align:left;
                    padding:10px;
                    color: #FFFFFF;
                    position:absolute;
                    word-break: break-all;
                    top:47px;
                    left:50%;
                    transform:translateX(-50%);
                    // display:none;
                    width:88%;
                    background: rgba(0, 0, 0, 0.87);
                    border-radius: 4px;
                }
            }
        }
    }
    .inner{
        width: 100%;
        height: calc(100vh - 56px - 108px -54px);
        .block{
            width: 614px;
            position: relative;
            margin-top:54px;
            margin-right:auto;
            margin-left:auto;
            .btnContainer{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 50px;
                .canelBtn{
                    height: 48px;
                    display: block;
                    color: #666666;
                    border-radius: 4px;
                    font-size: 16px;
                    line-height:48px;
                    width: 80px;
                    background:#F2F2F2;
                    &:hover{
                        background-color: #E0E0E0;
                    }
                }
                .uploadBtn{
                    width: 112px;
                    height: 48px;
                    display: block;
                    color: #FFFFFF;
                    border-radius: 4px;
                    font-size:16px;
                    background-color: #ED2975;
                    line-height: 48px;
                    &:hover{
                        background-color: #F2539D;
                    }
                }
                .noFile{
                    pointer-events: none;
                    background-color: #FAB5D5;
                }
            }
            .fileDropCon{
                position: absolute;
                width: 100%;
            }
            .fileDrop{
                width: 100%;
                height: 344px;
                left:0;
                top:0;
                display: block;
                cursor: pointer;
                &:hover{
                    background-color: rgba(237, 41, 117, 0.04);
                    border: 1px solid #ED2975;
                    border-radius: 12px;
                }
            }
            .info{
                width: 100%;
                height: 344px;
                border-radius: 12px;
                border: 1px dashed #ED2975;
                .file{
                    z-index:-1;
                    background-color: rgba(237,41,117,0.08);
                    border-radius: 55px;
                    text-align:left;
                    max-width:304px;
                    margin-left:auto;
                    margin-right: auto;
                    margin-top: 75px;
                    margin-bottom: 40px;
                    position:relative;
                    .fileIcon{
                        vertical-align: middle;
                        width: 72px;
                        height:72px;
                        margin-right:11px;
                    }
                    .tt{
                        vertical-align: middle;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 140%;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.02em;
                        color: #333333;
                        font-size: 16px;
                        display: inline-block;
                        overflow: hidden;
                        width:100%;
                        max-width: 200px;
                        text-overflow: ellipsis;
                    }
                }
                .noFile{
                    background: none;
                    z-index:-1;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    .fileIcon{
                        vertical-align: middle;
                        width: 86px;
                        height:86px;
                    }
                }
                .title{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 140%;
                    padding-bottom: 8px;
                    color:#333;
                }
                .word{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 140%;
                    text-align: center;
                    letter-spacing: 0.02em;
                    color: #666666;
                    font-size:14px;
                }
            }
        }
    }
    .hidden{
        display: none;
    }
    #modelViewer{
        position: absolute;
        left:0;
        top:0;
        width: 100%;
        height: 100%;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
`;