// styles
import { StyledScanHeader } from './StyledScanHeader';

// react icon
import { IoClose } from 'react-icons/io5';

// images
import { ReactComponent as ScanKit } from './images/ScanKit.svg';

const ScanHeader = ({ title, clickEvent }) => {
    return (
        <StyledScanHeader title={title}>
            <div className='closeButton' onClick={clickEvent}>
                <IoClose />
            </div>
            <div className='titleWrapper'>
                {title && <ScanKit />}
                {title}
            </div>
        </StyledScanHeader>
    );
};

export default ScanHeader;