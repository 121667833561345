import { useEffect } from 'react';
import Modal from 'react-modal';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { CLOSE_MODAL } from 'redux/types';

// styles
import { StyledScanModal } from './StyledScanModal';

// react icon
import { MdClose } from 'react-icons/md';

// components
import ScanButton from 'components/ScanButton/ScanButton';
import { ReactComponent as Maximum } from './images/Art-Maximum.svg';
import { ReactComponent as Oops } from './images/Oops.svg';
import { ReactComponent as Error } from './images/Error.svg';
import { ReactComponent as CancelPublic } from './images/Cancel-Public.svg';
import { ReactComponent as Delete } from './images/Delete.svg';

// modal style
const customStyles = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.85)',
        zIndex: 9999999999,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0px',
        background: '#FFFFFF',
        borderRadius: '12px',
    },
};

const ScanModal = () => {

    const { isOpen, icon, title, description, description2, button1, button2, handleConfirm } = useSelector(store => store.modal);

    const dispatch = useDispatch();

    const handleCloseEvent = () => {
        dispatch({ type: CLOSE_MODAL });
    };

    const iconType = (element) => {
        switch (element) {
            case 'maximum':
                return <Maximum />;
            case 'oops':
                return <Oops />;
            case 'error':
                return <Error />;
            case 'cancel-public':
                return <CancelPublic />;
            case 'delete':
                return <Delete />;
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            style={customStyles}
            onRequestClose={handleCloseEvent}
            ariaHideApp={false}
        >
            <StyledScanModal>
                <MdClose className='closeButton' onClick={handleCloseEvent} />
                <div>
                    <div className='iconWrapper'>
                        {iconType(icon)}
                    </div>
                    <div className='textWrapper'>
                        <div className='title'>{title}</div>
                        <div className='description'>{description}</div>
                        <div className='description'>{description2}</div>
                    </div>
                    <div className='buttonWrapper' style={{ flexDirection: icon !== 'delete' ? 'row' : 'row-reverse' }}>
                        {
                            button1 !== '' &&
                            <ScanButton type='secondary' text={button1} clickEvent={handleCloseEvent} />
                        }
                        <ScanButton type={icon !== 'delete' ? 'primary' : 'alert'} text={button2} clickEvent={handleConfirm} />
                    </div>
                </div>
            </StyledScanModal>
        </Modal>
    );
};

export default ScanModal;