import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// redux
import { SET_SCANKIT_IMAGE_LIST, SET_SCANKIT_TAG_LIST, SET_PROJECT_RECORD, TOGGLE_SWITCH, OPEN_MODAL } from 'redux/types';

// styles
import { StyledEditor } from './StyledEditor';

// components
import Image360 from 'components/Image360/Image360';
import Loading from 'components/Loading/Loading';
import SuccessProject from 'components/SuccessProject/SuccessProject';
import SuccessCard from 'components/SuccessCard/SuccessCard';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import Switch from 'components/Switch/Switch';
import Notice from 'components/Notice/Notice';

// config
import { modalConfig } from 'config/modal';

// api
import { getProject } from 'api/scankit/getProject.api';
import { takedownProject } from 'api/scankit/takedownProject.api';
import { publicProject } from 'api/scankit/publicProject.api';
import { loginGet } from 'api/loginInfo/loginInfo.api';
import { updateModel } from 'api/updateModel/updateModel.api';
import { createBatchTags } from 'api/scankit/tag.api';
import { updateBatchTags } from 'api/scankit/tag.api';
import { createBatchTagsIcon } from 'api/scankit/tag.api';

// plugin
import Cookies from 'js-cookie';

// icons
import { MdHomeFilled } from 'react-icons/md';
import { IoCube } from 'react-icons/io5';
import { TiCloudStorage } from 'react-icons/ti';
import { BsArrowRepeat } from 'react-icons/bs';

// constants
import SERVER from 'constants/server';

// hooks
import useModal from 'hooks/useModal';

// i18n
import { useTranslation } from 'react-i18next';

// images
import { ReactComponent as ScanKit } from './images/ScanKit.svg';
import { ReactComponent as Reverse } from './images/Reverse.svg';
import { ReactComponent as Tags } from './images/Tags.svg';
import { ReactComponent as Add } from './images/Add.svg';
import { ReactComponent as Alert } from './images/Alert.svg';

const ScanKitEditor = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { modalSetting, setModalSetting } = useModal();
    const { t } = useTranslation();
    const [currentProject, setCurrentProject] = useState({
        name: '',
        isPublic: false,
        projectId: 0,
        modelId: 0,
        uuid: 0,
        scanSwitch: true,
        tagSwitch: true,
    });
    const [downloading, setDownloading] = useState(true);

    const store = useSelector(store => store);
    const editingTags = useSelector(store => store.tagList.editingTags);
    const { plan, publicProject: publicProjectLimit } = useSelector(store => store.permits);
    const { projectPublicCount } = useSelector(store => store.projectRecord);


    const [myPlan, setMyPlan] = useState('');

    useEffect(() => {

        // 舊版方案：free / basic / enterprise / armaker_enterprise_*企業名稱*
        // 新版方案：armaker_free_20220808 / armaker_basic_20220808 / <描述1>_Enterprise_<描述2>
        // 判斷方案（體驗版/基本版/企業版）
        if (plan.indexOf('free') > -1) {
            setMyPlan('free');
        } else if (plan.indexOf('basic') > -1) {
            setMyPlan('basic');
        } else if (plan.indexOf('enterprise') > -1 || plan.indexOf('Enterprise') > -1) {
            setMyPlan('enterprise');
        }

    }, [plan]);

    useEffect(() => {
        getProject(Cookies.get('token'), Cookies.get('scanProjectId'))
            .then((res) => {
                // console.log(res);
                setCurrentProject({
                    ...currentProject,
                    name: res.project.name,
                    isPublic: res.project.editors[0].publicSwitch,
                    projectId: res.project.id,
                    modelId: res.project.editors[0].models[0].id,
                    uuid: res.project.uuid,
                    scanSwitch: res.project.editors[0].models[0].scanSwitch,
                    tagSwitch: res.project.editors[0].models[0].tagSwitch,
                });
                dispatch({ type: SET_SCANKIT_IMAGE_LIST, payload: { scanFileList: res.project.editors[0].models[0].scanFileList } });
                dispatch({ type: SET_SCANKIT_TAG_LIST, payload: { oldTags: res.project.editors[0].models[0].tags, newTags: [], editingTags: [] } });
                dispatch({
                    type: TOGGLE_SWITCH, payload: {
                        scanSwitch: res.project.editors[0].models[0].scanSwitch,
                        tagSwitch: res.project.editors[0].models[0].tagSwitch,
                    },
                });
            })
            .finally(() => {
                setDownloading(false);
            });
    }, []);

    const imageList = useSelector(store => store.imageList.scanFileList);

    const [publicLoading, setPublicLoading] = useState(false);
    const [publicSuccess, setPublicSuccess] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    // 大儲存
    const handleSaveEvent = async () => {

        setSaveLoading(true);

        try {

            const getAllOldTags = store.tagList.oldTags;
            const getAllNewTags = store.tagList.newTags;
            const getAllEditingTags = store.tagList.editingTags;

            // 批次編輯舊標籤
            let idOfOldTagsWithImage = [];
            let oldTagsFormData = new FormData();

            if (getAllEditingTags.length > 0) {
                const mapAllOldTags = getAllOldTags.map(tag => {
                    let body = {
                        content: tag.content,
                        dataPosition: tag.dataPosition,
                        icon: tag.icon,
                        scankitIndex: tag.scankitIndex,
                        tagId: tag.id,
                        title: tag.title,
                        url: tag.url,
                    };

                    if (tag.file) {
                        idOfOldTagsWithImage.push(tag.id);
                        oldTagsFormData.append('files', tag.file);
                    }

                    return body;
                });

                // console.log(mapAllOldTags);
                await updateBatchTags(mapAllOldTags, Cookies.get('scanProjectId'), Cookies.get('scanModelId'));

                if (idOfOldTagsWithImage.length > 0) {
                    await createBatchTagsIcon(oldTagsFormData, Cookies.get('scanProjectId'), Cookies.get('scanModelId'), idOfOldTagsWithImage);
                }
            }

            // 批次新增新標籤
            let newTagsWithFile = [];

            if (getAllNewTags.length > 0) {
                const mapAllNewTags = getAllNewTags.map((tag, index) => {
                    let body = {
                        content: tag.content,
                        dataPosition: tag.dataPosition,
                        icon: tag.icon,
                        scankitIndex: tag.scankitIndex,
                        title: tag.title,
                        url: tag.url,
                    };

                    if (tag.file) {
                        newTagsWithFile.push({
                            index: index,
                            imageFile: tag.file,
                        });
                    }

                    return body;
                });

                // console.log(mapAllNewTags);
                const createBatchTagsResponse = await createBatchTags(mapAllNewTags, Cookies.get('scanProjectId'), Cookies.get('scanModelId'));

                if (newTagsWithFile.length > 0) {
                    let idOfNewTagsWithImage = [];
                    let newTagsFormData = new FormData();

                    newTagsWithFile.forEach(tag => {
                        idOfNewTagsWithImage.push(createBatchTagsResponse[tag.index].tag.id);
                        newTagsFormData.append('files', tag.imageFile);
                    });

                    await createBatchTagsIcon(newTagsFormData, Cookies.get('scanProjectId'), Cookies.get('scanModelId'), idOfNewTagsWithImage);
                }
            }

            // 更新oldTags列表(同時清空newTags及editingTags)
            await getProject(Cookies.get('token'), Cookies.get('scanProjectId')).then((res) => {
                dispatch({
                    type: SET_SCANKIT_TAG_LIST, payload: {
                        oldTags: res.project.editors[0].models[0].tags,
                        newTags: [],
                        editingTags: [],
                    },
                });
            });

            // 更新模型屬性
            let initModel = {
                scanSwitch: currentProject.scanSwitch,
                tagSwitch: currentProject.tagSwitch,
            };

            await updateModel(initModel, Cookies.get('token'), Cookies.get('scanProjectId'), Cookies.get('scanModelId'));

        } catch (error) {

            console.log(error);

            dispatch({
                type: OPEN_MODAL, payload: {
                    isOpen: true,
                    icon: 'error',
                    title: '儲存時發生錯誤',
                    description: t('interneterror'),
                    button2: t('PleaseTryAgain'),
                    handleConfirm: () => {
                        window.location.reload();
                    },
                },
            });

        }

        setSaveLoading(false);

    };

    const handlePublicEvent = () => {
        if (currentProject.isPublic) {
            // 更新至公開頁
            setModalSetting({
                ...modalSetting,
                show: true,
                type: 'type10',
                handleConfirm: async () => {
                    setPublicLoading(true);
                    await handleSaveEvent();
                    await takedownProject(Cookies.get('token'), Cookies.get('scanProjectId')).then(() => {
                        // console.log(res);
                        publicProject(Cookies.get('token'), Cookies.get('scanProjectId')).then(() => {
                            setPublicLoading(false);
                            // 更新至公開頁成功後跳 modal
                            setUpdateStatus(true);
                            setPublicSuccess(true);
                        });
                    }).catch((err) => {
                        setModalSetting({
                            ...modalSetting,
                            show: true,
                            title: '',
                            type: 'type39',
                            handleConfirm: () => {
                                window.location.reload();
                            },
                        });
                    });
                },
            });
        } else {
            // 公開專案
            if (projectPublicCount < publicProjectLimit) {
                setPublicLoading(true);
                publicProject(Cookies.get('token'), Cookies.get('scanProjectId')).then(() => {
                    // console.log(res);
                    getProject(Cookies.get('token'), Cookies.get('scanProjectId')).then((res) => {
                        setCurrentProject({
                            ...currentProject,
                            isPublic: res.project.editors[0].publicSwitch,
                        });
                        loginGet(Cookies.get('token')).then((resProjectInfo) => {
                            dispatch({ type: SET_PROJECT_RECORD, payload: resProjectInfo });
                        });
                    });
                    setPublicLoading(false);
                    // 公開專案成功後跳 modal
                    setPublicSuccess(true);
                }).catch((err) => {
                    setModalSetting({
                        ...modalSetting,
                        show: true,
                        title: '',
                        type: 'type39',
                        handleConfirm: () => {
                            window.location.reload();
                        },
                    });
                });
            } else {
                // 可公開物件達上限，跳出 popup
                setModalSetting({
                    ...modalSetting,
                    show: true,
                    type: myPlan === 'free' ? 'type19' : 'type40',
                    desc: t('publicFail', { projectCount: projectPublicCount, projectLimit: publicProjectLimit }),
                    handleConfirm: () => {
                        if (myPlan === 'free') {
                            navigate('/cardList?upgrade=true');
                        } else {
                            window.open(`${SERVER.AR_MAKER}/contact_us?lang=${Cookies.get('lang')}`, '_blank');
                        }
                    },
                });
            }
        }
    };

    const [notice, setNotice] = useState(false);

    const toggleSwitch = (target) => {

        if (target === 'reverse') {
            // 跳出變更提示
            setNotice(true);
            setCurrentProject({
                ...currentProject,
                scanSwitch: !currentProject.scanSwitch,
            });
            dispatch({ type: TOGGLE_SWITCH, payload: { scanSwitch: !currentProject.scanSwitch } });
            // 取消變更提示
            setTimeout(() => {
                setNotice(false);
            }, 2000);
        }

        if (target === 'tag') {
            setCurrentProject({
                ...currentProject,
                tagSwitch: !currentProject.tagSwitch,
            });
            dispatch({ type: TOGGLE_SWITCH, payload: { tagSwitch: !currentProject.tagSwitch } });
        }

    };

    const [navItem, setNavItem] = useState('reverse');

    // 標籤相關
    const [tags, setTags] = useState([]);
    const [currentTag, setCurrentTag] = useState({
        serialNumber: 0,
        imageIndex: 0,
    });

    // 標籤列表快捷鍵
    const handleClickTagList = (index, item) => {
        // 關閉顯示標籤＆不是標籤列表時，不觸發「標籤快捷鍵」功能
        if (!currentProject.tagSwitch) return;
        if (navItem !== 'tags') return;

        setCurrentTag({
            serialNumber: index + 1,
            imageIndex: item.scankitIndex,
        });
    };

    // 更新標籤列表
    useEffect(() => {
        const oldTags = store.tagList.oldTags;
        const newTags = store.tagList.newTags;
        // 因為 newTags 沒有 id，故給一個識別值 uniqueId
        const sortedNewTags = newTags.map((tag, index) => {
            tag.uniqueId = oldTags.length + index + 1;
            return tag;
        });
        setTags([...oldTags, ...sortedNewTags]);
    }, [store.tagList.oldTags, store.tagList.newTags]);

    return (
        <StyledEditor>
            {publicLoading &&
                <Loading
                    mask='true'
                    title={currentProject.isPublic ? '正在更新至公開頁...' : t('publishingProject')}
                    txt={t('pleaseWait')}
                />
            }
            {publicSuccess &&
                <SuccessProject scanViewerUrl={true} uuid={currentProject.uuid} onCallParent={setPublicSuccess} updateStatus={updateStatus} />
            }
            {modalSetting.show && (
                <ModalPopup
                    modalSetting={modalSetting}
                    setModalSetting={setModalSetting}
                    modalConfig={modalConfig[modalSetting.type]}
                />
            )}
            <div className='headerContainer'>
                <div className='startContainer'>
                    <Link to='/cardList' className='homeButton'>
                        <MdHomeFilled />
                    </Link>
                    <div className='planTag'>
                        {myPlan === 'free' ? t('packageFree') : myPlan === 'basic' ? t('packageBasic') : t('packageEnterprise')}
                    </div>
                </div>
                <div className='centerContainer'>
                    <ScanKit className='scankitMark' />
                    <div className='projectName'>
                        {currentProject.name}
                    </div>
                </div>
                <div className='endContainer'>
                    <button className='editorButton primary' onClick={handleSaveEvent}>
                        {saveLoading && <BsArrowRepeat className='arrowIcon' />}
                        <span>儲存</span>
                    </button>
                    <button className='editorButton secondary' onClick={handlePublicEvent}>
                        <TiCloudStorage />
                        <span>{currentProject.isPublic ? '更新至公開頁' : t('public')}</span>
                    </button>
                </div>
            </div>
            <div className='contentContainer'>
                <div className='navbarContainer'>
                    <div className='navbarPanel'>
                        <div
                            className={navItem === 'reverse' ? 'navbarTabs navbarActive' : 'navbarTabs'}
                            onClick={() => setNavItem('reverse')}
                        >
                            <Reverse />
                        </div>
                        <div
                            className={navItem === 'tags' ? 'navbarTabs navbarActive' : 'navbarTabs'}
                            onClick={() => setNavItem('tags')}
                        >
                            <Tags />
                        </div>
                    </div>
                    <div className='editorPanel'>
                        {navItem === 'reverse' && (
                            <div className='switchContainer'>
                                <div className='switchLabel'>
                                    變更物件旋轉方向
                                </div>
                                <div onClick={() => toggleSwitch('reverse')}>
                                    <Switch turn={currentProject.scanSwitch} />
                                </div>
                            </div>
                        )}
                        {navItem === 'tags' && (
                            <>
                                <div className='switchContainer'>
                                    <div className='switchLabel'>
                                        我的標籤
                                    </div>
                                    <div onClick={() => toggleSwitch('tag')}>
                                        <Switch turn={currentProject.tagSwitch} />
                                    </div>
                                </div>
                                <div className='tagsContainer'>
                                    {
                                        tags.length > 0 ?
                                            <>
                                                {tags.map((item, index) => {
                                                    return (
                                                        <div key={index} className='tagList' onClick={() => handleClickTagList(index, item)}>
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        background: item.id === null ? 'rgba(255, 255, 255, 0.30)' : 'rgba(255, 112, 67, 0.85)',
                                                                    }}
                                                                >
                                                                    {index + 1}
                                                                </div>
                                                                <div>
                                                                    {item.title}
                                                                </div>
                                                                {editingTags.indexOf(item.id) !== -1 && <Alert className='alertIcon' />}
                                                            </div>
                                                            <div>
                                                                <img src={imageList[item.scankitIndex]} alt='thumbnail' />
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </> :
                                            <div className='emptyTagNotice'>
                                                <Add />
                                                <div>點擊物件2次</div>
                                                <div>即可新增標籤</div>
                                            </div>
                                    }
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className='editorContainer'>
                    {notice && (
                        <div className='notice'>
                            <Notice text={currentProject.scanSwitch ? '成功變更物件旋轉方向。' : '成功還原為初始旋轉方向。'} />
                        </div>
                    )}
                    {
                        downloading ?
                            <div className='lds-ellipsis'>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div> :
                            <Image360
                                imageList={imageList}
                                tagNavbar={navItem === 'tags'}
                                tags={tags}
                                currentTag={currentTag}
                                viewer={false}
                            />
                    }
                </div>
            </div>
        </StyledEditor>
    );
};

export default ScanKitEditor;