import styled from 'styled-components';

export const StyledUnsubscribe = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    left:0;
    top:0;
    background-color: rgba(0,0,0,0.8);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    .container{
        border-radius: 8px;
        overflow: hidden;
        width: 1110px;
        height: 90%;
        overflow: hidden;
        @media (max-width:1366px) {
            width:calc(1049 / 1366 * 100%);
        }
        @media (max-width:1280px) {
            width:calc(978 / 1280 * 100%);
        }
        @media (max-width:992px) {
            width:calc(722 / 992 * 100%);
        }
        @media (max-width:768px) {
            width:calc(536 / 768 * 100%);
        }
        @media (max-width:414px) {
            width: 100%;
            height: 100%;
            border-radius: 0px;
        }
        .title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            font-size:20px;
            height: 64px;
            line-height: 64px;
            padding: 5px 20px;
            background: linear-gradient(79.78deg, #ED0973 0%, #FF744E 100%);
            .goBackIcon {
                cursor: pointer;
                font-size: 14px;
                opacity: 0.6;
                display: flex;
                flex-direction: row;
                column-gap: 8px;
                align-items: center;
                svg {
                    font-size: 20px;
                }
                &:hover {
                    opacity: 1;
                }
            }
            .closeIcon{
                font-size: 25px;
                cursor: pointer;
            }
        }
        .bg{
            height: calc(100% - 64px);
            background: #F2F2F2;
            overflow: auto;
            scroll-behavior: smooth;
            padding: 40px;
            @media (max-width: 768px) {
                padding: 40px 24px;
            }
            .formBackground {
                width: 100%;
                background: #FFFFFF;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
                border-radius: 8px;
                padding: 32px 24px;
                color: #333333;
                text-align: left;
                font-weight: 500;
            }
            .question {
                font-size: 18px;
                margin-bottom: 24px;
                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }
            .required {
                color: #FF744E;
                font-size: 14px;
                margin-left: 8px;
                @media (max-width: 768px) {
                    font-size: 12px;
                }
            }
            .checkboxPanel {
                margin-left: 4px;
                margin-bottom: 18px;
                .checkbox {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    position: relative;
                }
                .checkbox > span {
                    font-weight: 500;
                    letter-spacing: 0.02em;
                    color: #333333;
                    padding: 0px 8px;
                    line-height: 22px;
                    @media (max-width: 768px) {
                        font-size: 14px;
                    }
                }
                .checkbox > input {
                    width: 15px;
                    height: 15px;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    -o-appearance: none;
                    appearance: none;
                    background: #FFFFFF;
                    border: 1.4px solid #999999;
                    border-radius: 2px;
                    outline: none;
                    transition-duration: 0.3s;
                    cursor: pointer;
                }
                .checkbox > input:checked {
                    background: #FF744E;
                    border: 1.4px solid #FF744E;
                }
                .checkbox > input:checked + span::before {
                    content: 'L';
                    display: block;
                    text-align: center;
                    color: #fff;
                    position: absolute;
                    left: 3.5px;
                    top: 0px;
                    font-family: inherit;
                    font-size: 10px;
                    font-weight: 600;
                    -ms-transform: scaleX(-1) rotate(-35deg); /* IE 9 */
                    -webkit-transform: scaleX(-1) rotate(-35deg); /* Chrome, Safari, Opera */
                    transform: scaleX(-1) rotate(-35deg);
                }
                .checkbox > input:hover {
                    border: 1.4px solid #F2539D;
                }
            }
            .textArea {
                width: 100%;
                height: 120px;
                background: #FFFFFF;
                border: 1px solid #CCCCCC;
                box-sizing: border-box;
                border-radius: 2px;
                padding: 8px 16px;
                resize: none;
                &:hover {
                    border: 1px solid #FF744E;
                }
                &:focus {
                    outline: none;
                    border: 1px solid #FF744E;
                    box-shadow: 0px 0px 0px 4px rgba(255, 116, 78, 0.15);
                }
                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }
            .notiSection {
                margin: 48px 0px;
                display: flex;
                flex-direction: column;
                row-gap: 24px;
            }
            .notification {
                display: flex;
                flex-direction: row;
                column-gap: 8px;
                align-items: flex-start;
                .icon {
                    width: 24px;
                    height: 24px;
                    padding: 3px;
                    svg {
                        font-size: 18px;
                        color: #FFC55E;
                    }
                }
                span {
                    color: #54C68F;
                    line-height: 26px;
                }
                div {
                    @media (max-width: 768px) {
                        font-size: 14px;
                    }
                }
            }
            .changeList {
                margin-top: 16px;
                line-height: 160%;
                span {
                    color: #999999;
                }
            }
            .confirmInput {
                width: 100%;
                padding: 12px 16px;
                background: #FFFFFF;
                border: 1px solid #CCCCCC;
                box-sizing: border-box;
                border-radius: 2px;
                margin-top: 16px;
                margin-bottom: 72px;
                &:hover {
                    border: 1px solid #FF744E;
                }
                &:focus {
                    outline: none;
                    border: 1px solid #FF744E;
                    box-shadow: 0px 0px 0px 4px rgba(255, 116, 78, 0.15);
                }
            }
            .buttons {
                display: inline-flex;
                flex-direction: row;
                column-gap: 50px;
            }
            .primaryButton {
                padding: 12px 24px;
                background: ${props => props.disabled ? '#FAB5D5' : '#ED0973'};
                border: 1px solid ${props => props.disabled ? '#FAB5D5' : '#ED0973'};
                border-radius: 4px;
                color: #FFFFFF;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                display: inline-flex;
                &:hover {
                    background: ${props => props.disabled ? '#FAB5D5' : '#F2539D'};
                    border: 1px solid ${props => props.disabled ? '#FAB5D5' : '#F2539D'};
                }
            }
            .cancelButton {
                padding: 12px 24px;
                background: #F2F2F2;
                border: 1px solid #F2F2F2;
                border-radius: 4px;
                color: #666666;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                &:hover {
                    background: #E0E0E0;
                    border: 1px solid #E0E0E0;
                }
            }
            .arrowIcon {
                font-size: 20px;
                margin-right: 10px;
                animation: rotateMove 2s infinite linear;
            }
            .checkIcon {
                font-size: 20px;
                margin-right: 10px;
            }
            @keyframes rotateMove {
                0%{
                    transform: rotateZ(0deg);
                }
                100%{
                    transform: rotateZ(360deg);
                }
            }
        }
    }
`;