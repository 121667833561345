import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//redux
import { OPEN_MODAL } from 'redux/types';

// styles
import { StyledChooseType } from './StyledChooseType';

// react icon
import { IoClose } from 'react-icons/io5';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

// images
import { ReactComponent as ArObject } from './images/ArObject.svg';
import { ReactComponent as ScanKit } from './images/ScanKit.svg';

// components
import Button from 'components/Button/Button';

// api
import { createProject } from 'api/createProject/createProject.api';
import { createScankitProject } from 'api/scankit/createProject.api';

// plugin
import Cookies from 'js-cookie';

// i18n
import { useTranslation, Trans } from 'react-i18next';

const ChooseType = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [armodelLoading, setArmodelLoading] = useState(false);
    const [scankitLoading, setScankitLoading] = useState(false);

    const handleCloseEvent = () => {
        navigate('/cardList');
    };

    const handleCreateArObject = () => {
        setArmodelLoading(true);

        // console.log('創建 AR 物件');
        let object = {
            description: '',
            hashTags: [],
            id: 0,
            name: '我的名稱',
        };

        createProject(object, Cookies.get('token')).then((res) => {
            Cookies.set('projectId', res.project.id);
            navigate('/upload');
        }).catch((error) => {
            console.log(error);
            dispatch({
                type: OPEN_MODAL, payload: {
                    isOpen: true,
                    icon: 'error',
                    title: '發生錯誤',
                    description: t('interneterror'),
                    button2: t('PleaseTryAgain'),
                    handleConfirm: () => {
                        window.location.reload();
                    },
                },
            });
        }).finally(() => {
            setArmodelLoading(false);
        });

    };

    const handleCreateScanKit = () => {
        setScankitLoading(true);

        // console.log('創建 Scankit 物件');
        let object = {
            description: '',
            hashTags: [],
            id: 0,
            name: '',
        };

        createScankitProject(object, Cookies.get('token')).then((res) => {
            Cookies.set('scanProjectId', res.project.id);
            Cookies.set('scanModelId', res.project.editors[0].models[0].id);
            navigate('/new');
        }).catch((error) => {
            console.log(error);
            dispatch({
                type: OPEN_MODAL, payload: {
                    isOpen: true,
                    icon: 'error',
                    title: '發生錯誤',
                    description: t('interneterror'),
                    button2: t('PleaseTryAgain'),
                    handleConfirm: () => {
                        window.location.reload();
                    },
                },
            });
        }).finally(() => {
            setScankitLoading(false);
        });
    };

    return (
        <StyledChooseType>
            <IoClose className='close_button' onClick={handleCloseEvent} />
            <div className='page_title'>{t('chooseType.chooseTheTypeOfObject')}</div>
            <div className='page_content'>
                <div className='type_section'>
                    <ArObject className='object_icon' />
                    <div className='object_description'>
                        <Trans i18nKey="chooseType.canBeUsedInAR">
                            建立可用於<br />
                            <span className='primary_highlight'>擴增實境體驗</span>的3D物件
                        </Trans>
                    </div>
                    <p className='support_format'>{t('chooseType.supportFormatFor3D')}</p>
                    <p className='support_format'>{t('chooseType.clickHereForDetail')}</p>
                    <Button
                        type='dark'
                        icon={armodelLoading && <AiOutlineLoading3Quarters className='bsRotate' />}
                        text={t('chooseType.startUse')}
                        clickEvent={handleCreateArObject}
                        disabled={armodelLoading}
                    />
                </div>
                <div className='divider_section'>
                    <span></span>
                    <div>{t('chooseType.or')}</div>
                    <span></span>
                </div>
                <div className='type_section'>
                    <ScanKit className='object_icon' />
                    <div className='object_description'>
                        <Trans i18nKey="chooseType.canBeUsedIn360">
                            建立<span className='secondary_highlight'>360°環物拍攝</span><br />
                            快速完成立體化展示
                        </Trans>
                    </div>
                    <p className='support_format'>{t('chooseType.supportFormatForImage')}</p>
                    <p className='support_format'>{t('chooseType.clickHereForDetail')}</p>
                    <Button
                        type='dark'
                        icon={scankitLoading && <AiOutlineLoading3Quarters className='bsRotate' />}
                        text={t('chooseType.startUse')}
                        clickEvent={handleCreateScanKit}
                        disabled={scankitLoading}
                    />
                </div>
            </div>
        </StyledChooseType>
    );
};

export default ChooseType;