import React, { useState } from 'react';

// styled components
import { StyledLoadingBar } from './StyledLoadingBar';
//images
import file from './images/file.svg';

const LoadingBar = (props) => {

    return (
        <StyledLoadingBar progress={props.progress}>
            <div className="whiteBg">
                <div className="projectName">
                    <div className="file">
                        <img className='fileIcon' src={file} alt="" />
                        <span>{props.fileName}</span>
                    </div>
                </div>
                <div className="Loadingbar">
                    <div className="progress">
                    </div>
                </div>
                <div className="progressText">
                    <span>Uploading... {props.progress}%</span>
                </div>
            </div>
        </StyledLoadingBar>
    );
};

export default LoadingBar;