const env = window.location.hostname === 'localhost' ? 'local' : process.env.BUILD_ENV || process.env.REACT_APP_BUILD_ENV;
const SERVER = {
    local: {
        FREE_PACKAGE_ID: 'ff808181826c8e0601827b0a54b90005',
        BASIC_PACKAGE_ID: 'ff8081817f4f3259017f52aa59410000',
        VIEW_USAGE_ID: 'ff8081817f4f3259017f52ab73b90007',
        LIMIT_USAGE_ID: 'ff8081817f4f3259017f52abe4ab000e',
        PRODUCT_ID: 'ff8081817ca04e50017ca21459620000',
        PAGE_VIEW_ID: 'ff808181826c8e0601827b4f4bb00022',
        NEW_BASIC_PACKAGE_ID: 'ff808181826c8e0601827b2e1b860009',
    },
    development: {
        FREE_PACKAGE_ID: 'ff808181826c8e0601827b0a54b90005',
        BASIC_PACKAGE_ID: 'ff8081817f4f3259017f52aa59410000',
        VIEW_USAGE_ID: 'ff8081817f4f3259017f52ab73b90007',
        LIMIT_USAGE_ID: 'ff8081817f4f3259017f52abe4ab000e',
        PRODUCT_ID: 'ff8081817ca04e50017ca21459620000',
        PAGE_VIEW_ID: 'ff808181826c8e0601827b4f4bb00022',
        NEW_BASIC_PACKAGE_ID: 'ff808181826c8e0601827b2e1b860009',
    },
    test: { // temporary use dev env
        FREE_PACKAGE_ID: 'ff808181826c8e0601827b0a54b90005',
        BASIC_PACKAGE_ID: 'ff8081817f4f3259017f52aa59410000',
        VIEW_USAGE_ID: 'ff8081817f4f3259017f52ab73b90007',
        LIMIT_USAGE_ID: 'ff8081817f4f3259017f52abe4ab000e',
        PRODUCT_ID: 'ff8081817ca04e50017ca21459620000',
        PAGE_VIEW_ID: 'ff808181826c8e0601827b4f4bb00022',
        NEW_BASIC_PACKAGE_ID: 'ff808181826c8e0601827b2e1b860009',
    },
    production: {
        FREE_PACKAGE_ID: 'ff8081818247840401827b5482c2010b',
        BASIC_PACKAGE_ID: 'ff8081817f0beedc017f52b629e5002d',
        VIEW_USAGE_ID: 'ff8081817f0beedc017f52b705120034',
        LIMIT_USAGE_ID: 'ff8081817f0beedc017f52b82823003b',
        PRODUCT_ID: 'ff8081817d274eb7017dfa0c507503ee',
        PAGE_VIEW_ID: 'ff8081818247840401827b5957e80113',
        NEW_BASIC_PACKAGE_ID: 'ff8081818247840401827b57c345010f',
    },
};

export default SERVER[env];

