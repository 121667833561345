import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';


const store = createStore(
    rootReducer,
    composeWithDevTools(),
);

if (process.env.NODE_ENV === 'development') Object.defineProperty(window, 'store', { get: store.getState });

export default store;