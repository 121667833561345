import styled from 'styled-components';

export const StyledNotice = styled.div`
    padding: 8px 24px;
    background: #54C68F;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    color: #FFFFFF;

    >span {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
    }
`;