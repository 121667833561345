import en from 'i18n/en.json';
import tw from 'i18n/zh-tw.json';
import fr from 'i18n/fr.json';
import ko from 'i18n/ko.json';

import Cookies from 'js-cookie';

const iconDelete = './assets/icons/icon-delete.svg';
const iconAlertFile = './assets/icons/icon-alert-file.svg';
const iconAlertTag = './assets/icons/icon-alert-tag.svg';
const iconExit = './assets/icons/icon-exit.svg';
const iconAlert = './assets/icons/icon-alert-warn.svg';
const iconCould = './assets/icons/icon-could.svg';
const iconUpload = './assets/icons/icon-upload.svg';
const iconCopy = './assets/icons/icon-copy.svg';
const iconDeskbook = './assets/icons/icon-deskbook.svg';
const iconNoPublic = './assets/icons/icon-nopublic.svg';
const iconAsync = './assets/icons/icon-async.svg';


let lang;

if (Cookies.get('lang') === 'en') {
    lang = en;
} else if (Cookies.get('lang') === 'fr') {
    lang = fr;
} else if (Cookies.get('lang') === 'ko') {
    lang = ko;
} else {
    lang = tw;
}


export const modalConfig = {
    type1: {
        icon: iconDelete,
        type: 'delete',
        desc: ['要刪除 ', '', ' 標籤嗎?'],
        notice: '標籤刪除後將無法復原。',
    },
    type2: {
        icon: iconAlertFile,
        type: 'alert-format',
        desc: lang.modelFormatError,
        notice: '支援: 圖片小於 2MB 的 JPG、PNG 檔。',
    },
    type3: {
        icon: iconAlertFile,
        type: 'alert-storage',
        desc: lang.modelSizeError,
        notice: '支援: jpg、png，圖片大小2M以內。',
    },
    type4: {
        icon: iconAlertTag,
        type: 'alert-tag-name',
        desc: '所有標籤須包含標題。',
        notice: '為標籤添加標題 或 刪除無標題標籤。',
    },
    type5: {
        icon: iconExit,
        type: 'exit-editor',
        desc: '要離開編輯器嗎?',
        notice: '系統不會自動儲存你所做的變更。',
    },
    type6: {
        icon: iconExit,
        type: 'exit-tag',
        desc: lang.leaveObjectTagPrompt1,
        notice: lang.leaveObjectTagPrompt2,
    },
    type7: {
        icon: iconDelete,
        type: 'file-delete',
        desc: '要刪除已上傳的物件縮圖?',
        notice: '刪除後將無法復原。',
    },
    type8: {
        icon: iconAlertFile,
        type: 'alert-format',
        desc: lang.modelFormatError,
        notice: '支援: 比例 2:1 (例:2880x1440) 且小於 2MB 的 HDR、JPG、PNG 檔。  ',
    },
    type9: {
        icon: iconDelete,
        type: 'file-delete',
        desc: '要刪除已上傳的HDR?',
        notice: '刪除後將無法復原。',
    },
    type10: {
        icon: iconAsync,
        type: 'file-save',
        desc: lang.saveAndUpdateTitle,
        notice: lang.saveAndPublishDec,
    },
    type11: {
        icon: iconAlert,
        type: 'file-save',
        desc: '您有未儲存的變更，是否儲存並預覽專案?',
        notice: '未儲存的變更將遺失且不會顯示於預覽畫面。',
    },
    type12: {
        icon: iconCould,
        type: 'fail-save',
        desc: lang.previewFail,
        notice: lang.interneterror,
    },
    type13: {
        icon: iconAlert,
        type: 'file-preview',
        desc: '您有未儲存的變更，是否儲存並預覽專案?',
        notice: '未儲存的變更將遺失且不會顯示於預覽畫面。',
    },
    type14: {
        icon: iconAlertFile,
        type: 'alert-storage',
        desc: lang.modelSizeError,
        notice: lang.objectSupportPrompt,
    },
    type15: {
        icon: iconAlertFile,
        type: 'alert-storage',
        desc: '一次只能上傳一個檔案',
        notice: lang.objectSupportPrompt,
    },
    type16: {
        icon: iconAlertFile,
        type: 'alert-storage',
        desc: lang.modelFormatError,
        notice: lang.objectSupportPrompt,
    },
    type17: {
        icon: iconDelete,
        type: 'file-delete',
        desc: '要刪除 "iStaging Meta..." 專案嗎?',
        notice: '刪除後將無法復原。',
    },
    type18: {
        icon: iconCopy,
        type: 'file-copy',
        desc: lang.copyProject,
        notice: lang.duplicateProjectPrompt,
    },
    type19: {
        icon: iconUpload,
        type: 'file-upload',
        desc: lang.publicFail,
        notice: lang.upgradePlanPrompt,
    },
    type20: {
        icon: iconCould,
        type: 'alert-storage',
        desc: '建立專案失敗。',
        notice: lang.interneterror,
    },
    type21: {
        icon: iconCould,
        type: 'alert-storage',
        desc: lang.loginFail,
        notice: '請確認帳號密碼後再試一次。',
    },
    type22: {
        icon: iconCould,
        type: 'alert-storage',
        desc: lang.uploadObjectFail2,
        notice: lang.interneterror,
    },
    type23: {
        icon: iconCould,
        type: 'alert-storage',
        desc: lang.createProjectInformationFail,
        notice: lang.interneterror,
    },
    type24: {
        icon: iconCould,
        type: 'alert-storage',
        desc: lang.publicProjectFail,
        notice: lang.interneterror,
    },
    type25: {
        icon: iconCould,
        type: 'alert-storage',
        desc: lang.errorWhileDeleting,
        notice: lang.interneterror,
    },
    type26: {
        icon: iconCould,
        type: 'alert-storage',
        desc: lang.duplicateProjectFail,
        notice: lang.interneterror,
    },
    type27: {
        icon: iconCould,
        type: 'alert-storage',
        desc: lang.publicProjectFail,
        notice: lang.interneterror,
    },
    type28: {
        icon: iconCould,
        type: 'alert-storage',
        desc: lang.errorWhilePublishing,
        notice: lang.interneterror,
    },
    type29: {
        icon: iconUpload,
        type: 'file-upload',
        desc: lang.createProjectMaximumPrompt,
        notice: lang.upgradeProjectMaximumPrompt,
    },
    type30: {
        icon: iconDelete,
        type: 'delete',
        desc: [lang.deleteProjectPrompt1, '', lang.deleteProjectPrompt2],
        notice: lang.deleteProjectRecoverPrompt,
    },
    type31: {
        icon: iconCould,
        type: 'alert-storage',
        desc: lang.saveProjectFail,
        notice: lang.interneterror,
    },
    type32: {
        icon: iconUpload,
        type: 'file-upload',
        desc: lang.publicProjectMaximumFail,
        notice: lang.upgradePublicProjectPrompt,
    },
    type33: {
        icon: iconCopy,
        type: 'file-share',
        desc: lang.copyUrlPrompt,
        notice: '',
    },
    type34: {
        icon: iconNoPublic,
        type: 'file-close',
        desc: lang.cancelPublicProjectPrompt,
        notice: lang.cancelPublicProjectDraftPrompt,
    },
    type35: {
        icon: iconUpload,
        type: 'file-upload',
        desc: lang.createProjectMaximumPromptFree,
        notice: lang.upgradeProjectMaximumPromptFree,
    },
    type36: {
        icon: iconDeskbook,
        type: 'alert-storage',
        desc: lang.recommendedDevice,
        notice: lang.bestExperience,
    },
    type37: {
        icon: iconCould,
        type: 'loading-err',
        desc: lang.errorWhileLoading,
        notice: lang.interneterror,
    },
    type38: {
        icon: iconCould,
        type: 'alert-storage',
        desc: lang.errorWhileCopying,
        notice: lang.interneterror,
    },
    type39: {
        icon: iconCould,
        type: 'loading-err',
        desc: lang.errorWhilePublishing,
        notice: lang.interneterror,
    },
    type40: {
        icon: iconUpload,
        type: 'file-upload2',
        desc: lang.createFail,
        notice: lang.youCanConnectSalerToFinishCreateProject,
    },
    type41: {
        icon: iconCould,
        type: 'loading-err',
        desc: lang.errorWhileSubscribing,
        notice: lang.internetErrorOrContact,
    },
    type42: {
        icon: iconAlertFile,
        type: 'alert-storage',
        desc: lang.modal.overWordLimit,
        notice: lang.modal.characterLimitation,
    },
};

