import jsCookie from 'js-cookie';

import axiosApi, { setHeader } from 'api/root.api';

export const loginPost = async (body) => {
    const urlEnd = '/platform/product/v1/login';
    const response = await axiosApi.post(urlEnd, body);
    // 返回 token (token 改成 bigPlatformToken)
    const token = response.data.bigPlatformToken;
    const xrToken = response.data.token;
    setHeader('Authorization', `Bearer ${token}`);
    jsCookie.set('token', token);
    jsCookie.set('xrToken', xrToken);
    // 返回 code
    const code = response.data.code;
    return code;
};

export const autoLogin = async () => {
    const token = jsCookie.get('token');
    try {
        // const userInfo = JSON.parse(atob(token.split('.')[1]));
        // const { permission } = userInfo;
        // const { permits, expiredAt, createdAt, startAt } = permission;
        // const limits = { ...permits, expiredAt, createdAt, startAt };
        setHeader('Authorization', `Bearer ${token}`);
        // return limits;
    } catch (err) {
        return err;
    }
};