import styled from 'styled-components';

export const StyledForget = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    @media all and (max-height:880px) {
        display: block;
        overflow-y: auto;
    }
    .whiteBg{
        width: 90%;
        max-width: 522px;
        height: 614px;
        background-color: #fff;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        position: relative;
        z-index: 2;
        @media all and (max-height:880px) {
            margin: 120px auto 180px auto;
        }
        .logo{
            position: absolute;
            left:50%;
            top:-80px;
            margin-left: -91px;
        }

        .inputErrorMsg {
            height: 20px;
            margin-top: 40px;
            font-size: 12px;
            color: #F5314D;
            position: absolute;
            width: 100%;
            text-align: center;
            svg {
                margin-right: 5px;
            }
        }
        .reCAPTCHA{
            margin: 0 auto;
            padding-top: 30px;
            text-align: center;
            width: 304px;
        }
        h1{
            padding-bottom: 40px;
            color: #333333;
            font-size: 16px;
            text-align:center;
            padding-top: 80px;
        }
        .checkout{
            font-size: 14px;
            color: #222;
            font-weight: bold;
            text-align: left;
            margin: 0 auto;
            width: 85%;
            max-width: 426px;
            a{
                color: #54C68F;
                text-decoration: underline;
            }
        }
        .formTable{
            width: 85%;
            max-width: 426px;
            margin:0 auto;
            .accountLabelPanel, .passwordInputLable {
                margin-bottom: 10px;
                width: 100%;
                font-weight: bold;
                font-size: 14px;
                color: #333;
                text-align:left;
                position: relative;
            }
            
            .passwordInputLable {
                a {
                    right:0;
                    color: #333333;
                    font-size: 14px;
                    &:hover{
                        color: #ED2975;
                    }
                }
            }

            
            .inputCon{
                margin-bottom: 35px;
                position: relative;
                span{
                    position: absolute;
                    top:10px;
                    left:10px;
                    svg{
                        color:#666 ;
                    }
                }
                .eye{
                    right: 10px;
                    left:auto;
                    top:12px;
                    cursor: pointer;
                    &:hover{
                        svg{
                            color: #F2539D;
                        }
                    }
                }
                b{
                    position: absolute;
                    left:10px;
                    top:45px;
                    font-size:12px;
                    color: #F5314D;
                }
            }
            .passwordInputPanel {
                position: relative;
                margin-bottom: 10px;
                text-align: left;
                .eyeIcon {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);

                    &:hover {
                        color: #ED2975;
                        cursor: pointer;
                    }
                }

            }
            .inputs {
                width: 100%;
                height: 40px;
                border-radius: 2px;
                border:1px solid rgba(0,0,0,0.2);
                padding:0 5px 0 35px;
                font-weight: normal;
                outline: none;
                &:focus{
                    border:2px solid #ED2975;
                }
                
            }

            tr{
                &:nth-child(1){
                    td{
                        padding-top: 50px;
                        padding-bottom: 15px;
                    }
                }
                &:nth-child(3){
                    td{
                        padding-top: 40px;
                        padding-bottom: 15px;
                    }
                }
                td{
                    font-weight: bold;
                    font-size: 14px;
                    color: #333;
                    text-align:left;
                    position: relative;
                    svg{
                        position: absolute;
                        right:10px;
                        top:9px;
                        cursor: pointer;
                        font-size: 20px;
                    }
                    input{
                        width: 100%;
                        height: 40px;
                        border-radius: 2px;
                        border:1px solid rgba(0,0,0,0.2);
                        padding:0 5px;
                        font-weight: normal;
                        outline: none;
                        &:focus{
                            border:2px solid #ED2975;
                        }
                    }
                    a{
                        position: absolute;
                        right:0;
                        color: #333333;
                        font-size: 14px;
                        &:hover{
                            color: #ED2975;
                        }
                    }
                }
            }
        }
        .langBtn{
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);
            text-decoration:underline;
            text-align:center;
            position: absolute;
            display: inline-block;
            cursor: pointer;
            bottom: -95px;
            left:50%;
            margin-left: -35px;
            &:hover{
                color: #fff;
            }
            @media all and (max-width:1440px) {
                bottom: -90px;
            }
            svg{
                position: relative;
                margin-right: 6px;
                top:2px;
            }
        }
        .langList{
            width: 152px;
            background-color: #fff;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            position: absolute;
            bottom: -215px;
            left:50%;
            margin-left: -76px;
            @media all and (max-width:1440px) {
                bottom: -210px;
            }
            li{
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                color: #333333;
                font-size:14px;
                padding: 10px 15px;
                &:hover{
                    color: rgba(237, 41, 117, 1);
                }
                svg{
                    display: none;
                }
            }
            li.current{
                color: rgba(237, 41, 117, 1);
                svg{
                    display: inline;
                }
            }
        }
        .button{
            width: 85%;
            max-width: 426px;
            height: 48px;
            line-height: 48px;
            background-color:#ED2975;
            font-size: 16px;
            text-align:center;
            cursor: pointer;
            color: #fff;
            border-radius: 4px;
            margin-top: 30px;
            &:hover{
                background-color: #EA4C89;
            }
            svg{
                font-size:22px;
                position: relative;
                top:4px;
                margin-right: 10px;
            }
            .bsRotate{
                animation: 2s rotateMove infinite linear;
                position: relative;
                top:4px;
            }
        }
        .say{
            text-align:center;
            color: #333;
            font-weight: bold;
            font-size: 16px;
            padding-top: 30px;
            width: 85%;
            max-width: 426px;
            margin: 0 auto;
            a{
                text-decoration:underline;
                color: #333;
                &:hover{
                    color: #EA4C89;
                }
            }
        }
        .underWord{
            text-align:center;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.6);
            position: absolute;
            bottom: -50px;
            width: 100%;
            a{
                color: rgba(255, 255, 255, 0.6);
                text-decoration: underline;
            }
        }
    }
    .bg{
        position: absolute;
        left:0;
        top:0;
        z-index: 0;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        object-position: center center;
        z-index: -1;
    }
    @keyframes rotateMove {
        0%{
            transform: rotateZ(0deg);
        }
        100%{
            transform: rotateZ(360deg);
        }
    }
`;