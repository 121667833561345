import styled from 'styled-components';

export const StyledCatchError = styled.div`
    width: 100%;
    height: 100%;
    .errorIcon {
        width: 80px;
        height: 80px;
    }
    .errorTitle {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #333333;
        margin-top: 32px;
    }
    .errorDescription {
        font-weight: 500;
        font-size: 15px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #666666;
        margin-top: 4px;
    }
    .retryButton {
        padding: 12px 24px;
        background: #ED0973;
        border: 1px solid #ED0973;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        cursor: pointer;
        margin-top: 64px;
    }
`;