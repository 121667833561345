import React, { Suspense, useEffect } from 'react';
import { Navigate, Routes, Route, BrowserRouter as Router } from 'react-router-dom';

// styled components
import { ThemeProvider } from 'styled-components';
import { StyledApp } from './StyeldApp';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { SET_PERMITS, SET_USER_INFO, SET_PROJECT_RECORD } from 'redux/types';

// api
import { loginGet } from 'api/loginInfo/loginInfo.api';
import { autoLogin } from 'api/login/login.api';
import { currentInfo } from 'api/currentInfo/currentInfo.api';

// components
import Login from 'pages/Login/Login';
import Create from 'pages/Create/Create';
import CardList from 'pages/CardList/CardList';
import Upload from 'pages/Upload/Upload';
import ProjectInfo from 'pages/ProjectInfo/ProjectInfo';
import Forget from 'pages/Forget/Forget';
import Register from 'pages/Register/Register';
import FinshPassword from 'pages/FinshPassword/FinshPassword';
import UpgradeCon from 'pages/Upgrade/Upgrade';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import ScanKit from 'pages/ScanKit/ScanKit';
import ScanKitNew from 'pages/ScanKitNew/ScanKitNew';
import ScanKitInfo from 'pages/ScanKitInfo/ScanKitInfo';
import ScanKitEditor from 'pages/ScanKitEditor/ScanKitEditor';
import ScanKitViewer from 'pages/ScanKitViewer/ScanKitViewer';
import ScanModal from 'components/ScanModal/ScanModal';
import ChooseType from 'pages/ChooseType/ChooseType';

//plugin
import Cookies from 'js-cookie';

// i18n
import { useTranslation } from 'react-i18next';

// constants
import THEME from 'constants/theme';

// config
import { modalConfig } from 'config/modal';

// hooks
import useModal from 'hooks/useModal';

// ga
import ReactGA from 'react-ga4';

ReactGA.initialize('G-B17J6K0VSN');
ReactGA.send('pageview');

document.oncontextmenu = () => false;

function RequireAuth({ children, redirectTo }) {
    const user = useSelector(store => store.userInfo);
    return user ? children : <Navigate to={redirectTo} />;
}

function App() {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { projectCount } = useSelector(store => store.projectRecord);
    const [authorizing, setAuthorizing] = React.useState(true);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const { modalSetting, setModalSetting } = useModal();

    if (urlParams.get('autoToken')) {
        Cookies.set('token', decodeURIComponent(urlParams.get('autoToken')));
    }

    useEffect(async () => {
        try {
            await autoLogin();
            const [projectInfo, userInfo] = await Promise.all([loginGet(), currentInfo()]);
            const { permits, expiredAt, createdAt, startAt } = userInfo.ssoData.permission;
            const limits = { ...permits, expiredAt, createdAt, startAt };
            dispatch({ type: SET_USER_INFO, payload: userInfo });
            dispatch({ type: SET_PERMITS, payload: limits });
            dispatch({ type: SET_PROJECT_RECORD, payload: { ...projectInfo } });
        } catch (err) {
            if (err.response.data.msg === 'Required request header "Authorization" for method parameter type String is not present') {
                setModalSetting({
                    ...modalSetting,
                    show: true,
                    type: 'type37',
                    handleConfirm: () => {
                        window.location.reload();
                    },
                });
            }
        } finally {
            setAuthorizing(false);
        }
    }, []);

    const checkLang = (language) => {
        if (language.indexOf('zh') > -1) {
            return 'zh-tw';
        } else if (language.indexOf('fr') > -1) {
            return 'fr';
        } else if (language.indexOf('ko') > -1) {
            return 'ko';
        } else {
            return 'en';
        }
    };

    useEffect(() => {
        if (urlParams.get('lang')) {
            i18n.changeLanguage(checkLang(urlParams.get('lang')));
            Cookies.set('lang', checkLang(urlParams.get('lang')));
        } else if (Cookies.get('lang')) {
            i18n.changeLanguage(checkLang(Cookies.get('lang')));
        } else {
            Cookies.set('lang', checkLang(window.navigator.language));
            i18n.changeLanguage(checkLang(window.navigator.language));
        }
    }, []);

    // const openModal = () => {
    //     dispatch({ type: OPEN_MODAL, payload: {
    //         isOpen: true,
    //         icon: 'icon',
    //         title: 'title',
    //         description: '123',
    //         button1: '',
    //         button2: '確定',
    //         handleConfirm: () => { console.log('confirm'); },
    //     } });
    // };

    if (authorizing) return <div></div>;

    return (
        <ThemeProvider theme={THEME}>
            <StyledApp>
                {modalSetting.show && (
                    <ModalPopup
                        modalConfig={modalConfig[modalSetting.type]}
                        setModalSetting={setModalSetting}
                        modalSetting={modalSetting}
                    />
                )}
                {/* 掛載 scan-modal 元件處 */}
                <ScanModal />
                <Router>
                    <Suspense fallback={<div />}>
                        <Routes>
                            <Route exact path="/login" element={
                                <Login />}
                            />
                            <Route exact path="/register" element={
                                <Register />}
                            />
                            <Route exact path="/create" element={
                                <RequireAuth redirectTo="/login">
                                    <Create />
                                </RequireAuth>
                            }
                            />
                            <Route exact path="/chooseType" element={
                                <RequireAuth redirectTo="/login">
                                    <ChooseType />
                                </RequireAuth>
                            }
                            />
                            <Route exact path="/cardList" element={
                                <RequireAuth redirectTo="/login">
                                    <CardList />
                                </RequireAuth>}
                            />
                            {/* <Route exact path="/scankit" element={
                                <RequireAuth redirectTo="/login">
                                    <ScanKit />
                                </RequireAuth>}
                            /> */}
                            <Route exact path="/new" element={
                                <RequireAuth redirectTo="/login">
                                    <ScanKitNew />
                                </RequireAuth>}
                            />
                            <Route exact path="/info" element={
                                <RequireAuth redirectTo="/login">
                                    <ScanKitInfo />
                                </RequireAuth>}
                            />
                            <Route exact path="/editor" element={
                                <RequireAuth redirectTo="/login">
                                    <ScanKitEditor />
                                </RequireAuth>}
                            />
                            <Route exact path="/upload" element={
                                <RequireAuth redirectTo="/login">
                                    <Upload />
                                </RequireAuth>
                            }
                            />
                            <Route exact path="/projectInfo" element={
                                <RequireAuth redirectTo="/login">
                                    <ProjectInfo />
                                </RequireAuth>
                            }
                            />
                            <Route exact path="/forget" element={
                                <Forget />
                            }
                            />
                            <Route exact path="/finshPassword" element={
                                <FinshPassword />
                            }
                            />
                            <Route exact path="/upgrade" element={
                                <UpgradeCon />
                            }
                            />
                            <Route exact path="/viewer" element={
                                <ScanKitViewer />
                            }
                            />
                            <Route
                                path="*"
                                element={
                                    projectCount !== 0
                                        ? <Navigate to="/cardList" />
                                        : <Navigate to="/create" />
                                }
                            />
                        </Routes>
                    </Suspense>
                </Router>
            </StyledApp>
        </ThemeProvider>
    );
}

export default App;
