import jsCookie from 'js-cookie';
import axiosApi, { removeHeader } from 'api/root.api';

export const logout = async () => {
    const urlEnd = '/platform/account/v1/logout';
    const response = await axiosApi.post(urlEnd);
    removeHeader('Authorization');
    jsCookie.remove('token');
    jsCookie.remove('xrToken');
    const result = response.data;
    return result;
};