import styled from 'styled-components';

export const StyledScanModal = styled.div`
    width: 560px;
    padding: 40px 40px 48px 40px;
    text-align: center;
    position: relative;

    .closeButton {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 32px;
        height: 32px;
        color: #666666;
        cursor: pointer;
    }

    .iconWrapper {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        
        >img {
            object-fit: contain;
        }
    }

    .textWrapper {
        margin-top: 32px;

        .title {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.02em;
            color: #333333;
        }

        .description {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;
            color: rgba(0, 0, 0, 0.6);
            margin-top: 4px;
        }
    }

    .buttonWrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 64px;
    }
`;