import styled from 'styled-components';

export const StyledFeature = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        border-radius: 8px;
        overflow: hidden;
        width: 1110px;
        height: 90%;
        overflow: hidden;

        @media (max-width:1366px) {
            width: calc(1049 / 1366 * 100%);
        }

        @media (max-width:1280px) {
            width: calc(978 / 1280 * 100%);
        }

        @media (max-width:992px) {
            width: calc(722 / 992 * 100%);
        }

        @media (max-width:768px) {
            width: 69.8%;
            width: calc(536 / 768 * 100%);
        }

        @media (max-width:414px) {
            width: 100%;
            height: 100%;
            border-radius: 0px;
        }

        .title {
            position: relative;
            text-align: center;
            color: #fff;
            font-size: 20px;
            height: 64px;
            line-height: 64px;
            background: linear-gradient(79.78deg, #ED0973 0%, #FF744E 100%);

            .closeIcon {
                position: absolute;
                top: 0px;
                right: 20px;
                font-size: 25px;
                cursor: pointer;
            }
        }

        .bg {
            height: calc(100% - 64px);
            background: #F2F2F2;
            overflow: auto;
            scroll-behavior: smooth;
        }

        .loadingIcon {
            font-size: 72px;
            color: #999999;
            margin: 104px auto;
            animation: loader 1s linear infinite;
        }

        @keyframes loader {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        .errorStatus {
            padding: 100px 0px;
        }
    }

    /* 之後要從這裡整理 */

    .content-card-container {
        width: calc(1030 / 1110 * 100%);
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        column-gap: 24px;
        padding: 40px 0px 140px 0px;

        @media (max-width:1366px) {
            width: calc(969 / 1049 * 100%);
        }

        @media (max-width:1280px) {
            width: calc(898 / 978 * 100%);
            flex-direction: column;
        }

        @media (max-width:992px) {
            width: calc(642 / 722 * 100%);
        }

        @media (max-width:768px) {
            width: calc(456 / 536 * 100%);
        }

        @media (max-width:414px) {
            width: calc(366 / 414 * 100%);
        }
    }

    .content-card {
        padding: 24px;
        width: 327px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        text-align: center;

        @media (max-width: 1280px) {
            width: 100%;
            margin: 20px auto;
        }
    }

    .content-card-title {
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        text-align: left;
        display: flex;
        align-items: center;
    }

    .nextPlan {
        padding: 2px 8px;
        color: #fff;
        font-size: 12px;
        background: #54C68F;
        border: 1px solid #54C68F;
        border-radius: 40px;
        margin-left: 16px;
    }

    .content-card-subtitle {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #666666;
        margin-top: 8px;
        margin-bottom: 24px;
        text-align: left;
        height: 50px;
    }

    .content-card-price {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #333333;
        margin-bottom: 40px;
        text-align: left;
    }

    .content-card-price span {
        font-weight: 500;
        font-size: 26px;
    }

    .content-card-button-container {
        height: 48px;

        >button {
            width: 100%;
        }
    }

    .arrowIcon {
        animation: rotateMove 2s infinite linear;
    }

    @keyframes rotateMove {
        0%{
            transform: rotateZ(0deg);
        }
        100%{
            transform: rotateZ(360deg);
        }
    }

    .content-card-description {
        margin-top: 32px;
    }

    .content-card-description div {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.02em;
        color: #333333;
        text-align: left;
        height: 40px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        column-gap: 4px;

        >div {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .key-point {
        color: #ED0973;
    }
`;