import styled from 'styled-components';

export const StyledFinshPassword = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    @media all and (max-height:880px) {
        display: block;
        overflow-y: auto;
    }
    .whiteBg{
        width: 90%;
        max-width: 522px;
        height: 508px;
        background-color: #fff;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        position: relative;
        z-index: 2;
        @media all and (max-height:880px) {
            margin: 120px auto 180px auto;
        }
        p{
            width: 85%;
            max-width: 426px;
            display: block;
            color: #666;
            font-size: 14px;
            text-align: left;
            margin: 0 auto;
            line-height: 24px;
        }
        .logo{
            position: absolute;
            left:50%;
            top:-80px;
            margin-left: -91px;
        }


        h1{
            padding-bottom: 40px;
            color: #333333;
            font-size: 16px;
            text-align:center;
            padding-top: 50px;
        }
        .langBtn{
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);
            text-decoration:underline;
            text-align:center;
            position: absolute;
            display: inline-block;
            cursor: pointer;
            bottom:-70px;
            left:50%;
            margin-left: -35px;
            &:hover{
                color: #fff;
            }
            @media all and (max-width:1440px) {
                bottom: -40px;
            }
            svg{
                position: relative;
                margin-right: 6px;
                top:2px;
            }
        }
        .langList{
            width: 152px;
            background-color: #fff;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            position: absolute;
            bottom:-155px;
            left:50%;
            margin-left: -76px;
            @media all and (max-width:1440px) {
                bottom: -125px;
            }
            li{
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                color: #333333;
                font-size:14px;
                padding: 10px 15px;
                &:hover{
                    color: rgba(237, 41, 117, 1);
                }
                svg{
                    display: none;
                }
            }
            li.current{
                color: rgba(237, 41, 117, 1);
                svg{
                    display: inline;
                }
            }
        }
        .button{
            width: 85%;
            max-width: 426px;
            height: 48px;
            line-height: 48px;
            background-color:#ED2975;
            font-size: 16px;
            text-align:center;
            cursor: pointer;
            color: #fff;
            border-radius: 4px;
            font-weight: bold;
            margin-top: 30px;
            display: inline-block;
            &:hover{
                background-color: #EA4C89;
            }
            svg{
                font-size:22px;
                position: relative;
                top:4px;
                margin-right: 10px;
            }
        }
    }
    .bg{
        position: absolute;
        left:0;
        top:0;
        z-index: 0;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        object-position: center center;
        z-index: -1;
    }
`;