import styled from 'styled-components';

export const StyledEditor = styled.div`
    width: 100%;
    height: 100vh;
    overflow: auto;

    .headerContainer {
        width: 100%;
        height: 56px;
        background: #27282C;
        box-shadow: 0px 1px 0px #BFBFBF;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 24px;

        .startContainer {
            display: flex;
            column-gap: 32px;
            align-items: center;

            .homeButton {
                font-size: 24px;
                color: #FFFFFF;
                cursor: pointer;
            }

            .planTag {
                background: rgba(255, 255, 255, 0.3);
                border-radius: 40px;
                padding: 4px 16px;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: #FFFFFF;
            }
        }

        .centerContainer {
            display: flex;
            column-gap: 12px;
            align-items: center;

            .scankitMark {
                width: 34px;
                height: 34px;
            }

            .projectName {
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.02em;
                color: #FFFFFF;
            }
        }

        .endContainer {
            display: flex;
            column-gap: 24px;
            align-items: center;

            .editorButton {
                padding: 8px 24px;
                border-radius: 8px;
                display: flex;
                column-gap: 8px;
                align-items: center;

                >svg {
                    width: 24px;
                    height: 24px;
                    padding: 2px;
                }

                >span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 0.02em;
                }

                .arrowIcon {
                    animation: rotateMove 2s infinite linear;
                }

                @keyframes rotateMove {
                    0%{
                        transform: rotateZ(0deg);
                    }
                    100%{
                        transform: rotateZ(360deg);
                    }
                }
            }

            .primary {
                background: rgba(255, 255, 255, 0.2);
                color: #FFFFFF;

                &:hover {
                    background: rgba(255, 255, 255, 0.87);
                    color: #333333;
                }
            }

            .secondary {
                background: rgba(255, 255, 255, 0.87);
                color: #333333;

                &:hover {
                    background: rgba(255, 255, 255, 0.2);
                    color: #FFFFFF;
                }
            }
        }
    }

    .contentContainer {
        display: flex;
        width: 100%;
        height: calc(100% - 56px);

        .navbarContainer {
            width: 344px;
            height: 100%;
            background: #121212;
            box-shadow: 1px 0px 0px #CCCCCC;
            display: flex;

            .navbarPanel {
                width: 64px;
                height: 100%;
                background: #121212;
                padding-top: 54px;

                .navbarTabs {
                    width: 64px;
                    height: 64px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }

                .navbarTabs:hover {
                    background: #FF7043;
                }

                .navbarActive {
                    background: #FF7043;
                }
            }

            .editorPanel {
                width: 280px;
                height: 100%;
                background: rgba(255, 255, 255, 0.07);
                padding: 21px 0px;

                .switchContainer {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 6px 20px;

                    .switchLabel {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.02em;
                        color: #FFFFFF;
                    }
                }

                .tagsContainer {
                    width: 100%;
                    overflow-y: overlay;
                    height: calc(100% - 40px);
                    display: flex;
                    flex-direction: column;
                    row-gap: 12px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    padding: 15px 20px 20px;

                    /* 整個滾動條 */
                    &::-webkit-scrollbar {
                        width: 7px;
                    }
                    /* 滾動條軌道 */
                    &::-webkit-scrollbar-track-piece {
                        background: transparent;
                    }
                    /* 滾動條滑塊 */
                    &::-webkit-scrollbar-thumb {
                        border-radius: 8px;
                        background: rgba(255, 255, 255, 0.30);
                    }
                    
                    .tagList {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-radius: 2px;
                        border: 1px solid rgba(255, 255, 255, 0.12);
                        background: #1B1B1B;
                        color: rgba(255, 255, 255, 0.87);
                        cursor: pointer;

                        /* 標籤編號+標籤標題 */
                        >div:nth-child(1) {
                            width: 70%;
                            display: flex;
                            flex-direction: row;
                            column-gap: 8px;
                            padding: 6px 12px;
                            position: relative;

                            >div:nth-child(1) {
                                width: 24px;
                                height: 24px;
                                border-radius: 50%;
                                text-align: center;
                            }

                            >div:nth-child(2) {
                                width: 65%;
                                letter-spacing: 0.28px;
                                text-align: left;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            .alertIcon {
                                position: absolute;
                                top: -12px;
                                right: 4px;
                            }
                        }

                        /* 標籤縮圖 */
                        >div:nth-child(2) {
                            width: 30%;
                            height: 45px;
                            background: #FFFFFF;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 1px;

                            >img {
                                max-width: 100%;
                                max-height: 100%;
                                object-fit: contain;
                            }
                        }
                    }

                    .emptyTagNotice {
                        letter-spacing: 0.28px;
                        color: rgba(255, 255, 255, 0.50);
                        margin-top: 62px;
                    }
                }
            }
        }

        .editorContainer {
            width: 100%;
            height: 100%;
            padding: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .notice {
                position: absolute;
                top: 27px;
                left: 30%;
            }
        }
    }

    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;

        >div {
            position: absolute;
            top: 33px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: linear-gradient(79.78deg, #FF7043 0%, #FFA270 100%);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
        }

        >div:nth-child(1) {
            left: 8px;
            animation: lds-ellipsis1 0.6s infinite;
        }

        >div:nth-child(2) {
            left: 8px;
            animation: lds-ellipsis2 0.6s infinite;
        }

        >div:nth-child(3) {
            left: 32px;
            animation: lds-ellipsis2 0.6s infinite;
        }

        >div:nth-child(4) {
            left: 56px;
            animation: lds-ellipsis3 0.6s infinite;
        }
    }

    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }
`;