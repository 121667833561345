import styled from 'styled-components';

export const StyledCardSettingList = styled.div`
    >div {
        padding: 8px 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 4px;

        >svg {
            fill: #333333;
            width: 24px;
            height: 24px;
            padding: 1px;
        }

        >span {
            color: #333333;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
        }

        &:hover {
            >svg {
                fill: #FF744E;
            }
            >span {
                color: #FF744E;
            }
        }

        &:first-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }

        &:last-child {
            border-top: 1px solid rgba(0, 0, 0, 0.12);

            &:hover {
                >svg {
                    fill: #F5314D;
                }
                >span {
                    color: #F5314D;
                }
            }
        }
    }

    .cannotPublic {
        >svg {
            fill: #BFBFBF;
        }
        >span {
            color: #BFBFBF;
        }
        &:hover {
            >svg {
                fill: #BFBFBF;
            }
            >span {
                color: #BFBFBF;
            }
        }
    }

    .basicTag {
        padding: 2px 8px;
        background: #FFE8E1;
        border: 1px solid #FFE8E1;
        border-radius: 40px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: #FF7043 !important;
        margin-left: 12px;
    }
`;