import styled from 'styled-components';

export const StyledScanKitNew = styled.div`
    width: 100%;
    height: 100vh;
    overflow: auto;

    .uploading {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;

        .loadingWrapper {
            width: 410px;

            .loadingBar {
                width: 100%;
                height: 8px;
                background: #E0E0E0;
                border-radius: 12px;
                margin-top: 64px;
                margin-bottom: 24px;
                position: relative;
            }

            .loadingBar::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: ${({ uploadProgress }) => `${uploadProgress}%`};
                height: 100%;
                background: #54C68F;
                border-radius: 12px;
            }
        }
    }

    .fileNamePanel {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 24px 0px 0px;
        gap: 10px;
        width: 304px;
        height: auto;
        background: #FFE8E1;
        border-radius: 55px;
        margin: 0 auto;

        >span {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.02em;
            color: #333333;
        }
    }

    .contentContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        height: calc(100% - 56px);

        .uploadContainer {
            display: flex;
            justify-content: center;
            align-items: center;

            .uploadWrapper {
                width: 628px;

                .dropzone {
                    width: 100%;
                    background: #FFFFFF;
                    border: 1px dashed #FF744E;
                    border-radius: 12px;

                    &:hover {
                        background: #FFF9F7;
                    }

                    >div {
                        width: 100%;
                        height: 100%;
                        padding: 48px 72px;

                        .textWrapper {
                            width: 484px;

                            .title {
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 25px;
                                letter-spacing: 0.02em;
                                color: #333333;
                                margin-top: 24px;
                            }

                            .description {
                                text-align: left;
                                margin-top: 24px;

                                >li {
                                    display: flex;
                                    align-items: start;
                                    margin-bottom: 8px;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 20px;
                                    letter-spacing: 0.02em;
                                    color: #666666;

                                    >svg {
                                        fill: #54C68F;
                                        font-size: 20px;
                                        margin-right: 7.75px;
                                    }

                                    >div {
                                        max-width: 456px;
                                    }

                                    >div >span {
                                        color: #54C68F;
                                    }
                                }
                            }
                        }
                    }
                }

                .dragActive {
                    background: #FFF9F7;
                    border: 1px solid #FF744E;
                }

                .buttonPanel {
                    width: 100%;
                    padding-top: 80px;
                    display: flex;
                    justify-content: space-between;

                    >button {
                        padding: 12px 24px;
                        border-radius: 4px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    .deleteButton {
                        background: #F2F2F2;
                        border: 1px solid #F2F2F2;
                        color: #666666;
                    }

                    .uploadButton {
                        background: #ED0973;
                        border: 1px solid #ED0973;
                        color: #FFFFFF;
                    }

                    .disableButton {
                        background: #FAB5D5;
                        border: 1px solid #FAB5D5;
                    }
                }
            }
        }

        .editInfoContainer {
            background: #999;
        }
    }
`;