import styled from 'styled-components';

export const StyledScanHeader = styled.div`
    width: 100%;
    height: 56px;
    background: #FFFFFF;
    box-shadow: ${({ title }) => title ? '0px 1px 4px rgba(0, 0, 0, 0.2)' : ''};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .closeButton {
        font-size: 32px;
        color: #333333;
        cursor: pointer;
        position: absolute;
        top: 12px;
        right: 24px;
    }

    .titleWrapper {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: #333333;
        display: flex;
        column-gap: 12px;
        align-items: center;

        svg {
            width: 34px;
            height: 34px;
        }
    }
`;