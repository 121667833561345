import { SET_PROJECT_RECORD, RESET_ALL } from 'redux/types';

const defaultProjectRecord = {
    projectCount: 0,
    projectPublicCount: 0,
    thisMonthCount: 0,
};

const projectRecordReducer = (state = defaultProjectRecord, action) => {
    switch (action.type) {
        case SET_PROJECT_RECORD:
            return {
                ...state, ...action.payload,
            };
        case RESET_ALL:
            return defaultProjectRecord;
        default: return state;
    }
};

export default projectRecordReducer;