import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//styled
import { StyledUpgrade } from './StyledUpgrade';
import { StyledCheckForm } from './StyledCheckForm';

//api
import { refreshToken } from 'api/refreshToken/refreshToken.api';

// redux
import { useSelector, useDispatch } from 'react-redux';

//components
import MoneyStatus from 'components/MoneyStatus/MoneyStatus';


//i18n
import { useTranslation, Trans } from 'react-i18next';

//env
import SERVER from 'constants/server';
import HARDCODE from 'constants/hardcode';
import APIKEY from 'constants/apiKey';

//stripe
import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';

const stripePromise = loadStripe(APIKEY.STRIPE_KEY);

//plugin
import axios from 'axios';
import * as dayjs from 'dayjs';
import Cookies from 'js-cookie';

//react icon
import { AiOutlineClose } from 'react-icons/ai';


const CheckoutForm = ({ setShowLoginModal, setShowSignupModal }) => {

    const store = useSelector(store => store);
    const navigate = useNavigate();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);


    let token = decodeURIComponent(urlParams.get('token'));

    const [timeStart, setTimeStart] = useState('');
    const [timeEnd, setTimeEnd] = useState('');
    const [timeSecond, setTimeSecond] = useState('');

    const [apiStatus, setApiStatus] = useState('null');

    let date = new Date();

    useEffect(() => {
        if (token == 'null') {
            token = store.userInfo.token;
        }
        setTimeStart(dayjs(Date.now()).format('YYYY/MM/DD'));
        setTimeEnd(dayjs(Date.now() + 2700000000).format('YYYY/MM/DD'));
        setTimeSecond(date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds());
    }, []);

    const [checked, setChecked] = useState(false);

    const checkboxEvent = () => {
        setChecked(!checked);
    };

    const [checkboxErrMsg, setCheckboxErrMsg] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const { t, i18n } = useTranslation();
    const termsConditions = t('termsConditions');
    const privacyPolicy = t('privacyPolicy');


    const [accountPhone, setAccountPhone] = useState('');

    const accountPhoneChange = (e) => {
        setAccountPhone(e.target.value);
    };

    const [accountCountry, setAccountCountry] = useState('');

    const accountCountryChange = (e) => {
        setAccountCountry(e.target.value);
    };

    const [stripeToken, setStripeToken] = useState('');

    const signupEvent = async (e) => {
        e.preventDefault();


        if (elements == null) {
            return;
        }
        const card = elements.getElement(CardElement);
        try {


            const result = await stripe.createToken(card);

            if (!checked) {
                setCheckboxErrMsg(true);
                return;
            }

            if (token == 'null') {
                token = store.userInfo.bigPlatformToken;
            }

            let sendData = {
                stripeToken: result.token.id,
                packageId: HARDCODE.NEW_BASIC_PACKAGE_ID,
            };


            // return
            setApiStatus('progressing');
            const accountEvent = await axios({
                method: 'post',
                data: sendData,
                url: `${SERVER.PLATFORM_URL}/api/v1/payment/change`,
                headers: { 'Authorization': `Bearer ${token}` },
            });

            // 新版 20220808 之後的基本版，只需要訂閱一個次方案

            const viewUsageEvent = await axios({
                method: 'post',
                url: `${SERVER.PLATFORM_URL}/api/v1/payment/subscription`,
                data: {
                    packageId: HARDCODE.PAGE_VIEW_ID,
                    stripeToken: sendData.stripeToken,
                },
                headers: { 'Authorization': `Bearer ${token}` },
            });

            // const limitUsageEvent = await axios({
            //     method: 'post',
            //     url: `${SERVER.PLATFORM_URL}/api/v1/payment/subscription`,
            //     data: {
            //         packageId: HARDCODE.LIMIT_USAGE_ID,
            //         stripeToken: sendData.stripeToken,
            //     },
            //     headers: { 'Authorization': `Bearer ${token}` },
            // });

            setApiStatus('success');

            const refreshTokenEvent = await refreshToken({ token: Cookies.get('token'), username: store.userInfo.username });

            Cookies.set('token', refreshTokenEvent.token);

            // Cookies.remove('token');
            window.open(window.location.origin, '_self');

        } catch (err) {
            if (err.response.data.msg.indexOf('this username is already') > -1) {
                setApiStatus('error 002');
            } else {
                setApiStatus('error500');
            }
        }
    };

    return (
        <StyledCheckForm>
            {
                apiStatus !== 'null' &&
                <MoneyStatus status={apiStatus} setApiStatus={setApiStatus} />
            }
            <div className='modal-card'>
                <div>
                    <form autoComplete="off">
                        <div style={{ marginBottom: 16 }}>{t('content.paymentInfo')}</div>
                        <div className='modal-input-container credit-card-container'>
                            <CardElement />
                        </div>

                        {/* <div className='modal-input-container'>
                            <select
                                value={accountCountry}
                                name="country"
                                className="modal-input"
                                onChange={accountCountryChange}
                            >
                                <option value="">所在地區 (選填)</option>
                                <option value="zh-tw">台灣</option>
                                <option value="us">US</option>
                                <option value="other">other</option>
                            </select>
                        </div>
  
                        <div className='modal-input-container'>
                            <input
                                name="phone"
                                className="modal-input"
                                value={accountPhone}
                                type="text"
                                placeholder="聯絡電話 (選填)"
                                onChange={accountPhoneChange}
                            />
                        </div> */}
                    </form>



                </div>
            </div>
            <div className='modal-card' style={{ padding: 0 }}>
                <div className='payment-info-header'>
                    <span>{t('content.basicPlan')}</span>
                </div>
                <div className='payment-info-content'>
                    <div className='expiration-container'>
                        <div style={{ fontSize: 16, color: '#333333' }}>{t('content.deadlineOfPlan')}</div>
                        <div style={{ fontSize: 14, color: '#666666' }}>
                            {
                                timeStart
                            } ～
                            {
                                timeEnd
                            }
                        </div>
                    </div>
                    <div className='payment-total'>
                        <div>{t('content.paymentTotal')}</div>
                        <div>{t('content.basicPlanCost')}</div>
                    </div>
                    <div className='payment-notification'>
                        <div>
                            {t('content.agreement')} <span>{timeEnd} {timeSecond}</span> {t('content.willChargeFee')}
                        </div>
                        <div>*&nbsp;
                            <Trans i18nKey="content.termsOfService">
                                iStaging<a className='link' href={'https://www.istaging.com/' + i18n.language + '/terms/'} target="_blank">{{ termsConditions }}</a> 與 <a className='link' href={'https://www.istaging.com/' + i18n.language + '/privacy/'} target="_blank">{{ privacyPolicy }}</a>請點閱此處
                            </Trans>
                        </div>
                        <div>* {t('content.unsubscription')}</div>
                        <div className='read-checkbox'>
                            <input type="checkbox" name="read" onChange={checkboxEvent} checked={checked} />
                            <div>{t('content.alreadyReadRules')}</div>
                        </div>
                    </div>
                    <div className='checkbox-error-message'>
                        {checkboxErrMsg && <span>{t('mustFill')}</span>}
                    </div>
                    <button className='modal-submit-button' onClick={signupEvent}>{t('paymentButton')}</button>
                </div>
            </div>
        </StyledCheckForm>
    );
};

const Upgrade = (props) => {



    const { t } = useTranslation();

    const navigate = useNavigate();

    const closeEvent = (e) => {
        if (window.location.href.indexOf('upgrade') > -1) {
            navigate('/login');
        } else {
            props.onShowStripe(false);
        }

    };

    return (
        <StyledUpgrade>

            <div className='titleUpgrade'>
                {t('content.paymentTitle')}
                <span className='closeIcon' onClick={closeEvent}><AiOutlineClose /></span>
            </div>
            <div className="content-card-modal">
                <Elements stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            </div>
        </StyledUpgrade>
    );
};

export default Upgrade;